import { Chip, Paper, TableCell, TextField } from '@material-ui/core'
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useFilters } from '../../../hooks/useFilters'
import { useSharedStyles } from '../../../hooks/useSharedStyles'
import { api } from '../../../models/environment'
import { useStores } from '../../../models/root-store'
import { RouteSnapshot } from '../../../models/route'
import { RouteTableFilters } from '../../../services/api.types'
import ColumnFilter from '../../UI/ColumnFilter'
import ContactTag from '../../UI/ContactTag'
import ContentHeader from '../../UI/ContentHeader'
import DataTable from '../../UI/DataTable'
import RouteForm from './RouteForm'

function RouteList() {
  const rootStore = useStores()
  const sharedClasses = useSharedStyles()

  const { filterValues, filters, updateFilters } = useFilters<RouteTableFilters>({
    routeName: '',
    routeType: null,
    campus: '',
  })

  const { routeName, routeType, campus } = filterValues

  return (
    <>
      <ContentHeader pageName="Manage Routes" hideSelectedRoute />
      <Paper className={sharedClasses.pagePaper}>
        <DataTable
          enableCreate={rootStore.isAdmin}
          idProperty="routeId"
          request={api.getRoutes}
          filters={filters}
          download={api.downloadRoutes}
          renderHeadCells={() => (
            <>
              <ColumnFilter labelTx="Route" filtering={!!routeName}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Search Route"
                  type="search"
                  value={routeName}
                  onChange={event => updateFilters({ routeName: event.target.value }, true)}
                />
              </ColumnFilter>
              <TableCell>Display Name</TableCell>
              <ColumnFilter labelTx="Morning / Afternoon" filtering={!!routeType}>
                <Autocomplete
                  size="small"
                  openOnFocus
                  options={['Morning', 'Afternoon']}
                  renderInput={params => (
                    <TextField {...params} label="Choose Morning / Afternoon" variant="outlined" />
                  )}
                  value={routeType}
                  onChange={(e: React.ChangeEvent<{}>, value: string | null, reason: AutocompleteChangeReason) =>
                    updateFilters({ routeType: value })
                  }
                />
              </ColumnFilter>
              <ColumnFilter labelTx="Campus" filtering={!!campus}>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Search Campus"
                  type="search"
                  value={campus}
                  onChange={event => updateFilters({ campus: event.target.value }, true)}
                />
              </ColumnFilter>
              <TableCell>Designated Driver</TableCell>
              <TableCell>Designated Monitor</TableCell>
            </>
          )}
          renderRowCells={(route: RouteSnapshot) => (
            <>
              <TableCell>
                <Chip label={route.routeName} style={{ borderColor: route.color }} variant="outlined" />
              </TableCell>
              <TableCell>{route.displayName}</TableCell>
              <TableCell>{route.routeType}</TableCell>
              <TableCell>{route.campus}</TableCell>
              <TableCell>{route.driver && <ContactTag contact={route.driver} />}</TableCell>
              <TableCell>{route.chaperone && <ContactTag contact={route.chaperone} />}</TableCell>
            </>
          )}
          onDeleteContext={{
            confirm: {
              title: 'Delete Route',
              messages: [
                'Are you sure you want to delete this route?',
                '(deleting this route will delete all data associated with it e.g. stops, pupils, users etc.)',
              ],
            },
            request: api.deleteRoute,
            successMessage: 'Route has been deleted successfully',
            successCallback: (routeId: number) => {
              if (rootStore.selectedRouteId === routeId) {
                rootStore.setSelectedRouteId()
              }
              rootStore.getAllRoutes()
            },
          }}
          formComponent={RouteForm}
          formClosed={(reload?: boolean) => {
            if (reload) {
              rootStore.getAllRoutes()
            }
          }}
        />
      </Paper>
    </>
  )
}

export default observer(RouteList)
