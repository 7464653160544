import { types, Instance, SnapshotOut } from 'mobx-state-tree'

export const StopOverviewModel = types.model('StopOverview').props({
  stopId: types.number,
  stopName: types.string,
  stopTime: types.string,
  childCount: types.number,
  lat: types.number,
  lon: types.number,
  address: types.string,
  routeId: types.number,
  routeName: types.string,
  // TODO: maybe we should create RouteType type
  routeType: types.union(types.literal('Morning'), types.literal('Afternoon')),
})

export interface StopOverview extends Instance<typeof StopOverviewModel> {}
export interface StopOverviewSnapshot extends SnapshotOut<typeof StopOverviewModel> {}
