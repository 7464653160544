import { colors, Container, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import FaqItem from './FaqItem'
import faqItems from './questions'

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.grey[300],
    paddingTop: 25,
    paddingBottom: 25,
  },
  title: {
    marginBottom: 20,
  },
})

export default function Faq() {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Typography variant="h5" color="primary" className={classes.title}>
        {'FAQ'}
      </Typography>
      {faqItems.map((faqItem, index) => (
        <FaqItem key={index} {...faqItem} />
      ))}
    </Container>
  )
}
