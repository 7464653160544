import { Box, CircularProgress } from '@material-ui/core'
import React from 'react'

interface LoadingOverlayProps {
  visible: boolean
}

export default function LoadingOverlay(props: LoadingOverlayProps) {
  const { visible } = props
  return visible ? (
    <Box
      position="absolute"
      left={0}
      right={0}
      top={0}
      bottom={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex={10}
      style={{ backgroundColor: 'rgba(255, 255, 255, 0.38)' }}
    >
      <CircularProgress />
    </Box>
  ) : null
}
