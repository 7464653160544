import { useEffect, useMemo, useRef, useState } from 'react'
import { useStores } from '../models/root-store'
import { useLocalStorage } from './useLocalStorage'
// import { DEFAULT_PAGE_OPTIONS } from '../utils/constant'

interface PaginationConfig {
  pageSize: 25
}

export function usePagination(config?: PaginationConfig) {
  const rootStore = useStores()
  const [page, setPage] = useState(0)
  // TODO: initial size is hard coded value and should make sure we include it in rowsPerPageOptions prop for TablePagination, need to create type based on DEFAULT_PAGE_OPTIONS
  // component
  const { storedValue: size, setValue: setSize } = useLocalStorage<number>('pageSize', config?.pageSize || 25)
  const [total, setTotal] = useState(0)

  const tableRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    tableRef?.current?.scrollTo({ top: 0 })
  }, [page, size])

  const handleChangePage = (event: any, page: number) => {
    setPage(page)
  }

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSize(+event.target.value)
  }

  useEffect(() => {
    setPage(0)
  }, [size, rootStore.selectedRouteId])

  const hasMore = useMemo(() => {
    return total > size * (page + 1)
  }, [total, page, size])

  return {
    page,
    size,
    total,
    tableRef,
    handleChangePage,
    handleChangePageSize,
    setTotal,
    setPage,
    hasMore,
  }
}
