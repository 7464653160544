import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  MenuItem,
  Popover,
  TextField as MuiTextField,
} from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
//@ts-ignore
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab'
import { bindFocus, bindPopover, usePopupState } from 'material-ui-popup-state/hooks'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useReducer, useState } from 'react'
import { ColorResult, CompactPicker } from 'react-color'
import styled from 'styled-components'
import * as Yup from 'yup'
import { api } from '../../../models/environment'
import { useStores } from '../../../models/root-store'
import { RouteSnapshot } from '../../../models/route'
import { MessageVariant } from '../../../models/snackbar-message'
import { FormProps } from '../../UI/DataTable'
import LoadingOverlay from '../../UI/LoadingOverlay'
import SubmitButton from '../../UI/SubmitButton'
import UserSelect from '../../UI/Select/UserSelect'
import { useSharedStyles } from '../../../hooks/useSharedStyles'
import { formReducer } from './reducer'

const ColorCircle = styled.div(props => ({
  width: 10,
  height: 10,
  backgroundColor: props.color || '#000',
}))

function RouteForm(props: FormProps<RouteSnapshot>) {
  const { record: route, closeForm } = props
  const classes = useSharedStyles()
  const rootStore = useStores()
  const [loadingCampuses, setLoadingCampuses] = useState(false)

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'colorPicker',
  })

  const [state, dispatch] = useReducer(formReducer.reducer, formReducer.state)

  useEffect(() => {
    if (route) {
      dispatch({ type: 'SET_LOADING', payload: true })
      // load route detail data for update
      api
        .getRoute(route.routeId)
        .then(response => {
          if (response.success) {
            dispatch({ type: 'SET_RECORD', payload: response.data as RouteSnapshot })
          } else {
            // TODO: enqueue error message, and close form
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', payload: false }))
    } else {
      dispatch({ type: 'SET_RECORD', payload: null })
    }
  }, [route])

  useEffect(() => {
    setLoadingCampuses(true)
    rootStore.getAllCampuses().finally(() => setLoadingCampuses(false))
  }, [rootStore])

  const routeId = route?.routeId
  return (
    <Dialog open={true} fullWidth maxWidth="md" onClose={() => closeForm()}>
      <DialogTitle>{routeId ? 'Edit Route' : 'Add Route'}</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={state.values}
        validationSchema={Yup.object().shape({
          routeName: Yup.string()
            .matches(/^[A-Z]$/, 'Route should be A-Z')
            .required('Route should be A-Z'),
          routeType: Yup.string().label('Route Type').oneOf(['Morning', 'Afternoon']),
          // displayName: Yup.string().label('Display Name'),
          // campus: Yup.string().label('Campus'),
          color: Yup.string().label('Color').required(),
          chaperoneId: Yup.number().min(1, 'Monitor is a required field').required('Monitor is a required field'),
        })}
        onSubmit={async values => {
          const response = routeId ? await api.updateRoute(routeId, values) : await api.addRoute(values)
          if (response.success) {
            const message = routeId ? 'Route has been updated successfully' : 'Route has been added successfully'
            rootStore.enqueueSnackbar(message, MessageVariant.success)
            closeForm(true)
          }
        }}
      >
        {({ values, submitForm, isSubmitting, touched, errors, setFieldValue }) => (
          <>
            <DialogContent>
              <Form>
                <Grid container>
                  <Grid item container direction="column" xs={6} className={classes.formColumn} spacing={2}>
                    <Grid item>
                      <Field
                        component={TextField}
                        name="routeName"
                        label="Route"
                        variant="outlined"
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const routeName = e.target.value.toUpperCase()
                          setFieldValue('routeName', routeName)
                          const color = rootStore.routes.find(n => n.routeName === routeName)?.color
                          if (color) {
                            setFieldValue('color', color)
                          }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        component={TextField}
                        name="displayName"
                        label="Display Name"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        component={TextField}
                        name="routeType"
                        label="Morning / Afternoon"
                        variant="outlined"
                        fullWidth
                        select
                      >
                        <MenuItem value="Morning">Morning</MenuItem>
                        <MenuItem value="Afternoon">Afternoon</MenuItem>
                      </Field>
                    </Grid>
                    <Grid item>
                      <Field
                        name="campus"
                        component={Autocomplete}
                        options={rootStore.campuses}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                          <MuiTextField
                            {...params}
                            error={touched['campus'] && !!errors['campus']}
                            helperText={errors['campus']}
                            label="Campus"
                            variant="outlined"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setFieldValue('campus', e.target.value)
                            }}
                          />
                        )}
                        freeSolo
                        fullWidth
                        loading={loadingCampuses}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container direction="column" xs={6} className={classes.formColumn} spacing={2}>
                    <Grid item>
                      <Field
                        component={TextField}
                        name="color"
                        label="Color"
                        variant="outlined"
                        {...bindFocus(popupState)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <ColorCircle color={values.color} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Popover
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        {...bindPopover(popupState)}
                      >
                        <CompactPicker
                          color={values.color}
                          onChange={(color: ColorResult) => setFieldValue('color', color.hex.toUpperCase())}
                        />
                      </Popover>
                    </Grid>
                    <Grid item>
                      <UserSelect
                        filterFn={user => user.role === 'CHAPERONE'}
                        userId={values.chaperoneId}
                        setUserId={userId => setFieldValue('chaperoneId', userId)}
                        name="chaperoneId"
                        label="Monitor"
                        error={!!touched['chaperoneId'] && !!errors['chaperoneId']}
                        helperText={touched['chaperoneId'] && errors['chaperoneId']}
                      />
                    </Grid>
                    <Grid item>
                      <UserSelect
                        filterFn={user => user.role === 'DRIVER'}
                        userId={values.driverId}
                        setUserId={userId => setFieldValue('driverId', userId)}
                        name="driverId"
                        label="Driver (Optional)"
                        error={!!touched['driverId'] && !!errors['driverId']}
                        helperText={touched['driverId'] && errors['driverId']}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
              <LoadingOverlay visible={state.loading} />
            </DialogContent>

            <DialogActions>
              <Button variant="contained" onClick={() => closeForm()}>
                Cancel
              </Button>
              <SubmitButton onClick={submitForm} submitting={isSubmitting} text="Save" />
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  )
}

export default observer(RouteForm)
