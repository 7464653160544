import { CircularProgress, Grid, makeStyles, Typography, colors } from '@material-ui/core'
import classnames from 'classnames'
import { formatDistanceToNowStrict } from 'date-fns'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { AutoSizer, List, ListRowProps, Size } from 'react-virtualized'
import { useFilters } from '../../hooks/useFilters'
// import { useSharedStyles } from '../../hooks/useSharedStyles'
import { api } from '../../models/environment'
import { GroupChatSnapshot } from '../../models/group-chat'
import { useStores } from '../../models/root-store'
import { GroupChatListFilters } from '../../services/api.types'
import { getUsername } from '../../utils/format'

interface GroupChatListProps {
  childId: number | undefined
  onSelect(groupChat: GroupChatSnapshot | null): void
}

const GROUP_CHAT_HEIGHT = 75

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.grey[100],
  },
  groupChat: {
    cursor: 'pointer',
    padding: '6px 12px',
    '&:hover, &.selected': {
      background: 'rgba(0, 0, 0, 0.08)',
    },
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    // borderBottomColor: colors.grey[300],
    borderBottomColor: 'black',

    '&:last-child': {
      borderBottomColor: 'transparent',
    },
  },
  groupChatLeft: {
    width: 200,
  },
  groupChatRight: {
    width: 75,
  },
  groupChatTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  groupChatMessagePreview: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  groupChatSecondaryText: {
    color: colors.grey[600],
  },
})

function GroupChatList(props: GroupChatListProps) {
  const { onSelect, childId } = props
  const rootStore = useStores()
  const classes = useStyles()
  // const sharedClasses = useSharedStyles()

  const [groupChats, setGroupChats] = useState<GroupChatSnapshot[]>([])
  const [loading, setLoading] = useState(false)
  const { filters } = useFilters<GroupChatListFilters>({
    routeName: rootStore.selectedRouteName,
    kw: '',
  })

  const listRef = useRef<List | null>(null)
  const lastChildId = useRef<number | undefined>()

  useEffect(() => {
    if (!filters.routeName) return
    setLoading(true)
    api
      .getAllGroupChats(filters)
      .then(response => {
        if (response.success) {
          setGroupChats(response.data ? (response.data.filter(n => !!n.lastMessage) as GroupChatSnapshot[]) : [])
        }
      })
      .finally(() => setLoading(false))
  }, [filters])

  useEffect(() => {
    let groupChat: GroupChatSnapshot | null = null
    if (groupChats.length > 0) {
      if (childId) {
        groupChat = groupChats.find(n => n.child.childId === childId) || groupChats[0]
      } else {
        groupChat = groupChats[0]
      }
      onSelect(groupChat)
      lastChildId.current = groupChat.child.childId
      // scroll to record
    }
  }, [childId, groupChats, onSelect])

  const groupChatRenderer = useCallback(
    (props: ListRowProps) => {
      const { index, key, style } = props
      const groupChat = groupChats[index]
      const { child, lastMessage } = groupChat

      return (
        <Grid
          key={key}
          container
          alignItems="stretch"
          justify="space-between"
          className={classnames(classes.groupChat, { selected: child.childId === childId })}
          style={style}
          onClick={() => {
            lastChildId.current = groupChat.child.childId
            onSelect(groupChat)
          }}
        >
          <Grid item container direction="column" className={classes.groupChatLeft}>
            <Grid
              item
              style={{
                maxWidth: '100%',
              }}
            >
              <Typography variant="subtitle2" className={classes.groupChatTitle}>
                {getUsername(child)}
              </Typography>
            </Grid>
            {lastMessage && (
              <Grid item xs>
                <Typography
                  variant="body2"
                  className={classnames(classes.groupChatSecondaryText, classes.groupChatMessagePreview)}
                >
                  {`${lastMessage.user.firstName}: ${lastMessage.message}`}
                </Typography>
              </Grid>
            )}
          </Grid>
          {lastMessage && (
            <Grid item className={classes.groupChatRight}>
              <Typography variant="body2" className={classes.groupChatSecondaryText} align="right">
                {formatDistanceToNowStrict(lastMessage.sentAt)}
              </Typography>
            </Grid>
          )}
        </Grid>
      )
    },
    [
      childId,
      classes.groupChat,
      classes.groupChatLeft,
      classes.groupChatMessagePreview,
      classes.groupChatRight,
      classes.groupChatSecondaryText,
      classes.groupChatTitle,
      groupChats,
      onSelect,
    ]
  )

  return (
    <AutoSizer disableWidth>
      {({ height }: Size) =>
        loading ? (
          <div
            style={{ height, width: 320, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            className={classes.root}
          >
            <CircularProgress />
          </div>
        ) : (
          <List
            ref={listRef}
            className={classes.root}
            height={height}
            width={320}
            overscanRowCount={5}
            rowCount={groupChats.length}
            rowHeight={GROUP_CHAT_HEIGHT}
            rowRenderer={groupChatRenderer}
          />
        )
      }
    </AutoSizer>
  )
}

export default observer(GroupChatList)
