import { colors, Container, Grid, Link, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import AppLogName from '../../assets/images/app-logo-name.svg'
import BackgroundGraph from '../../assets/images/background-graph.svg'

const useStyles = makeStyles({
  root: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.8)), url(${BackgroundGraph})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '0 15px',
    minHeight: '100%',
  },
  content: {
    marginTop: 20,
  },
  main: {
    padding: '0 15px',
    flex: 1,
  },
  logo: {
    width: 230,
    height: 205,
  },
  title: {
    fontWeight: 'bold',
  },
  copyright: {
    color: colors.blue[900],
    fontWeight: 'bold',
    padding: '20px 0',
    textAlign: 'center',
  },
})

interface LayoutProps {
  children: any
  title?: string
  subtitle?: string
}

function Layout(props: LayoutProps) {
  const classes = useStyles()
  const { title, subtitle } = props

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item container direction="column" justify="center" alignItems="center" className={classes.main}>
        <img alt="Application Logo and Name" src={AppLogName} className={classes.logo} />
        {title && (
          <Typography color="primary" variant="h5" className={classes.title} align="center">
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography color="textSecondary" variant="subtitle1" className={classes.title} align="center">
            {subtitle}
          </Typography>
        )}
        <Container fixed maxWidth="xs" className={classes.content}>
          {props.children}
        </Container>
      </Grid>
      <Grid item className={classes.copyright}>
        <Link align="center" underline="none" target="_blank" href="https://www.bizzybus.co.uk/">
          Powered by Bizzybus Ltd.
        </Link>
      </Grid>
    </Grid>
  )
}

export default Layout
