import { StopOverviewModel } from './../stop/stop-overview'
import { UserModel } from './../user'
import { types, Instance, SnapshotOut } from 'mobx-state-tree'
import { sum } from 'ramda'

export const RouteBaseModel = types
  .model('RouteBase')
  .props({
    routeId: types.number,
    routeName: types.string,
    routeType: types.union(types.literal('Morning'), types.literal('Afternoon')),
    displayName: types.maybeNull(types.string),
    campus: types.maybeNull(types.string),
    color: types.string,
    chaperone: types.maybeNull(UserModel),
    driver: types.maybeNull(UserModel),
    stops: types.array(StopOverviewModel),
  })
  .views(self => ({
    get selectOptionLabel() {
      return `Route ${self.routeName} - ${self.routeType}`
    },

    get childrenCount() {
      return sum(self.stops.map(stop => stop.childCount))
    },
  }))

/**
 * The RootStore instance.
 */
export interface RouteBase extends Instance<typeof RouteBaseModel> {}

/**
 * The data of a RootStore.
 */
export interface RouteBaseSnapshot extends SnapshotOut<typeof RouteBaseModel> {}
