import { Grid, IconButton, TableCell } from '@material-ui/core'
import React from 'react'
import { DeleteForeverIcon, EditIcon } from './Icons'

interface TableRowActionCell {
  onEdit?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onDelete?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export default function RowActionCell(props: TableRowActionCell) {
  const { onEdit, onDelete } = props
  // const popupState = usePopupState({
  //   variant: 'popover',
  //   popupId: 'morePopover',
  // })

  return (
    <TableCell style={{ whiteSpace: 'nowrap' }}>
      <Grid container wrap="nowrap">
        {onEdit && (
          <Grid item>
            <IconButton color="primary" size="small" onClick={onEdit}>
              <EditIcon />
            </IconButton>
          </Grid>
        )}
        {onDelete && (
          <Grid item>
            <IconButton color="secondary" size="small" onClick={onDelete}>
              <DeleteForeverIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>

      {/* {onDelete && (
        <>
          <IconButton color="default" size="small" {...bindTrigger(popupState)}>
            <MoreVert />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
            transformOrigin={{
              horizontal: 'left',
              vertical: 'top',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={onDelete}
              startIcon={<DeleteForeverIcon />}
            >
              Delete
            </Button>
          </Popover>
        </>
      )} */}
    </TableCell>
  )
}
