import { types, Instance, SnapshotOut } from 'mobx-state-tree'

export const LocationModel = types.model('Location').props({
  lat: types.number,
  lon: types.number,
  postcode: types.maybeNull(types.string),
})

export interface Location extends Instance<typeof LocationModel> {}
export interface LocationSnapshot extends SnapshotOut<typeof LocationModel> {}
