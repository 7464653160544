import { Grid, makeStyles, Typography } from '@material-ui/core'
import { addMonths, format, startOfMonth } from 'date-fns'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { QuickStatsSnapshot } from '../models/archive/quick-stats'
import { api } from '../models/environment'
import { useStores } from '../models/root-store'
import { OverviewMap } from './Map'
import ContentHeader from './UI/ContentHeader'

const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  quickStats: {
    marginTop: 25,
  },
  quickStatsItem: {
    width: 145,
  },
  statCircle: {
    display: 'table-cell',
    width: 75,
    height: 75,
    background: 'linear-gradient(-90deg, rgb(55,158,240), rgb(128,122,247))',
    textAlign: 'center',
    borderRadius: '50%',
    verticalAlign: 'middle',
  },
  statValue: {
    fontWeight: 'bold',
    color: '#fff',
  },
  statLabel: {
    marginTop: 35,
  },
})

function QuickStatsItem(props: { text: string; label: string }) {
  const { text, label } = props
  const classes = useStyles()
  return (
    <div className={classes.quickStatsItem}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <div className={classes.statCircle}>
            <Typography variant="h5" className={classes.statValue}>
              {text}
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <Typography variant="body2" className={classes.statLabel}>
            {label}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

function Dashboard() {
  const classes = useStyles()
  const [quickStats, setQuickStats] = useState<QuickStatsSnapshot | undefined>()
  const rootStore = useStores()

  useEffect(() => {
    // TODO: date picker
    const dateTo = format(new Date(), 'yyyy-MM-dd')
    const dateFrom = format(addMonths(startOfMonth(new Date()), -3), 'yyyy-MM-dd')
    api
      .getQuickStats({
        dateFrom,
        dateTo,
        routeId: rootStore.selectedRouteId,
      })
      .then(response => {
        if (response.success) {
          setQuickStats(response.data)
        }
      })
  }, [rootStore, rootStore.selectedRouteId])

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <OverviewMap />
      </Grid>
      <Grid item className={classes.quickStats} xs>
        <ContentHeader pageName="Quick Stats" hideSelectedRoute />
        {quickStats && (
          <Grid item container>
            <Grid item>
              <QuickStatsItem
                text={quickStats.attendanceRate === null ? 'N/A' : `${Math.round(quickStats.attendanceRate * 100)}%`}
                label="Attendance"
              />
            </Grid>
            <Grid item>
              <QuickStatsItem
                text={quickStats.onTimeRate === null ? 'N/A' : `${Math.round(quickStats.onTimeRate * 100)}%`}
                label="On Time"
              />
            </Grid>
            <Grid item>
              <QuickStatsItem text={`${quickStats.additionalChildrenCount}`} label="Additional Children" />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default observer(Dashboard)
