import { makeStyles } from '@material-ui/core'
import theme from '../theme'

export const useSharedStyles = makeStyles({
  // the main content each page
  pagePaper: {
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    // relative so overlay could use absolute position to cover all spaces
    position: 'relative',
    flexGrow: 1,
    flexBasis: 200,
    display: 'flex',
    flexDirection: 'column',
  },
  tableToolbar: {
    padding: '0 15px',
  },
  tableToolbarButton: {
    position: 'relative',
  },
  tableDownloadButtonProgress: {
    position: 'absolute',
    left: 5,
    top: 5,
  },
  formColumn: {
    padding: theme.spacing(0),
    paddingRight: theme.spacing(5),
  },
  formCardActions: {
    padding: 16,
  },
})
