import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@material-ui/core'
import { isArray } from 'lodash'
import React from 'react'

interface AlertDialogProps extends Pick<DialogProps, 'open'> {
  title: string
  content: string | string[]
  handleClose: () => void
}

export default function AlertDialog(props: AlertDialogProps) {
  const { open, handleClose, title, content } = props
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {(isArray(content) ? content : [content]).map((message, index) => (
          <DialogContentText key={index}>{message}</DialogContentText>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
