import { Instance, SnapshotOut, types } from 'mobx-state-tree'

/**
 * User detail, names could be empty for unregistered user
 */
export const PersonModel = types
  .model('Person')
  .props({
    firstName: types.optional(types.string, '', [null]),
    lastName: types.optional(types.string, '', [null]),
  })
  .views(self => ({
    get name() {
      if (self.lastName && self.firstName) return `${self.firstName} ${self.lastName}`
      else return ''
    },
  }))

export interface Person extends Instance<typeof PersonModel> {}
export interface PersonSnapshot extends SnapshotOut<typeof PersonModel> {}
