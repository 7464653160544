import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useStores } from '../models/root-store'
import { autorun } from 'mobx'

function Notifier() {
  const rootStore = useStores()

  const [displayed, setDisplayed] = useState<number[]>([])

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    autorun(() => {
      rootStore.snackMessages.forEach(message => {
        const { key, tx, variant } = message
        if (displayed.includes(key)) return
        enqueueSnackbar(tx, {
          variant,
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        })
        setDisplayed([...displayed, key])
        rootStore.removeSnackbar(message)
      })
    })
  }, [displayed, enqueueSnackbar, rootStore])

  return null
}

export default Notifier
