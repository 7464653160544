import DateFnsUtils from '@date-io/date-fns'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarProvider } from 'notistack'
import React, { useMemo } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './components/Home'
import Notifier from './components/Notifier'
import Activation from './components/Public/Activation'
import ForgotPassword from './components/Public/ForgotPassword'
import Login from './components/Public/Login'
import ParentRegistration from './components/Public/ParentRegistration'
import PasswordReset from './components/Public/PasswordReset'
import { RootStoreProvider, setupRootStore } from './models/root-store'
import theme from './theme'

function App() {
  const rootStore = useMemo(() => setupRootStore(), [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RootStoreProvider value={rootStore}>
        <SnackbarProvider maxSnack={3}>
          <Router>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Switch>
                <Route path="/login">
                  <Login />
                </Route>
                <Route path="/forgot-password">
                  <ForgotPassword />
                </Route>
                <Route path="/password-reset/:verificationCode">
                  <PasswordReset />
                </Route>
                <Route path="/parent-registration/:verificationCode">
                  <ParentRegistration />
                </Route>
                <Route path="/activation/:verificationCode">
                  <Activation />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </MuiPickersUtilsProvider>
          </Router>
          <Notifier />
        </SnackbarProvider>
      </RootStoreProvider>
    </ThemeProvider>
  )
}

export default App
