import { Api } from '../services/api'
// import { StompClient } from '../services/websocket'

// TODO: setup reactotron
// let ReactotronDev
// if (__DEV__) {
//   const { Reactotron } = require('../services/reactotron')
//   ReactotronDev = Reactotron
// }

const api = new Api()
api.setup()

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
class Environment {
  /**
   * Reactotron is only available in dev.
   */
  // reactotron: typeof ReactotronDev

  api: Api

  // stompClient: StompClient
  constructor() {
    // // create each service
    // if (__DEV__) {
    //   // dev-only services
    //   this.reactotron = new ReactotronDev()
    // }
    this.api = api
    // this.stompClient = new StompClient()
  }

  setup() {
    // allow each service to setup
    // if (__DEV__) {
    //   await this.reactotron.setup()
    // }
    // add a reference so that websocket token could be updated
    // this.api.setup()
  }
}

export { api, Environment }
