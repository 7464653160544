import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { UserModel } from './user'

export const FlagModel = types.model('Flag').props({
  dateOccurred: types.string,
  chaperone: UserModel,
  flag: types.string,
  routeId: types.number,
})

export interface Flag extends Instance<typeof FlagModel> {}
export interface FlagSnapshot extends SnapshotOut<typeof FlagModel> {}
