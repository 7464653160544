import styled from 'styled-components'
import React from 'react'
import { Grid, Typography } from '@material-ui/core'

const Container = styled(Grid)`
  width: 100vw;
  height: 100vh;
`

export default function Authenticating() {
  return (
    <Container container alignItems="center">
      <Grid item xs>
        <Typography align="center">Authenticating...</Typography>
      </Grid>
    </Container>
  )
}
