import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { LocationModel } from '../location'

/**
 * Model description here for TypeScript hints.
 */
export const StopModel = types.model('Stop').props({
  stopId: types.number,
  stopName: types.string,
  stopTime: types.string,
  address: types.string,
  latitude: types.number,
  longitude: types.number,
  location: types.optional(LocationModel, { lat: 0, lon: 0, postcode: '' }),
  routeId: types.number,
  routeName: types.string,
})

export interface Stop extends Instance<typeof StopModel> {}
export interface StopSnapshot extends SnapshotOut<typeof StopModel> {}
