import { STOP_CIRCLE_MARKER_SIZE } from './constant'

export const getStopMarkerIcon = (color: string) => {
  const canvas = document.createElement('canvas')
  canvas.width = STOP_CIRCLE_MARKER_SIZE
  canvas.height = STOP_CIRCLE_MARKER_SIZE
  const context = canvas.getContext('2d') as CanvasRenderingContext2D
  const cx = STOP_CIRCLE_MARKER_SIZE / 2
  const cy = STOP_CIRCLE_MARKER_SIZE / 2
  context.beginPath()
  context.arc(cx, cy, STOP_CIRCLE_MARKER_SIZE / 2, 0, 2 * Math.PI, false)
  context.fillStyle = color
  context.fill()

  return canvas.toDataURL('image/png')
}
