import {
  Badge,
  Box,
  Button,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState, useRef } from 'react'
import { NavLink, NavLinkProps, Redirect, Route, RouteProps, Switch, useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import IconCall from '../assets/icons/IconCall'
import IconMessage from '../assets/icons/IconMessage'
import AppLogoName from '../assets/images/app-logo-name-white.png'
import SchoolContext from '../contexts/SchoolContext'
import { useStores } from '../models/root-store'
import Archive from './Archive/Archive'
import Authenticating from './Authenticating'
import CallLogTable from './CallLogTable'
// TODO: use route config
// import routes, { RouteConfig } from '../routes'
import Dashboard from './Dashboard'
import FlagTable from './FlagTable'
import ChildList from './Manage/Child/ChildList'
import RouteList from './Manage/Route/RouteList'
import StopList from './Manage/Stop/StopList'
import ManagerUser from './Manage/User/ManageUser'
import { MessageLog } from './Message'
import NotFound from './NotFound'
import Profile from './Profile'
import Registers from './Registers'
import RouteSelector from './RouteSelector'
import SchoolSelect from './SchoolSelect'
import Support from './Support'
import {
  IconArchive,
  IconAvatar,
  IconBus,
  IconExchange,
  IconFlag,
  IconLocation,
  IconRegisters,
  IconSupport,
} from './UI/Icons'

const useStyles = makeStyles({
  root: {
    minHeight: '100%',
    display: 'flex',
  },
  navbar: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 270,
    background: 'linear-gradient(rgb(55,158,240), rgb(128,122,247))',
    display: 'flex',
    flexDirection: 'column',
  },
  navbarItem: {
    color: 'white',
  },
  navbarItemIcon: {
    color: 'white',
  },
  nested: {},
  content: {
    flex: 1,
    padding: 25,
    paddingBottom: 48,
    display: 'flex',
    flexDirection: 'column',
  },
  menu: {},
  logo: {
    width: 72,
  },
  logoContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 30,
  },
})

const NavLinkMui = React.forwardRef<any, NavLinkProps>((props, ref) => (
  <NavLink {...props} activeClassName="Mui-selected" ref={ref} />
))

const NavBarDivider = styled(Divider)`
  height: 3px;
  margin-top: 16px;
  margin-bottom: 16;
`

const isManageMenu = (pathname: string) => pathname.startsWith('/manage')

function Home(props: RouteProps) {
  const classes = useStyles()
  const rootStore = useStores()
  const history = useHistory()
  const location = useLocation()

  const { isAdmin, selectedRouteName } = rootStore

  // per discussion 20200831, data loading progress visual feature is cancelled
  // useDataProvider()

  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false)

  // TODO: if there will be more collapsible nav menu, create model and manage states in store as a map
  const [manageMenuOpen, setManageMenuOpen] = useState(isManageMenu(location.pathname))

  const [showRouteSelector, setShowRouteSelector] = useState(true)
  const intervalIdForTodayFlags = useRef<number>()

  useEffect(() => {
    if (!rootStore.user) {
      rootStore.getCurrentUser().then(success => {
        if (!success) history.push('/login')
      })
    }
  }, [history, rootStore])

  useEffect(() => {
    if (rootStore.user && rootStore.schoolCode) {
      // get master data
      rootStore.getAllRoutes()
      rootStore.getAllStops()
      rootStore.getTodayFlags()

      intervalIdForTodayFlags.current = window.setInterval(() => {
        rootStore.getTodayFlags()
      }, 60e3)
    }
  }, [rootStore.user, rootStore.schoolCode, rootStore, intervalIdForTodayFlags])

  useEffect(() => {
    if (intervalIdForTodayFlags.current) {
      clearTimeout(intervalIdForTodayFlags.current)
    }
  }, [intervalIdForTodayFlags])

  useEffect(() => {
    if (
      location.pathname === '/manage/routes' ||
      location.pathname === '/manage/users' ||
      location.pathname === '/support'
    )
      setShowRouteSelector(false)
    else setShowRouteSelector(true)
  }, [location.pathname])

  const logoutUser = () => {
    setShowLogoutConfirm(false)
    rootStore.logout()
    history.push('/login')
  }

  if (!rootStore.user) return <Authenticating />
  if (!rootStore.schoolCode) return <SchoolSelect />

  return (
    <SchoolContext.Provider value={{ timeZone: rootStore.school?.timeZone || 'Europe/London' }}>
      <Box className={classes.root}>
        {/* TODO: create navigation component */}
        <Container className={classes.navbar}>
          <Profile />
          <NavBarDivider variant="middle" />
          <List component="nav" className={classes.menu}>
            {/* TODO: use map to create items */}
            <ListItem className={classes.navbarItem} component={NavLinkMui} to="/dashboard">
              <ListItemIcon className={classes.navbarItemIcon}>
                <IconLocation />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem className={classes.navbarItem} button onClick={() => setManageMenuOpen(!manageMenuOpen)}>
              <ListItemIcon className={classes.navbarItemIcon}>
                <IconBus />
              </ListItemIcon>
              <ListItemText primary="Manage" />
            </ListItem>
            <Collapse in={manageMenuOpen}>
              <List>
                <ListItem className={classes.navbarItem} component={NavLinkMui} to="/manage/users">
                  <ListItemText
                    inset
                    className={classes.nested}
                    primary="Users"
                    primaryTypographyProps={{ variant: 'body2' }}
                  />
                </ListItem>
                <ListItem className={classes.navbarItem} component={NavLinkMui} to="/manage/routes">
                  <ListItemText
                    inset
                    className={classes.nested}
                    primary="Routes"
                    primaryTypographyProps={{ variant: 'body2' }}
                  />
                </ListItem>
                <ListItem className={classes.navbarItem} component={NavLinkMui} to="/manage/stops">
                  <ListItemText
                    inset
                    className={classes.nested}
                    primary="Stops"
                    primaryTypographyProps={{ variant: 'body2' }}
                  />
                </ListItem>
                <ListItem className={classes.navbarItem} component={NavLinkMui} to="/manage/children">
                  <ListItemText
                    inset
                    className={classes.nested}
                    primary="Pupils"
                    primaryTypographyProps={{ variant: 'body2' }}
                  />
                </ListItem>
              </List>
            </Collapse>
            <ListItem className={classes.navbarItem} component={NavLinkMui} to="/registers">
              <ListItemIcon className={classes.navbarItemIcon}>
                <IconRegisters />
              </ListItemIcon>
              <ListItemText primary="Registers" />
            </ListItem>
            <ListItem className={classes.navbarItem} component={NavLinkMui} to="/flags">
              <ListItemIcon className={classes.navbarItemIcon}>
                <Badge color="secondary" badgeContent={rootStore.flagCount} showZero={false} max={99}>
                  <IconFlag />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Flags" />
            </ListItem>
            <ListItem className={classes.navbarItem} component={NavLinkMui} to="/messages">
              <ListItemIcon className={classes.navbarItemIcon}>
                <IconMessage />
              </ListItemIcon>
              <ListItemText primary="Messages" />
            </ListItem>
            {isAdmin && (
              <ListItem className={classes.navbarItem} component={NavLinkMui} to="/call-log">
                <ListItemIcon className={classes.navbarItemIcon}>
                  <IconCall />
                </ListItemIcon>
                <ListItemText primary="Call Log" />
              </ListItem>
            )}
            <ListItem className={classes.navbarItem} component={NavLinkMui} to="/archive">
              <ListItemIcon className={classes.navbarItemIcon}>
                <IconArchive />
              </ListItemIcon>
              <ListItemText primary="Archive" />
            </ListItem>
            <ListItem className={classes.navbarItem} component={NavLinkMui} to="/support">
              <ListItemIcon className={classes.navbarItemIcon}>
                <IconSupport />
              </ListItemIcon>
              <ListItemText primary="Support" />
            </ListItem>
            <NavBarDivider variant="middle" />
            <ListItem className={classes.navbarItem} button onClick={() => setShowLogoutConfirm(true)}>
              <ListItemIcon className={classes.navbarItemIcon}>
                <IconAvatar />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
            {/* <ListItem className={classes.navbarItem} button>
              <ListItemIcon className={classes.navbarItemIcon}>
                <IconGear />
              </ListItemIcon>
              <ListItemText primary="Account" />
            </ListItem> */}
            <ListItem className={classes.navbarItem} button onClick={() => rootStore.setSchoolCode(null)}>
              <ListItemIcon className={classes.navbarItemIcon}>
                <IconExchange />
              </ListItemIcon>
              <ListItemText primary="Change School" />
            </ListItem>
          </List>
          <Box className={classes.logoContainer}>
            <img className={classes.logo} src={AppLogoName} alt="app logo and name" />
          </Box>
        </Container>
        {showRouteSelector && <RouteSelector />}
        <Box className={classes.content}>
          <Switch>
            <Route path="/" exact>
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/dashboard" component={Dashboard} />
            <Route exact={true} path="/manage">
              <Redirect to="/manage/children" />
            </Route>
            <Route path="/manage/users">
              <ManagerUser />
            </Route>
            <Route path="/manage/routes">
              <RouteList />
            </Route>
            <Route path="/manage/stops">
              <StopList />
            </Route>
            <Route path="/manage/children">
              <ChildList />
            </Route>
            <Route path="/registers">
              <Registers />
            </Route>
            <Route path="/flags">
              <FlagTable />
            </Route>
            <Route path="/messages">
              <MessageLog key={selectedRouteName} />
            </Route>
            <Route path="/call-log">
              <CallLogTable />
            </Route>
            <Route path="/archive">
              <Archive />
            </Route>
            <Route path="/support">
              <Support />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
            )
          </Switch>
        </Box>
        <Dialog
          open={showLogoutConfirm}
          onClose={() => setShowLogoutConfirm(false)}
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <Typography>Are you sure to logout?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowLogoutConfirm(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={logoutUser} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </SchoolContext.Provider>
  )
}

export default observer(Home)
