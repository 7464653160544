import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface SvgIconProps {
  size?: number
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error'
}

function IconLocation(props: SvgIconProps) {
  const { size, color } = props
  return (
    <SvgIcon style={{ fontSize: size || 36 }} color={color ? color : undefined}>
      <svg viewBox="0 0 25 36">
        <path
          fill="none"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={2}
          d="M22.566 11.742c0 7.293-9.945 12.597-9.945 19.227 0-6.63-9.944-11.934-9.944-19.227 0-5.492 4.452-9.944 9.944-9.944 5.493 0 9.945 4.452 9.945 9.944z"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={2}
          d="M15.936 11.742a3.315 3.315 0 11-6.63 0 3.315 3.315 0 016.63 0z"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={2}
          d="M11.514 26.99v0H5.328v0L1.35 34.948v0h22.542v0l-3.978-7.956v0H16.81v0"
        />
      </svg>
    </SvgIcon>
  )
}

export default IconLocation
