import { Grid } from '@material-ui/core'
import React from 'react'
import ContentHeader from '../UI/ContentHeader'
import Contact from './Contact'
import Faq from './Faq'

export default function Support() {
  return (
    <>
      <ContentHeader pageName="Support - We're here to help" hideSelectedRoute />
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Faq />
        </Grid>
        <Grid item xs={6}>
          <Contact />
        </Grid>
      </Grid>
    </>
  )
}
