export type ListAction<T> =
  | {
      type: 'SET_FORM_OPEN' | 'SET_DELETE_CONFIRM_OPEN' | 'SET_LOADING' | 'SET_DELETING'
      payload: boolean
    }
  | { type: 'SET_DATA'; payload: T[] }
  | { type: 'SET_ACTIVE_RECORD'; payload: T | null }

/**
 * Q - the query object data type
 * T - the data type
 */
export type ListState<T> = {
  deleteConfirmOpen: boolean
  formOpen: boolean
  loading: boolean
  deleting: boolean
  data: T[]
  activeRecord: T | null
}

export function createListReducer<T>() {
  const reducer = (prevState: ListState<T>, action: ListAction<T>) => {
    switch (action.type) {
      case 'SET_DATA':
        return { ...prevState, data: action.payload }
      case 'SET_DELETE_CONFIRM_OPEN':
        return { ...prevState, deleteConfirmOpen: action.payload }
      case 'SET_FORM_OPEN':
        return { ...prevState, formOpen: action.payload }
      case 'SET_ACTIVE_RECORD':
        return { ...prevState, activeRecord: action.payload }
      case 'SET_LOADING':
        return { ...prevState, loading: action.payload }
      case 'SET_DELETING':
        return { ...prevState, deleting: action.payload }
      default:
        return prevState
    }
  }

  const initialState = {
    deleting: false,
    loading: false,
    data: [] as T[],
    deleteConfirmOpen: false,
    formOpen: false,
    activeRecord: null,
  } as ListState<T>

  return { reducer, initialState }
}

export type FormAction<T> = { type: 'SET_LOADING'; payload: boolean } | { type: 'SET_RECORD'; payload: T | null }

export type FormState<F> = {
  loading: boolean
  values: F
}

/**
 * Convert record type T to form object type F
 */
export type Converter<T, F> = (record: T | null) => F

export function createFormReducer<T, F>(convert: Converter<T, F>) {
  const state = {
    loading: false,
    values: convert(null),
  } as FormState<F>

  const reducer = (prevState: FormState<F>, action: FormAction<T>) => {
    switch (action.type) {
      case 'SET_LOADING':
        return { ...prevState, loading: action.payload }
      case 'SET_RECORD': {
        return { ...prevState, values: convert(action.payload) }
      }
      default:
        return prevState
    }
  }

  return { reducer, state }
}
