import React from 'react'
import { useObserver } from 'mobx-react-lite'
import { Box, Typography } from '@material-ui/core'
import SelectedRouteView from './SelectedRouteView'

export interface ContentHeaderProps {
  pageName?: string
  hideSelectedRoute?: boolean
  selectedRouteOnly?: boolean
}

/**
 * Display current page name, and selected route
 */
export default function ContentHeader(props: ContentHeaderProps) {
  const { pageName, hideSelectedRoute, selectedRouteOnly = false } = props

  return useObserver(() => (
    <Box>
      {pageName && (
        // TODO: shall we bold all h6?
        <Typography variant="h6" color="primary" style={{ fontWeight: 'bold', marginBottom: 25 }}>
          {pageName}
        </Typography>
      )}
      {!hideSelectedRoute && <SelectedRouteView selectedRouteOnly={selectedRouteOnly} />}
    </Box>
  ))
}
