import { TableCell, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { SchoolContext } from '../../contexts'
import { useFilters } from '../../hooks/useFilters'
import { ChildLogSnapshot } from '../../models/archive/child-log'
import { api } from '../../models/environment'
import { useStores } from '../../models/root-store'
import { ChildLogTableFilters } from '../../services/api.types'
import { formatISODateString, formatPickerDate, getDefaultDateDuration, toLocalTime } from '../../utils/date'
import ColumnFilter, { KeywordFilter } from '../UI/ColumnFilter'
import { DateRangeFilter } from '../UI/ColumnFilter/DateRangeFilter'
import DataTable from '../UI/DataTable'
import StopSelect from '../UI/ColumnFilter/StopSelect'

const childStatusMap = new Map<string, string>([
  ['ABSENT', 'Absent'],
  ['PRESENT', 'Present'],
  ['LATE', 'Late'],
  ['CHECKED_IN', 'Checked In'],
  ['HELD', 'Held'],
  ['OFF', 'Off'],
  ['NOT_ON_SCHEDULE', 'Not on Schedule'],
])

function ChildLogTable() {
  const rootStore = useStores()
  const { filters, filterValues, updateFilters } = useFilters<ChildLogTableFilters>({
    routeId: rootStore.selectedRouteId,
    stopName: null,
    lastName: '',
    registered: null,
    ...getDefaultDateDuration(),
  })
  const { timeZone } = useContext(SchoolContext)

  const { stopName, lastName, registered, dateFrom, dateTo } = filterValues
  return (
    <DataTable
      filters={filters}
      download={api.downloadChildLogs}
      request={api.getChildLogs}
      renderHeadCells={() => (
        <>
          <ColumnFilter labelTx="Stop" filtering={!!stopName}>
            <StopSelect value={stopName} onChange={(stopName: string) => updateFilters({ stopName })} />
          </ColumnFilter>
          <TableCell>Name</TableCell>
          <KeywordFilter
            label="Search Surname"
            columnName="Surname"
            value={lastName}
            onChange={(value: string) => updateFilters({ lastName: value }, true)}
          />
          <TableCell>Status</TableCell>
          <DateRangeFilter
            columnName="Date"
            dateFrom={dateFrom}
            dateFromLabel={'Date From'}
            onDateFromChange={(date: MaterialUiPickersDate) => updateFilters({ dateFrom: formatPickerDate(date) })}
            dateTo={dateTo}
            dateToLabel={'Date To'}
            onDateToChange={(date: MaterialUiPickersDate) => updateFilters({ dateTo: formatPickerDate(date) })}
          />
          <TableCell>Time</TableCell>
          <ColumnFilter labelTx="Additional Pupil (Yes/No)" filtering={!!registered}>
            <Autocomplete
              size="small"
              openOnFocus
              options={['Yes', 'No']}
              renderInput={params => <TextField {...params} label="Select value" variant="outlined" />}
              onChange={(event, value) => updateFilters({ registered: value === null ? null : value === 'No' })}
            />
          </ColumnFilter>
        </>
      )}
      renderRowCells={(record: ChildLogSnapshot) => (
        <>
          <TableCell>{record.stopName}</TableCell>
          <TableCell>{record.firstName}</TableCell>
          <TableCell>{record.lastName}</TableCell>
          <TableCell>{childStatusMap.get(record.status)}</TableCell>
          <TableCell>{formatISODateString(record.dateOccurred)}</TableCell>
          <TableCell>{toLocalTime(record.reportedTime, timeZone)}</TableCell>
          <TableCell>{record.exceptional ? 'Yes' : 'No'}</TableCell>
        </>
      )}
    />
  )
}

export default observer(ChildLogTable)
