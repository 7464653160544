import { Chip, Grid, Paper, Tab, TableCell, Tabs } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useState } from 'react'
import { useFilters } from '../hooks/useFilters'
import { useSharedStyles } from '../hooks/useSharedStyles'
import { api } from '../models/environment'
import { Register } from '../models/register'
import { useStores } from '../models/root-store'
import { RegisterTableFilters } from '../services/api.types'
import ContentHeader from './UI/ContentHeader'
import DataTable from './UI/DataTable'
import { KeywordFilter } from './UI/ColumnFilter'

function Registers() {
  const rootStore = useStores()
  const [activeTab, setActiveTab] = useState('ALL')
  const sharedClasses = useSharedStyles()

  const { filters, filterValues, updateFilters } = useFilters<RegisterTableFilters>({
    routeId: rootStore.selectedRouteId,
    dayOfWeek: null,
    lastName: '',
  })

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setActiveTab(value)
      updateFilters({
        dayOfWeek: value === 'ALL' ? null : value,
      })
    },
    [updateFilters]
  )

  const { lastName } = filterValues

  return (
    <>
      <ContentHeader pageName="Registers" />
      <Paper className={sharedClasses.pagePaper}>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab value="ALL" label="All" />
          <Tab value="MON" label="Moday" />
          <Tab value="TUE" label="Tuesday" />
          <Tab value="WED" label="Wednesday" />
          <Tab value="THU" label="Thursday" />
          <Tab value="FRI" label="Friday" />
        </Tabs>
        <DataTable
          filters={filters}
          request={api.getRegisters}
          download={api.downloadRegisters}
          renderHeadCells={() => (
            <>
              <TableCell>Name</TableCell>
              <KeywordFilter
                label="Search Surname"
                columnName="Surname"
                value={lastName}
                onChange={(value: string) => updateFilters({ lastName: value }, true)}
              />
              <TableCell>Stop</TableCell>
            </>
          )}
          renderRowCells={(record: Register) => (
            <>
              <TableCell>{record.firstName}</TableCell>
              <TableCell>{record.lastName}</TableCell>
              <TableCell>
                <Grid container direction={'column'} spacing={1}>
                  {record.stops
                    .filter(n => !rootStore.selectedRouteId || n.routeId === rootStore.selectedRouteId)
                    .map(stop => (
                      <Grid key={stop.stopId} item>
                        <Chip label={`${stop.routeName}-${stop.routeType}-${stop.stopName}`} />
                      </Grid>
                    ))}
                </Grid>
              </TableCell>
            </>
          )}
        />
      </Paper>
    </>
  )
}

export default observer(Registers)
