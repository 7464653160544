import { TableCell, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useFilters } from '../../../hooks/useFilters'
import { api } from '../../../models/environment'
import { useStores } from '../../../models/root-store'
import { UserSnapshot } from '../../../models/user'
import { ChaperoneDriverUserTableFilters } from '../../../services/api.types'
import { getJobTypeName } from '../../../utils/format'
import ColumnFilter, { KeywordFilter } from '../../UI/ColumnFilter'
import DataTable from '../../UI/DataTable'
import UserForm from './UserForm'

function ChaperoneDriverUserTable() {
  const rootStore = useStores()
  const { filterValues, filters, updateFilters } = useFilters<ChaperoneDriverUserTableFilters>({
    lastName: '',
    roles: ['CHAPERONE', 'DRIVER'],
    campus: '',
    jobType: null,
    company: '',
  })

  const { lastName, roles, campus, company, jobType } = filterValues
  return (
    <DataTable
      enableCreate={rootStore.isAdmin}
      idProperty="userId"
      filters={filters}
      request={api.getChaperoneDriverUsers}
      download={api.downloadChaperoneDriverUsers}
      renderHeadCells={() => (
        <>
          <ColumnFilter labelTx="Driver/Monitor" filtering={roles.length === 1}>
            <Autocomplete
              size="small"
              openOnFocus
              options={['DRIVER', 'CHAPERONE']}
              getOptionLabel={(option: string) => (option === 'DRIVER' ? 'Driver' : 'Monitor')}
              renderInput={params => <TextField {...params} label="Select Driver/Monitor" variant="outlined" />}
              value={roles.length === 2 ? null : roles[0]}
              onChange={(event, value) =>
                updateFilters({
                  roles: value === null ? ['CHAPERONE', 'DRIVER'] : [value],
                })
              }
            />
          </ColumnFilter>
          <TableCell>Name</TableCell>
          <KeywordFilter
            label="Search Surname"
            columnName="Surname"
            value={lastName}
            onChange={(value: string) => updateFilters({ lastName: value }, true)}
          />
          <TableCell>Email</TableCell>
          <TableCell>Contact</TableCell>
          <KeywordFilter
            label="Search Campus"
            columnName="Campus"
            value={campus}
            onChange={(value: string) => updateFilters({ campus: value }, true)}
          />
          <KeywordFilter
            label="Search Company"
            columnName="Company"
            value={company}
            onChange={(value: string) => updateFilters({ company: value }, true)}
          />
          <ColumnFilter labelTx="Job Type" filtering={!!jobType}>
            <Autocomplete
              size="small"
              openOnFocus
              options={['ON_ROTATION', 'REGULAR']}
              getOptionLabel={getJobTypeName}
              renderInput={params => <TextField {...params} label="Select Job Type" variant="outlined" />}
              value={jobType}
              onChange={(event, value) =>
                updateFilters({
                  jobType: value,
                })
              }
            />
          </ColumnFilter>
        </>
      )}
      renderRowCells={(record: UserSnapshot) => {
        const { role, firstName, lastName, email, phoneNo, company, campus, jobType } = record
        return (
          <>
            <TableCell>{role === 'CHAPERONE' ? 'Monitor' : 'Driver'}</TableCell>
            <TableCell>{firstName}</TableCell>
            <TableCell>{lastName}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{phoneNo}</TableCell>
            <TableCell>{campus}</TableCell>
            <TableCell>{company}</TableCell>
            <TableCell>{getJobTypeName(jobType)}</TableCell>
          </>
        )
      }}
      formComponent={UserForm}
      formProps={{ userType: 'chaperone' }}
      onDeleteContext={{
        confirm: {
          title: 'Delete User',
          messages: ['Are you sure you want to delete this user?'],
        },
        request: api.deleteUser,
        successMessage: 'User has been deleted successfully',
      }}
    />
  )
}

export default observer(ChaperoneDriverUserTable)
