import { types, Instance, SnapshotOut } from 'mobx-state-tree'

export enum MessageVariant {
  default = 'default',
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

export const SnackbarMessageModel = types.model('SnackbarMessage').props({
  key: types.identifierNumber,
  // TODO: use translation key instead of plain text
  // TODO: need to support React.Node
  tx: types.string,

  // https://iamhosseindhv.com/notistack/demos#variants
  variant: types.enumeration<MessageVariant>(Object.values(MessageVariant)),
})

export interface SnackbarMessage extends Instance<typeof SnackbarMessageModel> {}
export interface SnackbarMessageSnapshot extends SnapshotOut<typeof SnackbarMessageModel> {}
