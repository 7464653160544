import { Grid, makeStyles } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { omit } from 'lodash'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { api } from '../../models/environment'
import { BIZZYBUS_HOME, PASSWORD_REGEX } from '../../utils/constant'
import AlertDialog from '../UI/AlertDialog'
import SubmitButton from '../UI/SubmitButton'
import Layout from './Layout'

const useStyles = makeStyles({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
})

function PasswordReset() {
  const classes = useStyles()
  const params = useParams<{ verificationCode: string }>()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)

  const verificationCode = params.verificationCode

  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false)
    window.location.href = BIZZYBUS_HOME
  }

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false)
  }

  return (
    <Layout subtitle="Password Reset">
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .matches(PASSWORD_REGEX, 'At least 8 characters including letter and number.')
            .required('At least 8 characters including letter and number.'),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Password does not match.')
            .required('Password does not match.'),
        })}
        onSubmit={async values => {
          omit(values, 'confirmPassword')
          const response = await api.resetPassword(verificationCode, values.password)
          if (response.success) setSuccessDialogOpen(true)
          else if (response.error.response?.status === 404) setErrorDialogOpen(true)
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form className={classes.form}>
            <Grid container spacing={2} direction="column" alignItems="stretch">
              <Grid item>
                <Field component={TextField} type="password" name="password" label="Enter new password" fullWidth />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  type="password"
                  name="confirmPassword"
                  label="Confirm new password"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <SubmitButton onClick={submitForm} submitting={isSubmitting} text="Submit" fullWidth />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <AlertDialog
        open={successDialogOpen}
        handleClose={handleCloseSuccessDialog}
        title="Congratulations!"
        content="You have successfully reset your password."
      />

      <AlertDialog
        open={errorDialogOpen}
        handleClose={handleCloseErrorDialog}
        title="Invalid Request!"
        content={['This link is no longer valid.', 'Please contract your school if you need assistance.']}
      />
    </Layout>
  )
}

export default PasswordReset
