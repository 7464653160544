import { Grid, makeStyles, TextField } from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { DatePicker } from '@material-ui/pickers'
import React from 'react'
import ColumnFilter from '.'
import { DATE_FORMAT } from '../../../utils/constant'

type DateIOType = string | number | Date | null

interface DateRangeFilterProps {
  columnName: string
  dateFrom: DateIOType
  dateFromLabel?: string
  onDateFromChange(date: MaterialUiPickersDate): void
  dateTo: DateIOType
  dateToLabel?: string
  onDateToChange(date: MaterialUiPickersDate): void
}

const useStyles = makeStyles({
  dateField: {
    width: 120,
    '& + &': {
      marginLeft: 10,
    },
  },
})

export function DateRangeFilter(props: DateRangeFilterProps) {
  const {
    columnName,
    dateFrom,
    dateFromLabel = 'Date From',
    onDateFromChange,
    dateTo,
    dateToLabel = 'Date To',
    onDateToChange,
  } = props

  const classes = useStyles()

  return (
    // filtering: dateFrom & dateTo both are not null
    <ColumnFilter labelTx={columnName} filtering={!!dateFrom && !!dateTo}>
      <Grid container spacing={2}>
        <Grid item>
          <DatePicker
            value={dateFrom}
            format={DATE_FORMAT}
            onChange={onDateFromChange}
            TextFieldComponent={(props: any) => (
              <TextField
                {...props}
                variant="outlined"
                label={dateFromLabel}
                size="small"
                className={classes.dateField}
              />
            )}
          />
        </Grid>
        <Grid item>
          <DatePicker
            value={dateTo}
            format={DATE_FORMAT}
            onChange={onDateToChange}
            TextFieldComponent={(props: any) => (
              <TextField {...props} variant="outlined" label={dateToLabel} size="small" className={classes.dateField} />
            )}
          />
        </Grid>
      </Grid>
    </ColumnFilter>
  )
}
