import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface SvgIconProps {
  size?: number
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error'
}

function IconAvatar(props: SvgIconProps) {
  const { size, color } = props
  return (
    <SvgIcon style={{ fontSize: size || 36 }} color={color ? color : undefined}>
      <svg viewBox="0 0 28 32">
        <path
          fill="none"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={2}
          d="M26.277 31.662v0-3.627c0-5.92-5.666-9.313-12.658-9.313-6.993 0-12.654 3.393-12.654 9.313v3.627"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeMiterlimit={20}
          strokeWidth={2}
          d="M6.402 8.908a7.219 7.219 0 007.218 7.218 7.219 7.219 0 100-14.437 7.219 7.219 0 00-7.218 7.22z"
        />
      </svg>
    </SvgIcon>
  )
}

export default IconAvatar
