import { Button, CircularProgress } from '@material-ui/core'
import React from 'react'

interface SubmitButtonProps {
  color?: 'inherit' | 'primary' | 'secondary' | 'default'
  onClick(): Promise<void>
  submitting: boolean
  text: string
  fullWidth?: boolean
}

export default function SubmitButton(props: SubmitButtonProps) {
  const { color = 'primary', onClick, submitting, text, fullWidth = false } = props
  return (
    <Button
      fullWidth={fullWidth}
      variant="contained"
      color={color}
      type="submit"
      onClick={onClick}
      disabled={submitting}
      startIcon={submitting ? <CircularProgress size={14} /> : null}
    >
      {text}
    </Button>
  )
}
