import { RootStoreModel } from './root-store'
import { Environment } from '../environment'
// @ts-ignore
import makeInspectable from 'mobx-devtools-mst'

export function createEnvironment() {
  const env = new Environment()
  env.setup()
  return env
}

export function setupRootStore() {
  const env = createEnvironment()
  const schoolCode = localStorage.getItem('schoolCode')
  const token = localStorage.getItem('token')
  const rootStore = RootStoreModel.create({ schoolCode, token }, env)
  env.api.setStore(rootStore)
  makeInspectable(rootStore)
  return rootStore
}
