import moment from 'moment'
import { ChildScheduleSnapshot } from '../models/archive/child-schedule'
import { PersonSnapshot } from '../models/person'
import { RouteOverview, RouteOverviewSnapshot } from '../models/route'
import { StopOverview } from '../models/stop'

export const personSort = (e1: PersonSnapshot, e2: PersonSnapshot) => {
  if (e1.lastName.toUpperCase() > e2.lastName.toUpperCase()) {
    return 1
  } else if (e1.lastName.toUpperCase() < e2.lastName.toUpperCase()) {
    return -1
  } else {
    return e1.firstName.toUpperCase() > e2.firstName.toUpperCase() ? 1 : -1
  }
}

export const stopOverViewSort = (e1: StopOverview, e2: StopOverview) => {
  if (e1.routeName.toUpperCase() > e2.routeName.toUpperCase()) {
    return 1
  } else if (e1.routeName.toUpperCase() < e2.routeName.toUpperCase()) {
    return -1
  } else {
    return e1.stopId > e2.stopId ? 1 : -1
  }
}

export const childScheduleSort = (e1: ChildScheduleSnapshot, e2: ChildScheduleSnapshot) => {
  if (moment(e1.scheduleDate, 'YYYY-MM-DD').isBefore(moment(e2.scheduleDate, 'YYYY-MM-DD'))) {
    return 1
  } else if (moment(e1.scheduleDate, 'YYYY-MM-DD').isAfter(moment(e2.scheduleDate, 'YYYY-MM-DD'))) {
    return -1
  } else {
    return personSort(e1, e2)
  }
}

export const stringSort = (e1: string, e2: string) => {
  if (e1.toUpperCase() > e2.toUpperCase()) {
    return 1
  } else if (e1.toUpperCase() < e2.toUpperCase()) {
    return -1
  } else {
    return 0
  }
}

export const weekSort = (e1: string, e2: string) => {
  const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
  return days.indexOf(e1) - days.indexOf(e2)
}

export const routeSort = (r1: RouteOverview | RouteOverviewSnapshot, r2: RouteOverview | RouteOverviewSnapshot) => {
  if (r1.routeName === r2.routeName) {
    return -1 * r1.routeType.localeCompare(r2.routeType)
  } else return r1.routeName.localeCompare(r2.routeName)
}
