import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useStores } from '../../models/root-store'
import { SchoolSnapshot } from '../../models/school'

interface SchoolCardProps extends SchoolSnapshot {}

const useStyles = makeStyles({
  root: {
    padding: 25,
    width: 235,
    height: 235,
    borderRadius: 5,
    background: 'white',
  },
  schoolImage: {
    borderRadius: '50%',
    width: 80,
  },
})

export default function SchoolCard(props: SchoolCardProps) {
  const { schoolImage, name, code } = props
  const rootStore = useStores()
  const classes = useStyles()
  const history = useHistory()

  const onClick = useCallback(() => {
    rootStore.setSchoolCode(code)
    rootStore.setSelectedRouteId()
    history.push('/dashboard')
  }, [code, history, rootStore])

  return (
    <Grid container item direction="column" alignItems="center" justify="space-between" xs className={classes.root}>
      <Grid item>
        <img className={classes.schoolImage} alt="school-logo" src={schoolImage} />
      </Grid>
      <Grid item>
        <Typography>{name}</Typography>
      </Grid>
      <Grid item style={{ alignSelf: 'stretch' }}>
        <Button variant="contained" color="primary" onClick={onClick} fullWidth>
          Select
        </Button>
      </Grid>
    </Grid>
  )
}
