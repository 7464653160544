import { TableCell, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useFilters } from '../../../hooks/useFilters'
import { api } from '../../../models/environment'
import { useStores } from '../../../models/root-store'
import { UserSnapshot } from '../../../models/user'
import { AdminUserTableFilters } from '../../../services/api.types'
import { getAdminRoleName } from '../../../utils/format'
import ColumnFilter, { KeywordFilter } from '../../UI/ColumnFilter'
import DataTable from '../../UI/DataTable'
import UserForm from './UserForm'

function AdminUserTable() {
  const rootStore = useStores()
  const { filterValues, filters, updateFilters } = useFilters<AdminUserTableFilters>({
    lastName: '',
    adminRole: null,
    campus: '',
  })

  const { lastName, adminRole, campus } = filterValues
  return (
    <DataTable
      enableCreate={rootStore.isAdmin}
      idProperty="userId"
      filters={filters}
      request={api.getAdminUsers}
      download={api.downloadAdminUsers}
      renderHeadCells={() => (
        <>
          <ColumnFilter labelTx="User Role" filtering={!!adminRole}>
            <Autocomplete
              size="small"
              openOnFocus
              options={['SCHOOL_ADMINISTRATOR', 'SCHOOL_SECRETARY']}
              getOptionLabel={(option: 'SCHOOL_ADMINISTRATOR' | 'SCHOOL_SECRETARY') => getAdminRoleName(option)}
              renderInput={params => <TextField {...params} label="Select User Role" variant="outlined" />}
              value={adminRole}
              onChange={(event, value) =>
                updateFilters({
                  adminRole: value,
                })
              }
            />
          </ColumnFilter>
          <TableCell>Name</TableCell>
          <KeywordFilter
            label="Search Surname"
            columnName="Surname"
            value={lastName}
            onChange={(value: string) => updateFilters({ lastName: value }, true)}
          />
          <TableCell>Email</TableCell>
          <TableCell>Contact</TableCell>
          <KeywordFilter
            label="Search Campus"
            columnName="Campus"
            value={campus}
            onChange={(value: string) => updateFilters({ campus: value }, true)}
          />
        </>
      )}
      renderRowCells={(record: UserSnapshot) => {
        const { adminRole, firstName, lastName, email, phoneNo, campus } = record
        return (
          <>
            <TableCell>{getAdminRoleName(adminRole)}</TableCell>
            <TableCell>{firstName}</TableCell>
            <TableCell>{lastName}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{phoneNo}</TableCell>
            <TableCell>{campus}</TableCell>
          </>
        )
      }}
      formComponent={UserForm}
      formProps={{ userType: 'admin' }}
      onDeleteContext={{
        confirm: {
          title: 'Delete User',
          messages: ['Are you sure you want to delete this user?'],
        },
        request: api.deleteUser,
        successMessage: 'User has been deleted successfully',
      }}
    />
  )
}

export default observer(AdminUserTable)
