import { Card, CardContent, Chip, Popover, Typography } from '@material-ui/core'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import React from 'react'
import { PersonSnapshot } from '../../models/person'
import { UserSnapshot } from '../../models/user'
import { getUsername } from '../../utils/format'

type SchoolUserContact = Pick<UserSnapshot, 'email' | 'phoneNo' | 'parentTitle'> & PersonSnapshot

interface ContactTagProps {
  contact: SchoolUserContact
  child?: { firstName: string }
}

export default function ContactTag(props: ContactTagProps) {
  const {
    child,
    contact: { phoneNo, email, parentTitle },
  } = props
  const name = getUsername(props.contact)

  const prefix = `${child?.firstName}${child?.firstName.endsWith('s') ? "'" : "'s "}`

  return (
    <PopupState variant="popover">
      {popupState => (
        <>
          <Chip label={`${name} ( ${phoneNo} )`} {...bindTrigger(popupState)} />
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <Card>
              <CardContent>
                {parentTitle && prefix && <Typography>{`${prefix} ${parentTitle}`}</Typography>}
                <Typography>{email}</Typography>
              </CardContent>
            </Card>
          </Popover>
        </>
      )}
    </PopupState>
  )
}
