import { Button, Grid, Link, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useStores } from '../../models/root-store'
import { observer } from 'mobx-react-lite'
import { MessageVariant } from '../../models/snackbar-message'

const useStyles = makeStyles({
  root: {
    height: 350,
    padding: 50,
  },
})

function Contact() {
  const classes = useStyles()
  const rootStore = useStores()

  return (
    <Grid container direction="column" alignItems="center" justify="space-between" className={classes.root}>
      <Grid item>
        <Typography color="primary" variant="subtitle1">
          Contact us
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" align="center">
          {`If you can't find an answer to your question simply click the button below or send an email using mail service to:`}
        </Typography>
      </Grid>
      <Grid item>
        <CopyToClipboard
          text={`support@buswise.org.uk`}
          onCopy={() => rootStore.enqueueSnackbar('Copied to clipboard!', MessageVariant.success)}
        >
          <Button variant="contained" color="primary">
            Copy to clipboard
          </Button>
        </CopyToClipboard>
      </Grid>
      <Grid item>
        <Link href="mailto:support@buswise.org.uk" color="primary" underline="none">
          support@buswise.org.uk
        </Link>
      </Grid>
      <Grid item>
        <Typography variant="body2">{`We aim to reply to all emails in 24 hours`}</Typography>
      </Grid>
    </Grid>
  )
}

export default observer(Contact)
