import { CircularProgress, Grid, IconButton } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import PostAddIcon from '@material-ui/icons/PostAdd'
import React, { useCallback, useState } from 'react'
import { useSharedStyles } from '../../hooks/useSharedStyles'
import { useStores } from '../../models/root-store'
import { MessageVariant } from '../../models/snackbar-message'

interface TableToolbarProps {
  downloadData: () => Promise<void>
  downloadDisabled: boolean
  onCreateButtonClicked?: () => void
}

export default function TableToolbar(props: TableToolbarProps) {
  const rootStore = useStores()
  const sharedClasses = useSharedStyles()
  const [downloading, setDownloading] = useState(false)
  const { downloadDisabled, downloadData, onCreateButtonClicked } = props

  const download = useCallback(() => {
    setDownloading(true)
    downloadData()
      .catch(() => {
        rootStore.enqueueSnackbar('Download failed, please try again later.', MessageVariant.error)
      })
      .finally(() => {
        setDownloading(false)
      })
  }, [downloadData, rootStore])

  return (
    <Grid container alignItems="center" className={sharedClasses.tableToolbar}>
      <Grid container item justify="flex-end" xs>
        {onCreateButtonClicked && (
          <Grid item className={sharedClasses.tableToolbarButton}>
            <IconButton color="primary" title="Add" onClick={onCreateButtonClicked}>
              <PostAddIcon fontSize="large" />
            </IconButton>
          </Grid>
        )}
        <Grid item className={sharedClasses.tableToolbarButton}>
          <IconButton color="primary" onClick={download} disabled={downloadDisabled || downloading} title="Export">
            <CloudDownloadIcon fontSize="large" />
          </IconButton>
          {downloading && <CircularProgress size={50} className={sharedClasses.tableDownloadButtonProgress} />}
        </Grid>
      </Grid>
    </Grid>
  )
}
