import { UserSnapshot } from '../../../models/user'
import { UserRequestModel } from '../../../services/api.types'
import { Converter, createFormReducer } from '../../../utils/reducer'

const convert: Converter<UserSnapshot, UserRequestModel> = (record: UserSnapshot | null) => {
  if (!record) {
    return {
      firstName: '',
      lastName: '',
      phoneNo: '',
      email: '',
      role: '',
      parentTitle: '',
      schools: [],
      company: '',
      locked: false,
      campus: '',
      jobType: '',
      adminRole: null,
      childIds: [],
    }
  } else {
    const {
      firstName,
      lastName,
      phoneNo,
      email,
      role,
      parentTitle,
      company,
      campus,
      jobType,
      adminRole,
      childIds,
    } = record
    return {
      firstName,
      lastName,
      phoneNo: phoneNo || '',
      email,
      role,
      parentTitle: parentTitle || '',
      schools: [],
      locked: false,
      company: company || '',
      campus: campus || '',
      jobType: jobType || '',
      adminRole,
      childIds,
    }
  }
}

const formReducer = createFormReducer<UserSnapshot, UserRequestModel>(convert)

export { formReducer }
