import { ChildSnapshot } from '../../../models/child'
import { ChildRequestModel } from '../../../services/api.types'
import { Converter, createFormReducer } from '../../../utils/reducer'

interface ChildFormValues extends ChildRequestModel {
  routeName: string
}

const convert: Converter<ChildSnapshot, ChildFormValues> = (record: ChildSnapshot | null) => {
  if (!record) {
    return {
      childId: 0,
      registered: true,
      firstName: '',
      lastName: '',
      medical: '',
      routeName: '',
      daysOfWeek: ['MON', 'TUE', 'WED', 'THU', 'FRI'],
      stopIds: [null, null],
      parentAccounts: [''],
    } as ChildFormValues
  } else {
    const { medical, daysOfWeek = [], parents, stops } = record
    const morningStop = stops.find(e => e.routeType === 'Morning')
    const afternoonStop = stops.find(e => e.routeType === 'Afternoon')
    const routeName = morningStop?.routeName || afternoonStop?.routeName || ''

    return {
      ...record,
      medical: medical || '',
      daysOfWeek,
      routeName,
      stopIds: [morningStop?.stopId || null, afternoonStop?.stopId || null],
      parentAccounts: parents.length === 0 ? [''] : parents.map(n => n.email),
    } as ChildFormValues
  }
}

const formReducer = createFormReducer<ChildSnapshot, ChildFormValues>(convert)

export { formReducer }
