import { Collapse, Grid, makeStyles, Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import React, { useState } from 'react'
import { FaqData } from './questions'

const useStyles = makeStyles({
  root: {
    marginBottom: 10,
  },
  title: {
    cursor: 'pointer',
    // '&:hover': {
    //   background: colors.grey[400],
    // },
  },
  expandIcon: {
    width: 25,
    display: 'flex',
  },
  description: {
    paddingLeft: 25,
  },
})

export default function Accordion({ title, description }: FaqData) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  return (
    <div className={classes.root}>
      <Grid container className={classes.title} onClick={() => setExpanded(!expanded)} alignItems="center">
        <Grid item className={classes.expandIcon}>
          {expanded ? <KeyboardArrowDownIcon color="primary" /> : <KeyboardArrowRightIcon color="primary" />}
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">{title}</Typography>
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.description}>
          <Typography variant="body2">{description}</Typography>
        </div>
      </Collapse>
    </div>
  )
}
