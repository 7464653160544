import {
  Badge,
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  OutlinedInput,
  Typography,
  colors,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { observer, useObserver } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { useStores } from '../models/root-store'
import { RouteBase } from '../models/route/route-base'

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.grey[100],
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 240,
    paddingTop: 25,
  },
  routePair: {
    display: 'flex',
    marginBottom: 10,
  },
  routeColor: {
    alignSelf: 'center',
    width: 25,
    height: 45,
    borderRadius: 25,
    marginRight: 5,
  },
  route: {
    cursor: 'pointer',
  },
  search: {
    height: 36,
    backgroundColor: 'white',
    borderRadius: 5,
  },
  routeLabel: {
    marginTop: 0,
    marginBottom: 0,
  },
  routeName: {
    paddingRight: 5,
  },
  allRoutesButton: {
    margin: '25px 0 40px 0',
  },
})

function RoutePairView({ routes }: { routes: RouteBase[] }) {
  const classes = useStyles()
  const rootStore = useStores()

  const backgroundColor = (routes[0] || routes[1]).color

  return useObserver(() => (
    <Box className={classes.routePair}>
      <Box className={classes.routeColor} style={{ backgroundColor }} />
      <List dense>
        {routes.map(
          route =>
            route && (
              <ListItem
                key={route.routeType}
                button
                dense
                className={classes.route}
                selected={rootStore.selectedRouteId === route.routeId}
              >
                <ListItemText
                  primary={
                    <Badge
                      color="secondary"
                      variant="dot"
                      invisible={
                        !rootStore.flags.some(flag => flag.routeId === route.routeId) || !rootStore.showFlaggedRouteDot
                      }
                    >
                      <Typography noWrap className={classes.routeName}>
                        {route.selectOptionLabel}
                      </Typography>
                    </Badge>
                  }
                  className={classes.routeLabel}
                  onClick={() => rootStore.setSelectedRouteId(route.routeId)}
                />
              </ListItem>
            )
        )}
      </List>
    </Box>
  ))
}

function RouteSelector() {
  const classes = useStyles()
  const rootStore = useStores()
  const { setSelectedRouteId } = rootStore
  const [filter, setFilter] = useState('')

  useEffect(() => {
    rootStore.getAllRoutes()
  }, [rootStore])

  const routePairs = useMemo(() => {
    return new Map([...rootStore.routePairs.entries()].sort((p1, p2) => p1[0].localeCompare(p2[0])))
  }, [rootStore.routePairs])

  const onSearchChange = useCallback(e => setFilter(e.target.value.toLowerCase()), [])

  const resetSelector = useCallback(() => {
    // unset route id
    setSelectedRouteId()
    setFilter('')
  }, [setSelectedRouteId])

  return (
    <Container className={classes.root}>
      <OutlinedInput
        fullWidth
        startAdornment={<SearchIcon />}
        placeholder="Search"
        className={classes.search}
        value={filter}
        onChange={onSearchChange}
      />
      <Button variant="contained" color="primary" fullWidth className={classes.allRoutesButton} onClick={resetSelector}>
        <Typography variant="button">All routes</Typography>
      </Button>
      {[...routePairs.values()].map((routes, idx) => {
        const filtered = routes.filter(({ selectOptionLabel }) => selectOptionLabel.toLowerCase().includes(filter))
        if (filtered.length === 0) return null
        else return <RoutePairView key={idx} routes={filtered} />
      })}
    </Container>
  )
}

export default observer(RouteSelector)
