import { TableCell, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useFilters } from '../../hooks/useFilters'
import { ChildSchedule } from '../../models/archive/child-schedule'
import { api } from '../../models/environment'
import { useStores } from '../../models/root-store'
import { ChildScheduleTableFilters } from '../../services/api.types'
import { formatISODateString, formatJsonDate, formatPickerDate, getDefaultDateDuration } from '../../utils/date'
import ColumnFilter from '../UI/ColumnFilter'
import { DateRangeFilter } from '../UI/ColumnFilter/DateRangeFilter'
import DataTable from '../UI/DataTable'

function ChildScheduleTable() {
  const rootStore = useStores()

  const { dateFrom: initCreationDateFrom, dateTo: initCreationDateTo } = getDefaultDateDuration()
  // const { dateFrom: initScheduleDateFrom, dateTo: initScheduleDateTo } = getDefaultDateDuration()
  const { filters, updateFilters } = useFilters<ChildScheduleTableFilters>({
    routeId: rootStore.selectedRouteId,
    onRoute: null,
    createdDateFrom: initCreationDateFrom,
    createdDateTo: initCreationDateTo,
    scheduleDateFrom: null,
    scheduleDateTo: null,
  })

  const { onRoute, createdDateFrom, createdDateTo, scheduleDateFrom, scheduleDateTo } = filters

  return (
    <DataTable
      filters={filters}
      request={api.getChildSchedules}
      download={api.downloadChildSchedules}
      renderHeadCells={() => (
        <>
          <ColumnFilter labelTx="Pupil coming / not coming" filtering={onRoute !== null}>
            <Autocomplete
              size="small"
              openOnFocus
              options={['Coming', 'Not Coming']}
              renderInput={params => <TextField {...params} label="Select value" variant="outlined" />}
              value={onRoute === null ? null : onRoute ? 'Coming' : 'Not Coming'}
              onChange={(event, value) => updateFilters({ onRoute: value === null ? null : value === 'Coming' })}
            />
          </ColumnFilter>
          <TableCell>Name</TableCell>
          <TableCell>Surname</TableCell>
          <DateRangeFilter
            columnName="Date attending / not attending"
            dateFrom={scheduleDateFrom}
            dateFromLabel={'Date From'}
            onDateFromChange={(date: MaterialUiPickersDate) =>
              updateFilters({ scheduleDateFrom: formatPickerDate(date) })
            }
            dateTo={scheduleDateTo}
            dateToLabel={'Date To'}
            onDateToChange={(date: MaterialUiPickersDate) => updateFilters({ scheduleDateTo: formatPickerDate(date) })}
          />

          <DateRangeFilter
            columnName="Notification date"
            dateFrom={createdDateFrom}
            dateFromLabel={'Date From'}
            onDateFromChange={(date: MaterialUiPickersDate) =>
              updateFilters({ createdDateFrom: formatPickerDate(date) })
            }
            dateTo={createdDateTo}
            dateToLabel={'Date To'}
            onDateToChange={(date: MaterialUiPickersDate) => updateFilters({ createdDateTo: formatPickerDate(date) })}
          />
        </>
      )}
      renderRowCells={(childSchedule: ChildSchedule) => {
        return (
          <>
            <TableCell>{childSchedule.onRoute ? 'Coming' : 'Not coming'}</TableCell>
            <TableCell>{childSchedule.firstName}</TableCell>
            <TableCell>{childSchedule.lastName}</TableCell>
            <TableCell>{formatISODateString(childSchedule.scheduleDate)}</TableCell>
            <TableCell>{formatJsonDate(childSchedule.createdDate)}</TableCell>
          </>
        )
      }}
    />
  )
}

export default observer(ChildScheduleTable)
