import { Paper, TableCell, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz/esm'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { SchoolContext } from '../contexts'
import { useFilters } from '../hooks/useFilters'
import { useSharedStyles } from '../hooks/useSharedStyles'
import { CallLogSnapshot } from '../models/call-log'
import { api } from '../models/environment'
import { useStores } from '../models/root-store'
import { CallLogTableFilters } from '../services/api.types'
import { DATE_FORMAT } from '../utils/constant'
import { formatPickerDate, getDefaultDateDuration } from '../utils/date'
import ColumnFilter, { KeywordFilter } from './UI/ColumnFilter'
import { DateRangeFilter } from './UI/ColumnFilter/DateRangeFilter'
import ContactTag from './UI/ContactTag'
import ContentHeader from './UI/ContentHeader'
import DataTable from './UI/DataTable'

function CallLogTable() {
  const rootStore = useStores()
  const { filters, filterValues, updateFilters } = useFilters<CallLogTableFilters>({
    routeId: rootStore.selectedRouteId,
    callerLastName: null,
    roleNames: null,
    ...getDefaultDateDuration(),
  })

  const { timeZone } = useContext(SchoolContext)
  const sharedClasses = useSharedStyles()

  const { callerLastName, roleNames, dateFrom, dateTo } = filterValues
  return (
    <>
      <ContentHeader pageName="Call Log" />
      <Paper className={sharedClasses.pagePaper}>
        <DataTable
          filters={filters}
          request={api.getCallLogs}
          download={api.downloadCallLogs}
          renderHeadCells={() => (
            <>
              <TableCell>Caller Name</TableCell>
              <KeywordFilter
                columnName="Caller Surname"
                label="Search Caller Surname"
                value={callerLastName || ''}
                onChange={(callerLastName: string) => updateFilters({ callerLastName }, true)}
              />
              <TableCell>Call Recipient</TableCell>
              <DateRangeFilter
                columnName="Date"
                dateFrom={dateFrom}
                dateFromLabel="Date From"
                onDateFromChange={(date: MaterialUiPickersDate) => updateFilters({ dateFrom: formatPickerDate(date) })}
                dateTo={dateTo}
                dateToLabel="Date To"
                onDateToChange={(date: MaterialUiPickersDate) => updateFilters({ dateTo: formatPickerDate(date) })}
              />
              <TableCell>Time</TableCell>
              <ColumnFilter labelTx="Bus/Parent" filtering={!!roleNames?.length}>
                <Autocomplete
                  size="small"
                  openOnFocus
                  options={['Bus', 'Parent']}
                  renderInput={params => <TextField {...params} label="Select Bus/Parent" variant="outlined" />}
                  value={
                    !roleNames?.length ? null : roleNames.length === 1 && roleNames[0] === 'PARENT' ? 'Parent' : 'Bus'
                  }
                  onChange={(event, value) =>
                    updateFilters({
                      roleNames: value === null ? null : value === 'Parent' ? ['PARENT'] : ['CHAPERONE', 'DRIVER'],
                    })
                  }
                />
              </ColumnFilter>
            </>
          )}
          renderRowCells={(record: CallLogSnapshot) => {
            const {
              caller: { firstName, lastName, role },
              callee,
              child,
              occurredAt,
            } = record
            const dateOccurred = utcToZonedTime(occurredAt, timeZone)
            return (
              <>
                <TableCell>{firstName}</TableCell>
                <TableCell>{lastName}</TableCell>
                <TableCell>{<ContactTag contact={callee} child={child} />}</TableCell>
                <TableCell>{format(dateOccurred, DATE_FORMAT)}</TableCell>
                <TableCell>{format(dateOccurred, 'HH:mm')}</TableCell>
                <TableCell>{role === 'PARENT' ? 'Parent' : 'Bus'}</TableCell>
              </>
            )
          }}
        />
      </Paper>
    </>
  )
}

export default observer(CallLogTable)
