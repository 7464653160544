import { TableCell } from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useFilters } from '../../hooks/useFilters'
import { StopLogSnapshot } from '../../models/archive'
import { api } from '../../models/environment'
import { useStores } from '../../models/root-store'
import { StopLogTableFilters } from '../../services/api.types'
import { formatISODateString, formatPickerDate, formatISOTimeString, getDefaultDateDuration } from '../../utils/date'
import ColumnFilter from '../UI/ColumnFilter'
import { DateRangeFilter } from '../UI/ColumnFilter/DateRangeFilter'
import DataTable from '../UI/DataTable'
import StopSelect from '../UI/ColumnFilter/StopSelect'

function StopLogTable() {
  const rootStore = useStores()
  const { filters, filterValues, updateFilters } = useFilters<StopLogTableFilters>({
    stopName: null,
    routeId: rootStore.selectedRouteId,
    ...getDefaultDateDuration(),
  })

  const { stopName, dateFrom, dateTo } = filterValues

  return (
    <DataTable
      filters={filters}
      download={api.downloadStopLogs}
      request={api.getStopLogs}
      renderHeadCells={() => (
        <>
          <ColumnFilter labelTx="Stop" filtering={!!stopName}>
            <StopSelect value={stopName} onChange={(stopName: string) => updateFilters({ stopName })} />
          </ColumnFilter>
          <TableCell>Stop Time</TableCell>
          <TableCell>Arrival Time</TableCell>
          <TableCell>Time Diff</TableCell>
          <DateRangeFilter
            columnName="Date"
            dateFrom={dateFrom}
            dateFromLabel={'Date From'}
            onDateFromChange={(date: MaterialUiPickersDate) => updateFilters({ dateFrom: formatPickerDate(date) })}
            dateTo={dateTo}
            dateToLabel={'Date To'}
            onDateToChange={(date: MaterialUiPickersDate) => updateFilters({ dateTo: formatPickerDate(date) })}
          />
        </>
      )}
      keyExtractor={(record: StopLogSnapshot) => `${record.stopId}-${record.dateOccurred}`}
      renderRowCells={(record: StopLogSnapshot) => {
        const { dateOccurred, stopName, stopTime, timeDiff, arrivalTime } = record
        return (
          <>
            <TableCell>{stopName}</TableCell>
            <TableCell>{formatISOTimeString(stopTime)}</TableCell>
            <TableCell>{formatISOTimeString(arrivalTime)}</TableCell>
            <TableCell>{timeDiff}</TableCell>
            <TableCell>{formatISODateString(dateOccurred)}</TableCell>
          </>
        )
      }}
    />
  )
}

export default observer(StopLogTable)
