import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface SvgIconProps {
  size?: number
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error'
}

function IconMessage(props: SvgIconProps) {
  const { size, color } = props
  return (
    <SvgIcon style={{ fontSize: size || 36 }} color={color ? color : undefined}>
      <svg viewBox="0 0 35 25">
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={2}
          d="M31.481 24.044v0H3.761a2.64 2.64 0 01-2.64-2.64V4.244a2.64 2.64 0 012.64-2.64h27.72a2.64 2.64 0 012.64 2.64v17.16a2.64 2.64 0 01-2.64 2.64z"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={2}
          d="M31.481 4.244v0L19.487 16.237a2.64 2.64 0 01-3.733 0L3.761 4.244v0M31.481 21.403l-8.58-8.58M12.341 12.824l-8.58 8.58"
        />
      </svg>
    </SvgIcon>
  )
}

export default IconMessage
