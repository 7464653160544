import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken, Method } from 'axios'
import { saveAs } from 'file-saver'
import { merge } from 'lodash'
import queryString, { StringifyOptions } from 'query-string'
import { any } from 'ramda'
import { AppUserSnapshot } from '../models/app-user'
import { StopLogSnapshot } from '../models/archive'
import { ChildLogSnapshot } from '../models/archive/child-log'
import { ChildScheduleSnapshot } from '../models/archive/child-schedule'
import { OperationLogSnapshot } from '../models/archive/operation-log'
import { QuickStatsSnapshot } from '../models/archive/quick-stats'
import { CallLogSnapshot } from '../models/call-log'
import { ChildSnapshot } from '../models/child'
import { FlagSnapshot } from '../models/flag'
import { GroupChatMessageSnapshot, GroupChatSnapshot } from '../models/group-chat'
import { RegisterSnapshot } from '../models/register'
import { RootStore } from '../models/root-store'
import { BusSnapshot, RouteOverviewSnapshot, RouteSnapshot } from '../models/route'
import { MessageVariant } from '../models/snackbar-message'
import { StopOverviewSnapshot } from '../models/stop'
import { StopSnapshot } from '../models/stop/stop'
import { UserSnapshot } from '../models/user'
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config'
import {
  AdminUserQuery,
  AdminUserTableFilters,
  ApiResponse,
  CallLogQuery,
  CallLogTableFilters,
  ChaperoneDriverUserQuery,
  ChaperoneDriverUserTableFilters,
  ChildLogQuery,
  ChildLogTableFilters,
  ChildrenQuery,
  ChildRequestModel,
  ChildScheduleQuery,
  ChildScheduleTableFilters,
  ChildTableFilters,
  FlagQuery,
  FlagTableFilters,
  GroupChatMessageQuery,
  GroupChatQuery,
  LoginLogQuery,
  LoginLogTableFilters,
  PagedResult,
  ParentUserQuery,
  ParentUserTableFilters,
  QuickStatsFilter,
  RegistersQuery,
  RegisterTableFilters,
  RouteQuery,
  RouteRequestModel,
  RouteTableFilters,
  ServerExceptionMessage,
  StopLogQuery,
  StopLogTableFilters,
  StopQuery,
  StopRequestModel,
  StopTableFilters,
  TokenData,
  UserRequestModel,
} from './api.types'

const QUERY_STRING_OPTIONS: StringifyOptions = {
  skipEmptyString: true,
  skipNull: true,
}

const stringifyQuery = (query: any, options = QUERY_STRING_OPTIONS) => queryString.stringify(query, options)

export class Api {
  instance: AxiosInstance

  config: ApiConfig

  store: RootStore | undefined

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
    const { baseURL, timeout } = this.config
    this.instance = axios.create({
      baseURL,
      timeout,
      headers: {
        Accept: 'application/json',
      },
    })
  }

  setStore(store: RootStore) {
    this.store = store
  }

  get schoolCode() {
    return this.store?.schoolCode
  }

  get token() {
    return this.store?.token
  }

  /**
   * Config axios instance
   */
  setup() {
    this.instance.interceptors.request.use(config => {
      const { url } = config

      if (!url) return config

      if (
        any((prefix: string) => url.indexOf(prefix) > -1)([
          '/public/login',
          '/public/refresh',
          '/public/registration',
          '/public/password-reset',
        ]) ||
        // need school code to delete account
        (url === '/current' && config.method === 'get')
      ) {
        delete config.headers['schoolCode']
      } else if (this.schoolCode) {
        config.headers['schoolCode'] = this.schoolCode
      }

      if (
        any((prefix: string) => url.indexOf(prefix) > -1)([
          '/public/login',
          '/public/refresh',
          '/public/registration',
          '/public/password-reset',
        ])
      ) {
        delete config.headers['Authorization']
      } else {
        config.headers['Authorization'] = this.token
      }

      return config
    })

    this.instance.interceptors.response.use(
      response => response,
      error => {
        let message: string | null = null
        // no need to show message if request is cancelled by the user
        if (!axios.isCancel(error)) {
          if (error.response) {
            message = this.interpolateErrorMessage(error)
          } else if (error.request as XMLHttpRequest) {
            // no response is received
            const config = error.config as AxiosRequestConfig
            if (!['/buses', '/activities/flags/today'].includes(config.url || '')) {
              message = 'Sorry connection failed. Please try again later!'
            }
          } else {
            message = 'Cannot send the request, please try again later!'
          }
          if (message) this.store?.enqueueSnackbar(message, MessageVariant.error)
        }

        return Promise.reject(error)
      }
    )
  }

  // 根据 API 调用的业务场景和返回状态码来提供错误消息内容
  // if null is returned, no message will be displayed
  interpolateErrorMessage(error: AxiosError): string | null {
    if (error.isAxiosError) {
      const { config, response } = error
      if (response) {
        const { status } = response as AxiosResponse
        const { url = '', method } = config
        // this message will be displayed if user is not SCHOOL_ADMIN / SYS_ADMIN
        if (url === '/public/login' && status === 401) return 'Invalid email or password!'
        // when using cached token to fetch user data failed, will bring to login screen silently
        if (url === '/current' && status === 401) return null
        if (url.indexOf('/public/registration') > -1 && status === 400) {
          return 'This link is no longer valid. Please contract your school if you need assistance.'
        }

        // request password reset, but username not found
        if (url === '/public/password-reset' && status === 404) return null
        if (url.startsWith('/public/password-reset') && method?.toLowerCase() === 'put' && status === 404) return null
        if (url === '/buses') return null
        if (url === '/activities/flags/today') return null
        if (response.data) {
          // server error
          return (response.data as ServerExceptionMessage).message
        }
      }
      return error.message
    }

    // TODO: custom error messages based on request.config.url and method and the response etc.
    return error.message
  }

  async request<T>(method: Method, url: string, options?: AxiosRequestConfig): Promise<ApiResponse<T>> {
    try {
      const config: AxiosRequestConfig = merge({ method, url }, options || {})
      const response: AxiosResponse<T> = await this.instance.request(config)
      return Promise.resolve({ success: true, data: response.data, headers: response.headers })
    } catch (error) {
      console.log(error)
      return Promise.resolve({ success: false, error })
    }
  }

  /**
   *
   * @param url The download URL
   * @param query Query object
   * @param filename Optional filename of downloaded file
   */
  async download(url: string, query: any, filename?: string): Promise<void> {
    const response: ApiResponse<any> = await this.request('get', `${url}?${stringifyQuery(query)}`, {
      responseType: 'blob',
    })
    const disposition = response.headers['content-disposition'] || ''
    let attachmentName = filename
    const matches = disposition.match(/filename="(.*)"/)
    if (matches && matches.length > 0) {
      attachmentName = decodeURI(matches[1])
    }
    if (response.success) {
      saveAs(response.data, attachmentName)
      return Promise.resolve()
    }
    return Promise.reject()
  }

  async getTokenData(username: string, password: string): Promise<ApiResponse<TokenData>> {
    return this.request('post', '/public/login', { data: { username, password } })
  }

  async renewTokenData(refreshToken: string): Promise<ApiResponse<TokenData>> {
    // empty refreshToken will cause a 500 error
    if (!refreshToken)
      return Promise.resolve({
        success: false,
        error: new Error('refreshToken cannot be empty'),
      } as ApiResponse<TokenData>)

    return this.request('post', '/public/refresh', { data: { refreshToken } })
  }

  async getCurrentUser(): Promise<ApiResponse<AppUserSnapshot>> {
    return this.request('get', '/current')
  }

  async completeRegistration(verificationCode: string, values: any): Promise<ApiResponse<void>> {
    return this.request('put', `/public/registration/${verificationCode}`, { data: values })
  }

  async activateAccount(verificationCode: string, password: string): Promise<ApiResponse<UserSnapshot>> {
    return this.request('put', `/public/activation/${verificationCode}`, { data: { password } })
  }

  async requestPasswordReset(email: string): Promise<ApiResponse<void>> {
    return this.request('post', '/public/password-reset', { data: { email } })
  }

  async resetPassword(verificationCode: string, password: string) {
    return this.request('put', `/public/password-reset/${verificationCode}`, { data: { password } })
  }

  async getQuickStats(filters: QuickStatsFilter): Promise<ApiResponse<QuickStatsSnapshot>> {
    return this.request('get', `/activities/quick-stats/?${stringifyQuery(filters)}`)
  }

  async getBuses(): Promise<ApiResponse<BusSnapshot[]>> {
    return this.request('get', '/buses')
  }

  // TODO: rename?
  async getRouteOverview(): Promise<ApiResponse<RouteOverviewSnapshot[]>> {
    return this.request('get', '/routes/portal-overview')
  }

  async getStopOverview(): Promise<ApiResponse<StopOverviewSnapshot[]>> {
    return this.request('get', '/stops/portal-overview')
  }

  async getUsers(): Promise<ApiResponse<UserSnapshot[]>> {
    return this.request('get', '/users')
  }

  async getAllCampuses(): Promise<ApiResponse<string[]>> {
    return this.request('get', '/routes/campuses')
  }

  async getAllChildren(): Promise<ApiResponse<ApiResponse<ChildSnapshot[]>>> {
    return this.request('get', `/children/all`)
  }

  async downloadChildren(filters: ChildTableFilters): Promise<void> {
    return this.download('/children/download', filters)
  }

  async getRegisters(query: RegistersQuery): Promise<ApiResponse<PagedResult<RegisterSnapshot>>> {
    return this.request('get', `/child-schedules/rules?${stringifyQuery(query)}`)
  }

  async downloadRegisters(filters: RegisterTableFilters): Promise<void> {
    return this.download('/child-schedules/rules/download', filters)
  }

  async getFlags(query: FlagQuery, cancelToken?: CancelToken): Promise<ApiResponse<PagedResult<FlagSnapshot>>> {
    return this.request('get', `/activities/flags?${stringifyQuery(query)}`, { cancelToken })
  }

  async getTodayFlags(): Promise<ApiResponse<FlagSnapshot>> {
    return this.request('get', `/activities/flags/today`)
  }

  async downloadFlags(filters: FlagTableFilters): Promise<void> {
    return this.download('/activities/flags/download', filters)
  }

  async getAllGroupChats(query: GroupChatQuery): Promise<ApiResponse<GroupChatSnapshot[]>> {
    return this.request('get', `/chats?${stringifyQuery(query)}`)
  }

  async getMessagesBeforeDate(
    query: GroupChatMessageQuery,
    cancelToken?: CancelToken
  ): Promise<ApiResponse<GroupChatMessageSnapshot[]>> {
    const { routeName, childId, before, limit } = query
    return this.request('get', `/chats/routes/${routeName}/children/${childId}?${stringifyQuery({ before, limit })}`, {
      cancelToken,
    })
  }

  async getCallLogs(
    query: CallLogQuery,
    cancelToken?: CancelToken
  ): Promise<ApiResponse<PagedResult<CallLogSnapshot>>> {
    return this.request('get', `/calls?${stringifyQuery(query)}`, { cancelToken })
  }

  async downloadCallLogs(filters: CallLogTableFilters): Promise<void> {
    return this.download('/calls/download', filters)
  }

  async getStopLogs(
    query: StopLogQuery,
    cancelToken?: CancelToken
  ): Promise<ApiResponse<PagedResult<StopLogSnapshot>>> {
    return this.request('get', `/archives/stops?${stringifyQuery(query)}`, { cancelToken })
  }

  async downloadStopLogs(filters: StopLogTableFilters): Promise<void> {
    return this.download('/archives/stops/download', filters)
  }

  async getChildLogs(
    query: ChildLogQuery,
    cancelToken?: CancelToken
  ): Promise<ApiResponse<PagedResult<ChildLogSnapshot>>> {
    return this.request('get', `/archives/children?${stringifyQuery(query)}`, {
      cancelToken,
    })
  }

  async downloadChildLogs(filters: ChildLogTableFilters): Promise<void> {
    return this.download('/archives/children/download', filters)
  }

  async getLoginLogs(
    query: LoginLogQuery,
    cancelToken?: CancelToken
  ): Promise<ApiResponse<PagedResult<OperationLogSnapshot>>> {
    return this.request('get', `/operation-logs?${stringifyQuery({ ...query, operationType: 'LOGIN' })}`, {
      cancelToken,
    })
  }

  async downloadLoginLogs(filters: LoginLogTableFilters): Promise<void> {
    return this.download('/operation-logs/download', { ...filters, operationType: 'LOGIN' })
  }

  // archive - additional notification table
  async getChildSchedules(
    query: ChildScheduleQuery,
    cancelToken?: CancelToken
  ): Promise<ApiResponse<PagedResult<ChildScheduleSnapshot>>> {
    return this.request('get', `/child-schedules?${stringifyQuery(query)}`, {
      cancelToken,
    })
  }

  async downloadChildSchedules(filters: ChildScheduleTableFilters): Promise<void> {
    return this.download('/child-schedules/download', filters)
  }

  async getAdminUsers(
    query: AdminUserQuery,
    cancelToken?: CancelToken
  ): Promise<ApiResponse<PagedResult<UserSnapshot>>> {
    return this.request('get', `/admins?${stringifyQuery(query)}`, { cancelToken })
  }

  async downloadAdminUsers(filters: AdminUserTableFilters): Promise<void> {
    return this.download('/admins/download', filters)
  }

  async getChaperoneDriverUsers(
    query: ChaperoneDriverUserQuery,
    cancelToken?: CancelToken
  ): Promise<ApiResponse<PagedResult<UserSnapshot>>> {
    return this.request('get', `/chaperones?${stringifyQuery(query)}`, { cancelToken })
  }

  async downloadChaperoneDriverUsers(filters: ChaperoneDriverUserTableFilters): Promise<void> {
    return this.download('/chaperones/download', filters)
  }

  async getParents(query: ParentUserQuery, cancelToken?: CancelToken): Promise<ApiResponse<PagedResult<UserSnapshot>>> {
    return this.request('get', `/parents?${stringifyQuery(query)}`, { cancelToken })
  }

  async downloadParents(filters: ParentUserTableFilters): Promise<void> {
    return this.download('/parents/download', filters)
  }

  async addUser(user: UserRequestModel): Promise<ApiResponse<UserSnapshot>> {
    return this.request('post', '/users', { data: user })
  }

  async getUser(userId: number): Promise<ApiResponse<UserSnapshot>> {
    return this.request('get', `/school-users/${userId}`)
  }

  async updateUser(userId: number, user: UserRequestModel): Promise<ApiResponse<UserSnapshot>> {
    return this.request('put', `/users/${userId}`, { data: user })
  }

  async deleteUser(userId: number): Promise<ApiResponse<void>> {
    return this.request('delete', `/users/${userId}`)
  }

  async getRoutes(query: RouteQuery, cancelToken?: CancelToken): Promise<ApiResponse<PagedResult<RouteSnapshot>>> {
    return this.request('get', `/routes?${stringifyQuery(query)}`, { cancelToken })
  }

  async downloadRoutes(filters: RouteTableFilters): Promise<void> {
    return this.download('/routes/download', filters)
  }

  async addRoute(route: RouteRequestModel): Promise<ApiResponse<RouteSnapshot>> {
    return this.request('post', '/routes', { data: route })
  }

  async getRoute(routeId: number): Promise<ApiResponse<RouteSnapshot>> {
    return this.request('get', `/routes/${routeId}`)
  }

  async updateRoute(routeId: number, route: RouteRequestModel): Promise<ApiResponse<RouteSnapshot>> {
    return this.request('put', `/routes/${routeId}`, { data: route })
  }

  async deleteRoute(routeId: number): Promise<ApiResponse<void>> {
    return this.request('delete', `routes/${routeId}`)
  }

  async getStops(query: StopQuery, cancelToken?: CancelToken): Promise<ApiResponse<PagedResult<StopSnapshot>>> {
    return this.request('get', `/stops?${stringifyQuery(query)}`, { cancelToken })
  }

  async downloadStops(filters: StopTableFilters): Promise<void> {
    return this.download('/stops/download', filters)
  }

  async addStop(stop: StopRequestModel): Promise<ApiResponse<StopSnapshot>> {
    const routeId = stop.routeId
    return this.request('post', `/routes/${routeId}/stops`, { data: stop })
  }

  async getStop(stopId: number): Promise<ApiResponse<StopSnapshot>> {
    return this.request('get', `/stops/${stopId}`)
  }

  async updateStop(stopId: number, stop: StopRequestModel): Promise<ApiResponse<StopSnapshot>> {
    const routeId = stop.routeId
    return this.request('put', `/routes/${routeId}/stops/${stopId}`, { data: stop })
  }

  async deleteStop(stopId: number): Promise<ApiResponse<void>> {
    return this.request('delete', `stops/${stopId}`)
  }

  async getChildren(query: ChildrenQuery, cancelToken?: CancelToken): Promise<ApiResponse<PagedResult<ChildSnapshot>>> {
    return this.request('get', `/children?${stringifyQuery(query)}`, { cancelToken })
  }

  async getChild(childId: number): Promise<ApiResponse<ChildSnapshot>> {
    return this.request('get', `/children/${childId}`)
  }

  async addChild(child: ChildRequestModel): Promise<ApiResponse<ChildSnapshot>> {
    return this.request('post', '/children', { data: child })
  }

  async updateChild(childId: number, child: ChildRequestModel): Promise<ApiResponse<ChildSnapshot>> {
    return this.request('put', `/children/${childId}`, { data: child })
  }

  async deleteChild(childId: number): Promise<ApiResponse> {
    return this.request('delete', `/children/${childId}`)
  }

  async updateStopLocation(stopId: number, lat: number, lng: number): Promise<ApiResponse<void>> {
    return this.request('put', `/stops/${stopId}`, { data: { lat, lng } })
  }
}
