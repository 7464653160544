import { Grid, makeStyles, MenuItem } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { omit } from 'lodash'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { api } from '../../models/environment'
import { BIZZYBUS_HOME, PASSWORD_REGEX } from '../../utils/constant'
import AlertDialog from '../UI/AlertDialog'
import SubmitButton from '../UI/SubmitButton'
import Layout from './Layout'

const useStyles = makeStyles({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
})

interface FormValues {
  parentTitle: string
  firstName: string
  lastName: string
  phoneNo: string
  password: string
  confirmPassword: string
}

function ParentRegistration() {
  const classes = useStyles()
  const params = useParams<{ verificationCode: string }>()
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)

  const verificationCode = params.verificationCode

  const handleClose = () => {
    setSuccessDialogOpen(false)
    window.location.href = BIZZYBUS_HOME
  }

  return (
    <Layout
      title="Parent Account Registration"
      subtitle="Please enter the following information to create your account"
    >
      <Formik
        initialValues={{
          parentTitle: '',
          firstName: '',
          lastName: '',
          phoneNo: '',
          password: '',
          confirmPassword: '',
        }}
        validationSchema={Yup.object().shape({
          parentTitle: Yup.string().label('Parent Title').required(),
          firstName: Yup.string().label('Name').required(),
          lastName: Yup.string().label('Surname').required(),
          phoneNo: Yup.string().label('Mobile').required(),
          password: Yup.string()
            .matches(PASSWORD_REGEX, 'At least 8 characters including letter and number.')
            .required('At least 8 characters including letter and number.'),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Password does not match.')
            .required('Password does not match.'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          const data = omit(values, ['confirmPassword'])
          const response = await api.completeRegistration(verificationCode, data)
          response.success && setSuccessDialogOpen(true)
        }}
      >
        {({ submitForm, isSubmitting, isValid }) => (
          // TODO: disable autocomplete on all fields
          <Form className={classes.form} autoComplete="off">
            <Grid container spacing={2} direction="column" alignItems="stretch">
              <Grid item>
                <Field component={TextField} type="text" select name="parentTitle" label="Title" fullWidth>
                  <MenuItem value="Dad">Dad</MenuItem>
                  <MenuItem value="Mum">Mum</MenuItem>
                  <MenuItem value="Nanny">Nanny</MenuItem>
                </Field>
              </Grid>
              <Grid item>
                <Field component={TextField} name="firstName" label="Name" fullWidth />
              </Grid>
              <Grid item>
                <Field component={TextField} name="lastName" label="Surname" fullWidth />
              </Grid>
              <Grid item>
                <Field component={TextField} name="phoneNo" label="Mobile phone number" fullWidth />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  type="password"
                  name="password"
                  label="Create password"
                  fullWidth
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                />
              </Grid>
              <Grid item>
                <Field
                  component={TextField}
                  type="password"
                  name="confirmPassword"
                  label="Confirm password"
                  fullWidth
                  InputProps={{
                    autoComplete: 'new-password',
                  }}
                />
              </Grid>
              <Grid item>
                <SubmitButton onClick={submitForm} submitting={isSubmitting} text="Submit" fullWidth />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <AlertDialog
        open={successDialogOpen}
        handleClose={handleClose}
        title="Congratulations!"
        content={['You have successfully created your account.', 'Please download Buswise app to continue.']}
      />
    </Layout>
  )
}

export default ParentRegistration
