import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface SvgIconProps {
  size?: number
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error'
}

function IconExchange(props: SvgIconProps) {
  const { size, color } = props
  return (
    <SvgIcon style={{ fontSize: size || 36 }} color={color ? color : undefined}>
      <svg viewBox="0 0 41 26">
        <path
          fill="currentColor"
          d="M40.258 16.412l-7.906-8.03a.513.513 0 00-.858.213l-.02 3.49a.301.301 0 01-.3.301h-.716v.002h-21.4a.3.3 0 00-.3.301v3.183L2.05 9.06l6.707-6.812v3.183a.3.3 0 00.301.301h16.866a.505.505 0 100-1.012H10.071a.302.302 0 01-.302-.302v-3.39A.513.513 0 008.89.67L.984 8.7a.512.512 0 000 .72l7.906 8.031c.322.328.88.1.88-.359l.001-3.346c0-.167.135-.302.302-.302h22.112a.302.302 0 00.301-.302V9.96l6.706 6.811-6.706 6.813v-3.183a.3.3 0 00-.302-.3H15.341a.506.506 0 100 1.011h15.83c.167 0 .302.135.302.302v3.39c0 .46.556.686.88.36l7.905-8.032a.512.512 0 000-.72z"
        />
        <path
          fill="currentColor"
          stroke="currentColor"
          strokeMiterlimit={20}
          d="M40.258 16.412v0l-7.906-8.03a.513.513 0 00-.858.213l-.02 3.49a.301.301 0 01-.3.301h-.716v.002h-21.4a.3.3 0 00-.3.301v3.183L2.05 9.06v0l6.707-6.812v3.183a.3.3 0 00.301.301h16.866a.505.505 0 100-1.012H10.071a.302.302 0 01-.302-.302v-3.39A.513.513 0 008.89.67L.984 8.7a.512.512 0 000 .72l7.906 8.031c.322.328.88.1.88-.359l.001-3.346c0-.167.135-.302.302-.302h.711v0h4.051v0h17.35a.302.302 0 00.301-.302V9.96v0l6.706 6.811v0l-6.706 6.813v0-3.183a.3.3 0 00-.302-.3H15.341a.506.506 0 100 1.011h15.83c.167 0 .302.135.302.302v3.39c0 .46.556.686.88.36l7.905-8.032a.512.512 0 000-.72z"
        />
      </svg>
    </SvgIcon>
  )
}

export default IconExchange
