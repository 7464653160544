import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField as MuiTextField,
} from '@material-ui/core'
import { Field, FieldArray, Form, Formik, getIn } from 'formik'
import { TextField } from 'formik-material-ui'
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useReducer } from 'react'
import * as Yup from 'yup'
import { api } from '../../../models/environment'
import { useStores } from '../../../models/root-store'
import { MessageVariant } from '../../../models/snackbar-message'
import { UserSnapshot } from '../../../models/user'
import { FormProps } from '../../UI/DataTable'
import LoadingOverlay from '../../UI/LoadingOverlay'
import SubmitButton from '../../UI/SubmitButton'
import { useSharedStyles } from '../../../hooks/useSharedStyles'
import { formReducer } from './reducer'
import { getAdminRoleName, getRoleByUserType, getTitleByUserType } from '../../../utils/format'
import { AddCircleIcon, DeleteForeverIcon } from '../../UI/Icons'
import ChildSelect from '../../UI/Select/ChildSelect'

export interface UserFormProps<T> extends FormProps<T> {
  userType: 'admin' | 'parent' | 'chaperone'
}

function UserForm(props: UserFormProps<UserSnapshot>) {
  const { record: user, closeForm, userType } = props
  const classes = useSharedStyles()
  const rootStore = useStores()

  const [state, dispatch] = useReducer(formReducer.reducer, formReducer.state)

  useEffect(() => {
    if (user) {
      dispatch({ type: 'SET_LOADING', payload: true })
      api
        .getUser(user.userId)
        .then(response => {
          if (response.success) {
            dispatch({ type: 'SET_RECORD', payload: response.data as UserSnapshot })
          } else {
            // TODO: enqueue error message, and close form
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', payload: false }))
    } else {
      dispatch({ type: 'SET_RECORD', payload: null })
    }
  }, [user])

  const userId = user?.userId
  const role = getRoleByUserType(userType)

  return (
    <Dialog open={true} fullWidth maxWidth={userType === 'parent' ? 'md' : 'xs'} onClose={() => closeForm()}>
      <DialogTitle>{`${user ? 'Edit' : 'Add'} ${getTitleByUserType(userType)}`}</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{ ...state.values }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().label('Name').required(),
          lastName: Yup.string().label('Surname').required(),
          email: Yup.string().email('Invalid email').label('Email').required(),
          phoneNo: Yup.string().label('Contact').required(),
          role: userType === 'chaperone' ? Yup.string().label('Role').required() : Yup.string(),
          adminRole:
            userType === 'admin' ? Yup.string().label('User Role').required().nullable() : Yup.string().nullable(),
          jobType:
            userType === 'chaperone' ? Yup.string().label('Job Type').required().nullable() : Yup.string().nullable(),
          childIds: Yup.array<number>(Yup.number().moreThan(0, 'Please select a child or remove this line').required()),
          parentTitle:
            userType === 'parent' ? Yup.string().label('Parent Title').required().nullable() : Yup.string().nullable(),
        })}
        onSubmit={async values => {
          const schoolCode = rootStore.school?.code
          if (schoolCode) values.schools = [schoolCode]
          if (!values.role && role) values.role = role
          if (values.jobType === '') values.jobType = null
          const response = userId ? await api.updateUser(userId, values) : await api.addUser(values)
          if (response.success) {
            const message = userId ? 'User has been updated successfully' : 'User has been added successfully'
            rootStore.enqueueSnackbar(message, MessageVariant.success)
            closeForm(true)
          }
        }}
      >
        {({ touched, submitForm, errors, isSubmitting, values }) => {
          // for debug use
          // console.log(touched, errors)
          return (
            <>
              <DialogContent>
                <Form>
                  <Grid container spacing={2}>
                    <Grid
                      container
                      item
                      direction="column"
                      className={classes.formColumn}
                      spacing={2}
                      xs={userType === 'parent' ? 6 : 12}
                    >
                      {userType === 'admin' && (
                        <Grid item>
                          <Field
                            name="adminRole"
                            component={Autocomplete}
                            options={['SCHOOL_ADMINISTRATOR', 'SCHOOL_SECRETARY']}
                            getOptionLabel={getAdminRoleName}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                              <MuiTextField
                                {...params}
                                // HACK: in order to make the `error` check work
                                name="adminRole"
                                error={!!touched['adminRole'] && !!errors['adminRole']}
                                helperText={errors['adminRole']}
                                label="User Role"
                                variant="outlined"
                              />
                            )}
                            fullWidth
                            disableClearable
                          />
                        </Grid>
                      )}
                      {userType === 'chaperone' && (
                        <Grid item>
                          <Field
                            component={TextField}
                            type="text"
                            select
                            name="role"
                            label="Role"
                            variant="outlined"
                            fullWidth
                            disabled={!!userId}
                          >
                            <MenuItem value="CHAPERONE">Monitor</MenuItem>
                            <MenuItem value="DRIVER">Driver</MenuItem>
                          </Field>
                        </Grid>
                      )}
                      <Grid item>
                        <Field component={TextField} name="firstName" label="Name" variant="outlined" fullWidth />
                      </Grid>
                      <Grid item>
                        <Field component={TextField} name="lastName" label="Surname" variant="outlined" fullWidth />
                      </Grid>
                      <Grid item>
                        <Field
                          component={TextField}
                          name="email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                          disabled={!!userId}
                        />
                      </Grid>
                      <Grid item>
                        <Field component={TextField} name="phoneNo" label="Contact" variant="outlined" fullWidth />
                      </Grid>
                      {userType === 'parent' && (
                        <Grid item>
                          <Field
                            component={TextField}
                            type="text"
                            select
                            name="parentTitle"
                            label="Title"
                            variant="outlined"
                            fullWidth
                          >
                            <MenuItem value="Dad">Dad</MenuItem>
                            <MenuItem value="Mum">Mum</MenuItem>
                            <MenuItem value="Nanny">Nanny</MenuItem>
                          </Field>
                        </Grid>
                      )}
                      {userType !== 'parent' && (
                        <Grid item>
                          <Field component={TextField} name="campus" label="Campus" variant="outlined" fullWidth />
                        </Grid>
                      )}
                      {userType === 'chaperone' && (
                        <>
                          <Grid item>
                            <Field component={TextField} name="company" label="Company" variant="outlined" fullWidth />
                          </Grid>
                          <Grid item>
                            <Field
                              component={TextField}
                              type="text"
                              select
                              name="jobType"
                              label="Job Type"
                              variant="outlined"
                              fullWidth
                            >
                              <MenuItem value="REGULAR">Regular</MenuItem>
                              <MenuItem value="ON_ROTATION">On Rotation</MenuItem>
                            </Field>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    {userType === 'parent' && (
                      <Grid item xs={6}>
                        <FieldArray
                          name="childIds"
                          render={arrayHelpers => (
                            <Card variant="outlined">
                              <CardHeader title="Children" />
                              <CardContent>
                                <Grid container direction="column" spacing={2}>
                                  {values.childIds.map((childId: number, index: number) => {
                                    const fieldName = `childIds.${index}`
                                    return (
                                      <Grid item container spacing={0} key={fieldName}>
                                        <Grid item xs>
                                          <ChildSelect
                                            name={fieldName}
                                            label={`Child ${index + 1}`}
                                            childId={childId}
                                            setChildId={(childId: number) => arrayHelpers.replace(index, childId)}
                                            error={!!getIn(touched, fieldName) && !!getIn(errors, fieldName)}
                                            helperText={!!getIn(touched, fieldName) && getIn(errors, fieldName)}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <IconButton color="secondary" onClick={() => arrayHelpers.remove(index)}>
                                            <DeleteForeverIcon />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    )
                                  })}
                                </Grid>
                              </CardContent>
                              <CardActions className={classes.formCardActions}>
                                <Grid container justify="flex-end">
                                  <Grid item>
                                    <IconButton color="primary" onClick={() => arrayHelpers.push(0)}>
                                      <AddCircleIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Card>
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Form>
                <LoadingOverlay visible={state.loading} />
              </DialogContent>

              <DialogActions>
                <Button variant="contained" onClick={() => closeForm()}>
                  Cancel
                </Button>
                <SubmitButton onClick={submitForm} submitting={isSubmitting} text="Save" />
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default observer(UserForm)
