import { MenuItem, TextField } from '@material-ui/core'
import { FieldArrayRenderProps, FieldProps } from 'formik'
import { fieldToTextField } from 'formik-material-ui'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { useStores } from '../../../models/root-store'

export interface StopSelectProps extends FieldProps {
  arrayHelpers: FieldArrayRenderProps
  routeName: string | undefined
  routeType: 'Morning' | 'Afternoon'
}

function StopSelect(props: StopSelectProps) {
  const { routeType, arrayHelpers, ...fieldProps } = props
  const {
    field: { value },
    form: { values, errors, touched },
  } = fieldProps as FieldProps
  const rootStore = useStores()

  const options = useMemo(() => {
    if (!values.routeName) return []
    return rootStore.getStopOptions(values.routeName, routeType).map((option: { value: string; label: string }) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))
  }, [rootStore, routeType, values.routeName])

  const stopValueIndex = routeType === 'Morning' ? 0 : 1

  return (
    <TextField
      select
      {...fieldToTextField(fieldProps)}
      value={`${value || ''}`}
      disabled={options.length === 0}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const stopId = parseInt(e.target.value)
        arrayHelpers.replace(stopValueIndex, stopId)
      }}
      error={touched['stopIds'] && !!errors['stopIds']}
      helperText={touched['stopIds'] && errors['stopIds']}
    >
      {options}
    </TextField>
  )
}

export default observer(StopSelect)
