import { StopSnapshot } from '../../../models/stop'
import { StopRequestModel } from '../../../services/api.types'
import { Converter, createFormReducer } from '../../../utils/reducer'

const convert: Converter<StopSnapshot, StopRequestModel> = (record: StopSnapshot | null) => {
  if (!record) {
    return {
      routeId: 0,
      stopName: '',
      stopTime: '00:00:00',
      address: '',
      latitude: 0,
      longitude: 0,
    }
  } else {
    const { routeId, stopName, stopTime, address, latitude, longitude } = record
    return { routeId, stopName, stopTime, address, latitude, longitude }
  }
}

const formReducer = createFormReducer<StopSnapshot, StopRequestModel>(convert)

export { formReducer }
