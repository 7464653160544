import { CircularProgress, colors, Grid, LinearProgress, makeStyles, Typography } from '@material-ui/core'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AutoSizer, CellMeasurer, CellMeasurerCache, List, ListRowProps, ScrollParams, Size } from 'react-virtualized'
import { api } from '../../models/environment'
import { GroupChatMessageSnapshot } from '../../models/group-chat'
import { GroupChatMessageQuery } from '../../services/api.types'
import NoDataOverlay from '../UI/NoDataOverlay'

interface MessageListProps {
  routeName: string
  childId: number
}

const schoolMessageColor = colors.blue[500]
const mumMessageColor = 'rgb(129, 122, 247)'
const dadMessageColor = 'rgb(255, 142, 108)'
const nannyMessageColor = colors.pink[500]

const listItemHorizontalPadding = 25
const [listItemTopPadding, messagePaddingVertical, triangleHeight, contentHeight] = [15, 10, 20, 45]
const bubbleHeight = messagePaddingVertical * 2 + contentHeight
const listItemHeight = bubbleHeight + triangleHeight + listItemTopPadding

const useStyles = makeStyles({
  loadingMore: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
  },
  messageListItem: {
    paddingLeft: listItemHorizontalPadding,
    paddingRight: listItemHorizontalPadding,
    paddingTop: listItemTopPadding,
  },
  messageBubble: {
    maxWidth: '88%',
    borderRadius: 15,
    padding: `${messagePaddingVertical}px 20px`,
    marginBottom: triangleHeight,
    display: 'inline-block',
    color: '#fff',
    position: 'relative',

    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      borderWidth: triangleHeight / 2,
      borderStyle: 'solid',
      borderColor: 'transparent',
    },

    '&.schoolMessage': {
      background: schoolMessageColor,
    },

    '&.schoolMessage:before': {
      borderTopColor: schoolMessageColor,
      borderLeftColor: schoolMessageColor,
      left: 0,
      transform: `translate(100%, 100%)`,
    },

    '&.dadMessage': {
      background: dadMessageColor,
    },

    '&.dadMessage:before': {
      borderTopColor: dadMessageColor,
      borderRightColor: dadMessageColor,
      right: 0,
      transform: `translate(-100%, 100%)`,
    },

    '&.mumMessage': {
      background: mumMessageColor,
    },

    '&.mumMessage:before': {
      borderTopColor: mumMessageColor,
      borderRightColor: mumMessageColor,
      right: 0,
      transform: `translate(-100%, 100%)`,
    },

    '&.nannyMessage': {
      background: nannyMessageColor,
    },

    '&.nannyMessage:before': {
      borderTopColor: nannyMessageColor,
      borderRightColor: nannyMessageColor,
      right: 0,
      transform: `translate(-100%, 100%)`,
    },
  },
  message: {
    paddingTop: 0,
  },
})

function MessageList(props: MessageListProps) {
  // limit 20 messages each fetch request
  const limit = 20
  const { routeName, childId } = props

  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState<GroupChatMessageSnapshot[]>([])
  const loadingMore = useRef(false)

  const classes = useStyles()

  // scroll to bottom
  const autoScrolled = useRef(false)
  const cacheRef = useRef(
    new CellMeasurerCache({ minHeight: listItemHeight, defaultHeight: listItemHeight, fixedWidth: true })
  )
  const mostRecentWidth = useRef(0)
  const listRef = useRef<List | null>(null)
  const hasMore = useRef(true)
  const timestamp = useRef<number | null>(null)
  const focusedRowIndex = useRef<number>(0)

  const resizeAll = useCallback(() => {
    cacheRef.current.clearAll()
    listRef.current?.recomputeRowHeights()
  }, [])

  const getMessages = useCallback(() => {
    if (loadingMore.current) return
    loadingMore.current = true
    const query = {
      routeName,
      childId,
      kw: '',
      limit,
      before: timestamp.current,
    } as GroupChatMessageQuery
    setLoading(true)
    api
      .getMessagesBeforeDate(query)
      .then(response => {
        if (response.success && response.data) {
          hasMore.current = response.data.length === limit
          const snapshots = response.data as GroupChatMessageSnapshot[]
          if (snapshots.length > 0) {
            const updatedMessages = [...messages.concat(...(snapshots as GroupChatMessageSnapshot[]))].sort(
              (a, b) => a.sentAt - b.sentAt
            )
            timestamp.current = updatedMessages[0].sentAt
            focusedRowIndex.current =
              updatedMessages.length === snapshots.length ? snapshots.length : snapshots.length + 1
            setMessages(updatedMessages)
          }
          autoScrolled.current = true
        }
      })
      .finally(() => {
        setLoading(false)
        loadingMore.current = false
      })
  }, [childId, messages, routeName])

  const messageRenderer = useCallback(
    (props: ListRowProps) => {
      const { style, index, key, parent } = props
      const { message, user } = messages[index]

      return (
        <CellMeasurer
          cache={cacheRef.current}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
          width={mostRecentWidth.current}
        >
          {({ registerChild }) => (
            // TODO: correct the ref assign
            // @ts-ignore
            <Grid
              ref={registerChild}
              container
              style={style}
              className={classes.messageListItem}
              // put school-side messages on the left side and parent-side messages on the right side
              justify={user.role === 'PARENT' ? 'flex-end' : 'flex-start'}
              alignItems="flex-start"
            >
              <Grid
                item
                className={classnames(
                  classes.messageBubble,
                  `${user.parentTitle ? user.parentTitle.toLowerCase() : 'school'}Message`
                )}
              >
                <Typography variant="subtitle2">{`${user.firstName} - ${user.parentTitle || 'Monitor'}`}</Typography>
                <Typography variant="body2" className={classes.message}>
                  {message}
                </Typography>
              </Grid>
            </Grid>
          )}
        </CellMeasurer>
      )
    },
    [classes.message, classes.messageBubble, classes.messageListItem, messages]
  )

  useEffect(() => {
    if (!timestamp.current) {
      timestamp.current = new Date().getTime()
      getMessages()
    }
  }, [getMessages])

  const onScroll = (params: ScrollParams) => {
    if (params.scrollTop < bubbleHeight * 2 && hasMore.current && autoScrolled.current && !loadingMore.current) {
      getMessages()
    }
  }

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item xs>
        <AutoSizer>
          {({ height, width }: Size) => {
            if (width !== mostRecentWidth.current) {
              mostRecentWidth.current = width
              setTimeout(resizeAll, 0)
            }
            return messages.length > 0 ? (
              <>
                {loading && messages.length >= limit && <LinearProgress className={classes.loadingMore} />}
                <List
                  ref={listRef}
                  width={width}
                  height={height}
                  rowRenderer={messageRenderer}
                  deferredMeasurementCache={cacheRef.current}
                  rowHeight={cacheRef.current.rowHeight}
                  rowCount={messages.length}
                  onScroll={onScroll}
                  scrollToIndex={focusedRowIndex.current}
                  scrollToAlignment="start"
                />
              </>
            ) : (
              <div style={{ width, height, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {loading ? <CircularProgress /> : <NoDataOverlay />}
              </div>
            )
          }}
        </AutoSizer>
      </Grid>
    </Grid>
  )
}

export default observer(MessageList)
