import { Paper, Tab, Tabs } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import ContentHeader from '../../UI/ContentHeader'
import { useSharedStyles } from '../../../hooks/useSharedStyles'
import AdminUserTable from './AdminUserTable'
import ParentUserTable from './ParentUserTable'
import ChaperoneDriverUserTable from './ChaperoneDriverUserTable'
import { getTitleByUserType } from '../../../utils/format'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../models/root-store'

function ManageUser() {
  const rootStore = useStores()
  const { isAdmin } = rootStore
  const [activeTab, setActiveTab] = useState(isAdmin ? 'admin' : 'chaperone')

  const handleChange = useCallback((event: React.ChangeEvent<{}>, value: any) => {
    setActiveTab(value)
  }, [])

  const sharedClasses = useSharedStyles()

  return (
    <>
      <ContentHeader pageName="Manage Users" hideSelectedRoute />
      <Paper className={sharedClasses.pagePaper}>
        <Tabs value={activeTab} indicatorColor="primary" textColor="primary" onChange={handleChange}>
          {isAdmin && <Tab value="admin" label={getTitleByUserType('admin')} />}
          <Tab value="chaperone" label={getTitleByUserType('chaperone')} />
          <Tab value="parent" label={getTitleByUserType('parent')} />
        </Tabs>
        {activeTab === 'admin' && isAdmin && <AdminUserTable />}
        {activeTab === 'chaperone' && <ChaperoneDriverUserTable />}
        {activeTab === 'parent' && <ParentUserTable />}
      </Paper>
    </>
  )
}

export default observer(ManageUser)
