import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { UserModel } from './user'
import { SchoolModel } from './school'

/**
 * this is the current logged in user, to be differenciate with system user - UserModel
 */
export const AppUserModel = UserModel.named('AppUser').props({
  role: '',
  languagePreference: types.maybeNull(types.string),
  schools: types.array(SchoolModel),
})

export interface AppUser extends Instance<typeof AppUserModel> {}
export interface AppUserSnapshot extends SnapshotOut<typeof AppUserModel> {}
