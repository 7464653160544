import { TableCell } from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz/esm'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { SchoolContext } from '../../contexts'
import { useFilters } from '../../hooks/useFilters'
import { OperationLogSnapshot } from '../../models/archive/operation-log'
import { api } from '../../models/environment'
import { LoginLogTableFilters } from '../../services/api.types'
import { formatPickerDate, getDefaultDateDuration } from '../../utils/date'
import { DateRangeFilter } from '../UI/ColumnFilter/DateRangeFilter'
import DataTable from '../UI/DataTable'
import { useStores } from '../../models/root-store'

function LoginLogTable() {
  const rootStore = useStores()
  const { filters, updateFilters } = useFilters<LoginLogTableFilters>({
    roles: ['CHAPERONE', 'DRIVER'],
    routeId: rootStore.selectedRouteId,
    ...getDefaultDateDuration(),
  })
  const { timeZone } = useContext(SchoolContext)

  const { dateFrom, dateTo } = filters
  return (
    <DataTable
      filters={filters}
      download={api.downloadLoginLogs}
      request={api.getLoginLogs}
      renderHeadCells={() => (
        <>
          {/* <ColumnFilter labelTx="Driver/Monitor" filtering={roles.length === 1}>
            <Autocomplete
              size="small"
              openOnFocus
              options={['DRIVER', 'CHAPERONE']}
              getOptionLabel={(option: string) => (option === 'DRIVER' ? 'Driver' : 'Monitor')}
              renderInput={params => <TextField {...params} label="Select Driver/Monitor" variant="outlined" />}
              value={roles.length === 2 ? null : roles[0]}
              onChange={(event, value) =>
                updateFilters({
                  roles: value === null ? ['CHAPERONE', 'DRIVER'] : [value],
                })
              }
            />
          </ColumnFilter> */}
          <TableCell>Name</TableCell>
          <TableCell>Surname</TableCell>
          <DateRangeFilter
            columnName="Date Logged In"
            dateFrom={dateFrom}
            dateFromLabel={'Date From'}
            onDateFromChange={(date: MaterialUiPickersDate) => updateFilters({ dateFrom: formatPickerDate(date) })}
            dateTo={dateTo}
            dateToLabel={'Date To'}
            onDateToChange={(date: MaterialUiPickersDate) => updateFilters({ dateTo: formatPickerDate(date) })}
          />
          <TableCell>Time Logged In</TableCell>
        </>
      )}
      keyExtractor={(record: OperationLogSnapshot) => `${record.operationId}`}
      renderRowCells={(record: OperationLogSnapshot) => {
        const {
          operationTime,
          user: { firstName, lastName },
        } = record
        const date = utcToZonedTime(operationTime, timeZone)
        return (
          <>
            {/* <TableCell>{user.role === 'DRIVER' ? 'Driver' : 'Monitor'}</TableCell> */}
            <TableCell>{firstName}</TableCell>
            <TableCell>{lastName}</TableCell>
            <TableCell>{format(date, 'dd/MM/yyyy')}</TableCell>
            <TableCell>{format(date, 'HH:mm')}</TableCell>
          </>
        )
      }}
    />
  )
}

export default observer(LoginLogTable)
