import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField as MuiTextField,
} from '@material-ui/core'
import { format, parse } from 'date-fns'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
//@ts-ignore
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab'
import { TimePicker } from 'formik-material-ui-pickers'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import React, { useEffect, useReducer } from 'react'
import * as Yup from 'yup'
import { api } from '../../../models/environment'
import { useStores } from '../../../models/root-store'
import { RouteOverviewSnapshot } from '../../../models/route'
import { MessageVariant } from '../../../models/snackbar-message'
import { StopSnapshot } from '../../../models/stop'
import { routeSort } from '../../../utils/sort'
import { FormProps } from '../../UI/DataTable'
import LoadingOverlay from '../../UI/LoadingOverlay'
import SubmitButton from '../../UI/SubmitButton'
import { useSharedStyles } from '../../../hooks/useSharedStyles'
import { formReducer } from './reducer'

function StopForm(props: FormProps<StopSnapshot>) {
  const { record: stop, closeForm } = props
  const classes = useSharedStyles()
  const rootStore = useStores()

  const [state, dispatch] = useReducer(formReducer.reducer, formReducer.state)

  useEffect(() => {
    if (stop) {
      dispatch({ type: 'SET_LOADING', payload: true })
      api
        .getStop(stop.stopId)
        .then(response => {
          if (response.success) {
            dispatch({ type: 'SET_RECORD', payload: response.data as StopSnapshot })
          } else {
            // TODO: enqueue error message, and close form
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', payload: false }))
    } else {
      dispatch({ type: 'SET_RECORD', payload: null })
    }
  }, [stop])

  useEffect(() => {
    // make sure route data is up to date
    rootStore.getAllRoutes()
  }, [rootStore])

  const stopId = stop?.stopId
  return (
    <Dialog open={true} fullWidth maxWidth="sm" onClose={() => closeForm()}>
      <DialogTitle>{stop ? 'Edit Stop' : 'Add Stop'}</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={state.values}
        validationSchema={Yup.object().shape({
          routeId: Yup.number().min(1, 'Route is a required field').required('Route is a required field'),
          stopName: Yup.string().label('Stop Name').required(),
          stopTime: Yup.string().label('Time of Arrival').required(),
          address: Yup.string().label('Stop Address').required(),
          latitude: Yup.number().min(-180).max(180).label('Latitude').required(),
          longitude: Yup.number().min(-90).max(90).label('Longitude').required(),
        })}
        onSubmit={async values => {
          const response = stopId ? await api.updateStop(stopId, values) : await api.addStop(values)
          if (response.success) {
            const message = stopId ? 'Stop has been updated successfully' : 'Stop has been added successfully'
            rootStore.enqueueSnackbar(message, MessageVariant.success)
            closeForm(true)
          }
        }}
      >
        {({ values, submitForm, isSubmitting, touched, errors, setFieldValue }) => (
          <>
            <DialogContent>
              <Form>
                <Grid container direction="column" className={classes.formColumn} spacing={2}>
                  <Grid item>
                    <Field
                      name="routeId"
                      component={Autocomplete}
                      options={getSnapshot(rootStore.routes).slice().sort(routeSort)}
                      getOptionLabel={(option: RouteOverviewSnapshot) => `${option.routeName} ${option.routeType}`}
                      getOptionSelected={(option: RouteOverviewSnapshot, value: RouteOverviewSnapshot | null) => {
                        if (!value) return false
                        return option.routeId === value.routeId
                      }}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <MuiTextField
                          {...params}
                          name="routeId"
                          error={touched['routeId'] && !!errors['routeId']}
                          helperText={errors['routeId']}
                          label="Route"
                          variant="outlined"
                        />
                      )}
                      value={getSnapshot(rootStore.routes).find(n => n.routeId === values.routeId) || null}
                      onChange={(e: any, value: RouteOverviewSnapshot | null) => {
                        setFieldValue('routeId', value?.routeId || 0)
                      }}
                      disabled={!!stop}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Field component={TextField} name="stopName" label="Stop Name" variant="outlined" fullWidth />
                  </Grid>
                  <Grid item>
                    <Field component={TextField} name="address" label="Stop Address" variant="outlined" fullWidth />
                  </Grid>
                  <Grid item>
                    <Field
                      component={TextField}
                      type="number"
                      name="latitude"
                      label="Latitude"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      component={TextField}
                      type="number"
                      name="longitude"
                      label="Longitude"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      name="stopTime"
                      label="Set Time of Arrival"
                      fullWidth
                      component={TimePicker}
                      ampm={false}
                      inputVariant="outlined"
                      value={parse(values.stopTime, 'HH:mm:ss', new Date())}
                      onChange={(date: Date) => setFieldValue('stopTime', format(date, 'HH:mm:ss'))}
                    />
                  </Grid>
                </Grid>
              </Form>
              <LoadingOverlay visible={state.loading} />
            </DialogContent>

            <DialogActions>
              <Button variant="contained" onClick={() => closeForm()}>
                Cancel
              </Button>
              <SubmitButton onClick={submitForm} submitting={isSubmitting} text="Save" />
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  )
}

export default observer(StopForm)
