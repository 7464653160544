import { Box, FormControlLabel, makeStyles, Radio, RadioGroup, Typography, Grid, colors } from '@material-ui/core'
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import BusImage from '../../assets/images/bus.svg'
import SchoolImage from '../../assets/images/school.svg'
import SchoolContext from '../../contexts/SchoolContext'
import { useStores } from '../../models/root-store'
import { Bus, RouteOverview } from '../../models/route'
import { isMorning } from '../../utils/date'
import StopMarker from './StopMarker'
import ContentHeader from '../UI/ContentHeader'

const useStyle = makeStyles({
  mapContainerWrapper: {
    height: 500,
    backgroundColor: colors.grey[300],
  },
  mapContainer: {
    width: '100%',
    height: '100%',
  },
  radioGroupWrapper: {
    height: 60,
  },
})

// add other Google Maps API like "places" if needed
const libraries: any[] = []
const initialZoom = 12

// TODO: separate map component
function OverviewMap() {
  const rootStore = useStores()
  const classes = useStyle()

  const [showSchoolLabel, setShowSchoolLabel] = useState(false)
  const [map, setMap] = useState<google.maps.Map | null>(null)
  // const [showUpdateStopLatLngDlg, setShowUpdateStopLatLngDlg] = useState(false)
  // const [stopLatLng, setStopLatLng] = useState<[number, number]>([0, 0])
  // stop to update location
  // const [stopId, setStopId] = useState(0)

  // whether display routes based on current time on the map, or inverse morning / afternoon, i.e. display afternoon routes when it's morning time
  const [routeType, setRouteType] = useState<'Morning' | 'Afternoon'>('Morning')
  const { timeZone } = useContext(SchoolContext)

  // display InfoWindow
  const [selectedStopId, setSelectedStopId] = useState(0)
  const [selectedBusRouteId, setSelectedBusRouteId] = useState<number | null>(null)

  const { loadError, isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    libraries,
  })

  // get latest bus locations
  useEffect(() => {
    rootStore.getAllBuses()
    const id = setInterval(() => {
      rootStore.getAllBuses()
    }, 5e3)

    return () => clearInterval(id)
  }, [rootStore])

  const getBusMarkers = (buses: Bus[]) => {
    return buses.map(bus => {
      const { lat, lon, routeId } = bus
      const position = { lat, lng: lon }
      return (
        <React.Fragment key={`bus-marker-group-${routeId}`}>
          <Marker
            key={`bus-marker-${routeId}`}
            zIndex={40}
            position={position}
            icon={{
              url: BusImage,
              scaledSize: new google.maps.Size(24, 24),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(12, 12),
            }}
            onClick={() => {
              setSelectedBusRouteId(bus.routeId)
              setSelectedStopId(0)
            }}
          />
          {selectedBusRouteId === bus.routeId && (
            <InfoWindow
              key={`bus-info-${routeId}`}
              position={position}
              options={{ pixelOffset: new google.maps.Size(0, -16) }}
              onCloseClick={() => setSelectedBusRouteId(null)}
            >
              <Typography>{`Route ${bus.routeName}`}</Typography>
            </InfoWindow>
          )}
        </React.Fragment>
      )
    })
  }

  // TODO: school location should be fetched when selecting school
  const schoolMarker = useMemo(() => {
    if (!isLoaded) return null
    if (!rootStore.school) return null
    const {
      location: { lat, lon },
      name,
    } = rootStore.school
    const position = { lat, lng: lon }

    return (
      <>
        <Marker
          key="school-marker"
          zIndex={30}
          position={position}
          // onClick={() => setShowSchoolLabel(true)}
          icon={{
            url: SchoolImage,
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(20, 40),
          }}
        />
        {showSchoolLabel && (
          <InfoWindow
            key="school-info"
            position={position}
            options={{ pixelOffset: new google.maps.Size(0, -40) }}
            onCloseClick={() => setShowSchoolLabel(false)}
          >
            <Typography>{name}</Typography>
          </InfoWindow>
        )}
      </>
    )
  }, [isLoaded, rootStore.school, showSchoolLabel])

  // routes to display on map
  const routes = useMemo(() => {
    let routes: RouteOverview[] = []
    // TODO: test empty
    if (rootStore.selectedRoute) routes.push(rootStore.selectedRoute)
    else {
      rootStore.routePairs.forEach(routePair => {
        const [morningRoute, afternoonRoute] = routePair
        if (isMorning(timeZone) && morningRoute) {
          routes.push(morningRoute)
        } else if (!isMorning(timeZone) && afternoonRoute) {
          routes.push(afternoonRoute)
        }
        if ((routeType === 'Morning' && !isMorning(timeZone)) || (routeType === 'Afternoon' && isMorning(timeZone))) {
          const routeIds = routes.map(route => route.routeId)
          routes = rootStore.routes.filter(route => !routeIds.includes(route.routeId))
        }
      })
    }

    return routes
  }, [rootStore.selectedRoute, rootStore.routePairs, rootStore.routes, timeZone, routeType])

  const getStopMarkers = useCallback(() => {
    return routes.reduce((markers, route) => {
      const { color, stops } = route
      return markers.concat(
        stops
          .filter(n => n.lat && n.lon)
          .map(stop => {
            return (
              <StopMarker
                key={`stop-marker-${stop.stopId}`}
                stop={getSnapshot(stop)}
                color={color}
                clickedStopId={selectedStopId}
                setClickedStopId={(stopId: number) => {
                  setSelectedStopId(stopId)
                  setSelectedBusRouteId(null)
                }}
              />
            )
          })
      )
    }, [] as JSX.Element[])
  }, [routes, selectedStopId])

  // update map bounds
  useEffect(() => {
    if (!map) return
    const bounds = new google.maps.LatLngBounds()
    const schoolLocation = rootStore.school?.location
    schoolLocation && bounds.extend(new google.maps.LatLng(schoolLocation.lat, schoolLocation.lon))
    routes.forEach(route => {
      route.stops.forEach(stop => {
        const { lat, lon } = stop
        lat && lon && bounds.extend(new google.maps.LatLng(lat, lon))
      })
    })
    map.set('maxZoom', 15)
    map && map.fitBounds(bounds)
    map.set('maxZoom', undefined)
  }, [map, rootStore.routePairs, rootStore.selectedRoute, rootStore.school, timeZone, routeType, routes])

  // const handleCloseUpdateStopLatLngDlg = useCallback((e: React.MouseEvent) => setShowUpdateStopLatLngDlg(false), [])

  // const onMapClick = useCallback((e: google.maps.MouseEvent) => {
  //   setSelectedStopId(0)
  //   // setStopLatLng([e.latLng.lat(), e.latLng.lng()])
  //   // setShowUpdateStopLatLngDlg(true)
  // }, [])

  // const updateStopLocation = () => {
  //   rootStore.updateStopLocation(stopId, ...stopLatLng)
  //   setShowUpdateStopLatLngDlg(false)
  // }

  let errorMessage
  if (loadError) {
    errorMessage = 'Error loading the map.'
  } else if (!isLoaded) {
    errorMessage = 'Loading the map...'
  } else if (!rootStore.school) {
    errorMessage = 'Failed to load school geolocation data.'
  }

  // use https://www.latlong.net/ to get location lat & lng
  return (
    <>
      <ContentHeader pageName="Overview" selectedRouteOnly />
      {!rootStore.selectedRouteId && (
        <Box className={classes.radioGroupWrapper}>
          <RadioGroup
            row
            value={routeType}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) =>
              setRouteType(value as 'Morning' | 'Afternoon')
            }
          >
            <FormControlLabel value="Morning" control={<Radio color="primary" />} label="Morning Routes" />
            <FormControlLabel value="Afternoon" control={<Radio color="primary" />} label="Afternoon Routes" />
          </RadioGroup>
        </Box>
      )}
      <Grid container alignItems="center" justify="center" className={classes.mapContainerWrapper}>
        {
          errorMessage ? (
            <Typography align="center">{errorMessage}</Typography>
          ) : (
            <GoogleMap
              key="google-map"
              mapContainerClassName={classes.mapContainer}
              zoom={initialZoom}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
                zoomControlOptions: {
                  position: google.maps.ControlPosition.RIGHT_TOP,
                },
              }}
              onLoad={map => setMap(map)}
              // onClick={onMapClick}
            >
              {/* layers from bottom to top: stop -> school -> bus, which actually determined by zIndex property value */}
              {getStopMarkers()}
              {schoolMarker}
              {getBusMarkers(rootStore.buses)}
            </GoogleMap>
          )
          /* displaying dialog will cause GoogleMap container to be rendered again, and lost the bounds. */
          /* <Dialog key="update-stop-location-dlg" open={showUpdateStopLatLngDlg} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Stop Location</DialogTitle>
        <DialogContent>
          <DialogContentText>{`Location: [${stopLatLng.join(',')}]`}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="stopId"
            label="Stop ID"
            type="number"
            fullWidth
            onChange={e => {
              setStopId(+e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateStopLatLngDlg}>Cancel</Button>
          <Button onClick={updateStopLocation} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog> */
        }
      </Grid>
    </>
  )
}

export default observer(OverviewMap)
