import { sortBy } from 'lodash'
import { applySnapshot, flow, getSnapshot, Instance, SnapshotOut, types } from 'mobx-state-tree'
import { TokenData } from '../../services/api.types'
import { AppUserModel } from '../app-user'
import { ChildSnapshot } from '../child'
import { withEnvironment } from '../extensions'
import { FlagModel, FlagSnapshot } from '../flag'
import { BusModel, BusSnapshot, RouteOverview, RouteOverviewModel, RouteOverviewSnapshot } from '../route'
import { MessageVariant, SnackbarMessage, SnackbarMessageModel } from '../snackbar-message'
import { StopOverviewModel, StopOverviewSnapshot } from '../stop'
import { UserSnapshot } from '../user'

export const RootStoreModel = types
  .model('RootStore')
  .props({
    /**
     * The school code of the selected school
     */
    schoolCode: types.maybeNull(types.string),
    token: types.maybeNull(types.string),
    user: types.maybe(AppUserModel),
    selectedRouteId: types.maybe(types.number),

    // active buses' status
    buses: types.optional(types.array(BusModel), []),
    flags: types.optional(types.array(FlagModel), []),

    // only show flagged dot on flag page
    showFlaggedRouteDot: types.optional(types.boolean, false),

    // datasource of select components
    routes: types.optional(types.array(RouteOverviewModel), []),
    stops: types.optional(types.array(StopOverviewModel), []),
    campuses: types.optional(types.array(types.string), []),

    snackMessages: types.optional(types.array(SnackbarMessageModel), []),
  })
  .extend(withEnvironment)
  .views(self => ({
    get isAdmin() {
      return ['SCHOOL_ADMIN', 'SYS_ADMIN', 'TRANSPORT_MANAGER'].indexOf(self.user?.role || '') > -1
    },

    get school() {
      if (self.user?.schools && self.schoolCode) return self.user.schools.find(n => n.code === self.schoolCode)
      else return null
    },

    get routePairs() {
      const pairs = new Map<string, RouteOverview[]>()
      self.routes.forEach(route => {
        const idx = ['Morning', 'Afternoon'].indexOf(route.routeType)
        const key = route.routeName
        let pair: RouteOverview[] = []
        if (pairs.has(key)) pair = pairs.get(key) || []
        pair[idx] = route
        pairs.set(key, pair)
      })
      return pairs
    },

    get routeNames() {
      let routeNames = [...new Set(self.routes.map(route => route.routeName))]
      return routeNames.sort()
    },

    get selectedRoute() {
      if (!self.selectedRouteId) return undefined
      else return self.routes.find(n => n.routeId === self.selectedRouteId)
    },

    get selectedRouteName() {
      if (!self.selectedRouteId) return undefined
      else return self.routes.find(n => n.routeId === self.selectedRouteId)?.routeName
    },

    get flagCount() {
      return self.flags.length
    },
  }))
  .actions(self => ({
    setSelectedRouteId(routeId?: number) {
      self.selectedRouteId = routeId
    },

    setShowFlaggedRouteDot(visible: boolean) {
      self.showFlaggedRouteDot = visible
    },

    storeTokenData({ token }: TokenData) {
      localStorage.setItem('token', token)
      self.token = token
    },

    /**
     * Clear credential, as a rollback action of storeTokenData when:
     * 1. user turns out to be not an 'admin'
     * 2. invalid / expired token
     */
    clearCredentials() {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('schoolCode')
    },

    setSchoolCode(schoolCode: string | null) {
      if (schoolCode) {
        localStorage.setItem('schoolCode', schoolCode)
      } else {
        localStorage.removeItem('schoolCode')
      }
      self.schoolCode = schoolCode
    },

    getStopOptions(routeName: string, routeType: 'Morning' | 'Afternoon') {
      const options = self.stops
        .filter(stop => stop.routeType === routeType && stop.routeName === routeName)
        .map(stop => ({ value: `${stop.stopId}`, label: stop.stopName }))
      return sortBy(options, 'label')
    },

    enqueueSnackbar(tx: string, variant = MessageVariant.default) {
      self.snackMessages.push(
        SnackbarMessageModel.create({
          key: new Date().getTime() + Math.random(),
          tx,
          variant,
        })
      )
    },

    removeSnackbar(message: SnackbarMessage) {
      self.snackMessages.remove(message)
    },
  }))
  .actions(self => ({
    getCurrentUser: flow(function* () {
      const { api } = self
      const response = yield api.getCurrentUser()
      if (response.success) {
        if (['SCHOOL_ADMIN', 'SYS_ADMIN', 'TRANSPORT_MANAGER'].indexOf(response.data.role) === -1) {
          self.enqueueSnackbar('Invalid email or password!', MessageVariant.error)
          self.clearCredentials()
          return false
        } else {
          self.user = AppUserModel.create(response.data)
          if (self.user.schools.length === 1) {
            self.schoolCode = self.user.schools[0].code

            // FIXME: test code for viewing school select layout with a quite number of schools
            // const school = getSnapshot(self.user.schools[0])
            // for (let i = 0; i < 100; i++) {
            //   self.user.schools.push({
            //     ...school,
            //     code: `dev-${i}`,
            //   })
            // }
          }
          return true
        }
      } else {
        self.clearCredentials()
        return false
      }
    }),
  }))
  .actions(self => ({
    login: flow(function* (username, password) {
      const { api } = self
      const response = yield api.getTokenData(username, password)
      if (response.success) {
        self.storeTokenData(response.data)
        return yield self.getCurrentUser()
      } else {
        return false
      }
    }),

    logout() {
      self.clearCredentials()
      // we'd like to keep the snackbar messages, in case need to show it on login screen after logout
      applySnapshot(self, { snackMessages: getSnapshot(self.snackMessages) })
    },

    getAllBuses: flow(function* () {
      const { api } = self
      const response = yield api.getBuses()
      if (response.success) {
        self.buses.clear()
        applySnapshot(self.buses, response.data as BusSnapshot[])
      }
    }),

    /**
     * Get RouteSelector data
     */
    getAllRoutes: flow(function* () {
      const { api } = self
      const response = yield api.getRouteOverview()
      if (response.success) {
        applySnapshot(self.routes, response.data as RouteOverviewSnapshot[])
      }
    }),

    getAllStops: flow(function* () {
      const { api } = self
      const response = yield api.getStopOverview()
      if (response.success) {
        applySnapshot(self.stops, response.data as StopOverviewSnapshot[])
      }
    }),

    // TODO: correct API to get school users?
    getAllUsers: flow(function* () {
      const { api } = self
      const response = yield api.getUsers()
      let users: UserSnapshot[] = []
      if (response.success) {
        users = sortBy(response.data, ['firstName', 'lastName'])
      }
      return users
    }),

    getAllChildren: flow(function* () {
      const { api } = self
      const response = yield api.getAllChildren()
      let children: ChildSnapshot[] = []
      if (response.success) {
        children = sortBy(response.data, ['firstName', 'lastName']) as ChildSnapshot[]
      }
      return children
    }),

    getAllCampuses: flow(function* () {
      const { api } = self
      const response = yield api.getAllCampuses()
      if (response.success) {
        applySnapshot(self.campuses, response.data)
      }
    }),

    getTodayFlags: flow(function* () {
      const { api } = self
      const response = yield api.getTodayFlags()
      if (response.success) {
        applySnapshot(self.flags, response.data as FlagSnapshot[])
      }
    }),

    updateStopLocation: flow(function* (stopId: number, lat: number, lng: number) {
      const { api } = self
      const response = yield api.updateStopLocation(stopId, lat, lng)
      if (response.success) self.enqueueSnackbar('Location updated!', MessageVariant.success)
    }),
  }))

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
