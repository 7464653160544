import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { addMonths, format, getHours, parseISO, parseJSON } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { DateDurationFilter } from '../services/api.types'
import { DATE_FORMAT } from './constant'

/**
 * Convert an ISO 8601 date string to target format. Use this function to parse pure date field
 * @param date date string in ISO 8601 format
 * @param fmt target format
 */
export const formatISODateString = (date: string, fmt = DATE_FORMAT) => format(parseISO(date), fmt)

/**
 * Convert an JSON API date string to target format. Use this function to parse datetime string
 * @param date date string, see supported format here https://date-fns.org/v2.16.1/docs/parseJSON
 * @param fmt target format
 */
export const formatJsonDate = (date: string, fmt = DATE_FORMAT) => format(parseJSON(date), fmt)

/**
 * Parse a date-alike value to time format, will be convert to specified timezone first
 * @param date
 * @param timeZone
 * @param fmt
 */
export const toLocalTime = (date: Date | number | string, timeZone: string, fmt = 'HH:mm') => {
  const d = typeof date === 'string' ? parseJSON(date) : date
  return format(utcToZonedTime(d, timeZone), fmt)
}

/**
 * Trim seconds
 * @param time hh:mm:ss
 */
export const formatISOTimeString = (time: string) => time.substr(0, 5)

/**
 * Check if it's morning time at the school time zone
 * @param timeZone School time zone
 */
export const isMorning = (timeZone: string) => {
  return getHours(utcToZonedTime(new Date(), timeZone)) < 12
}

/**
 * Default date from value to filter table data
 */
export const getDefaultDateFromString = () => format(addMonths(new Date(), -4), 'yyyy-MM-dd')

export const getCurrentDateString = () => format(new Date(), 'yyyy-MM-dd')

export const formatPickerDate = (date: MaterialUiPickersDate) => {
  return date === null ? null : format(date, 'yyyy-MM-dd')
}

export const getDefaultDateDuration: () => DateDurationFilter = () => ({
  dateFrom: getDefaultDateFromString(),
  dateTo: getCurrentDateString(),
})
