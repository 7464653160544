import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface SvgIconProps {
  size?: number
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error'
}

function IconBus(props: SvgIconProps) {
  const { size, color } = props
  return (
    <SvgIcon style={{ fontSize: size || 36 }} color={color ? color : undefined}>
      <svg viewBox="0 0 33 34">
        <path
          fill="currentColor"
          strokeMiterlimit="20"
          strokeWidth="1"
          strokeLinejoin="round"
          d="M23.957 21.438H9.329a.584.584 0 100 1.17h14.628a.584.584 0 100-1.17z"
        />
        <path
          fill="currentColor"
          strokeMiterlimit="20"
          strokeWidth="1"
          strokeLinejoin="round"
          d="M9.33 22.733a.71.71 0 010-1.42h14.627a.71.71 0 010 1.42zm0-1.17a.46.46 0 000 .92h14.627a.46.46 0 000-.92zM23.957 23.778H9.329a.584.584 0 100 1.17h14.628a.584.584 0 100-1.17z"
        />
        <path
          fill="currentColor"
          strokeMiterlimit="20"
          strokeWidth="1"
          strokeLinejoin="round"
          d="M9.33 25.073a.71.71 0 010-1.42h14.627a.71.71 0 010 1.42zm0-1.17a.46.46 0 000 .92h14.627a.46.46 0 000-.92zM7.061 19.939H5.745a.585.585 0 100 1.17h1.316a.585.585 0 000-1.17z"
        />
        <path
          fill="currentColor"
          strokeMiterlimit="20"
          strokeWidth="1"
          strokeLinejoin="round"
          d="M5.745 21.233a.71.71 0 010-1.42h1.316a.711.711 0 010 1.42zm0-1.17a.46.46 0 000 .92h1.316a.46.46 0 100-.92zM27.541 19.939h-1.316a.584.584 0 100 1.17h1.316a.584.584 0 100-1.17z"
        />
        <path
          fill="currentColor"
          strokeMiterlimit="20"
          strokeWidth="1"
          strokeLinejoin="round"
          d="M26.225 21.233a.71.71 0 010-1.42h1.316a.711.711 0 010 1.42zm0-1.17a.46.46 0 000 .92h1.316a.46.46 0 100-.92z"
        />
        <path
          fill="currentColor"
          strokeMiterlimit="20"
          strokeWidth="1"
          strokeLinejoin="round"
          d="M27.858.41c1.29 0 2.34 1.05 2.34 2.34v2.643A2.336 2.336 0 0132.42 7.72v2.853c0 1.25-.985 2.264-2.22 2.328v14.193c0 .645-.526 1.17-1.17 1.17h-.875c0 .017.009.03.009.048v3.073c0 .967-.787 1.755-1.755 1.755h-2.56a1.757 1.757 0 01-1.755-1.755v-3.073c0-.017.008-.031.009-.048H11.185c0 .017.009.03.009.048v3.073c0 .967-.787 1.755-1.755 1.755h-2.56a1.757 1.757 0 01-1.755-1.755v-3.073c0-.017.008-.031.009-.048h-.875c-.645 0-1.17-.525-1.17-1.17V12.907a2.34 2.34 0 01-2.265-2.333V7.72a2.34 2.34 0 012.265-2.333V2.75c0-1.29 1.05-2.34 2.34-2.34zm-23.6 16.713h24.77V5.006H4.259zM5.428 1.58c-.645 0-1.17.525-1.17 1.17v1.086h24.77V2.75a1.17 1.17 0 00-1.17-1.17zm-3.434 8.994c0 .619.485 1.123 1.094 1.163v-5.18a1.169 1.169 0 00-1.094 1.164zm8.03 17.739c0-.017.009-.031.01-.048h-3.75c.002.017.01.03.01.048v3.073c0 .322.262.585.585.585h2.56a.586.586 0 00.585-.585zm16.968 0c0-.017.008-.031.01-.048h-3.75c.002.017.01.03.01.048v3.073c0 .322.263.585.586.585h2.56a.586.586 0 00.585-.585zM31.25 7.72c0-.604-.462-1.097-1.051-1.158v5.169a1.168 1.168 0 001.05-1.158zM4.258 27.095h24.77v-8.802H4.259z"
        />
        <path
          fill="currentColor"
          strokeMiterlimit="20"
          strokeWidth="1"
          d="M23.848 33.265a1.883 1.883 0 01-1.881-1.88V28.39H11.32v2.996c0 1.036-.844 1.88-1.881 1.88h-2.56a1.882 1.882 0 01-1.88-1.88V28.39h-.741a1.297 1.297 0 01-1.295-1.295v-14.07a2.451 2.451 0 01-2.265-2.45V7.72a2.45 2.45 0 012.265-2.45V2.75A2.468 2.468 0 015.428.284h22.43a2.468 2.468 0 012.466 2.466v2.526a2.46 2.46 0 012.22 2.444v2.853a2.46 2.46 0 01-2.22 2.444v14.077c0 .714-.581 1.295-1.295 1.295h-.741v2.996c0 1.036-.843 1.88-1.88 1.88zM11.052 28.14h11.187l-.022.182v3.064c0 .9.732 1.63 1.63 1.63h2.56c.9 0 1.63-.73 1.63-1.63v-3.072c-.002 0-.007-.02-.008-.04l-.008-.134h1.008c.576 0 1.045-.469 1.045-1.045v-14.31l.119-.008a2.21 2.21 0 002.102-2.203V7.72a2.21 2.21 0 00-2.102-2.203l-.12-.006v-2.76A2.218 2.218 0 0027.86.533H5.428A2.218 2.218 0 003.213 2.75v2.758l-.122.004A2.204 2.204 0 00.948 7.72v2.853c0 1.2.942 2.17 2.143 2.208l.122.004v14.31c0 .575.468 1.044 1.045 1.044H5.27l-.021.182v3.064c0 .9.73 1.63 1.63 1.63h2.56c.899 0 1.63-.73 1.63-1.63v-3.072a.103.103 0 01-.01-.04zm12.796 3.956a.712.712 0 01-.711-.71v-3.072a.11.11 0 01-.01-.04l-.006-.134h4.014l-.017.182v3.064a.71.71 0 01-.71.71zm-.461-3.706v2.996c0 .253.207.46.46.46h2.56a.46.46 0 00.46-.46V28.39zM6.879 32.095a.71.71 0 01-.71-.71v-3.072a.104.104 0 01-.01-.04l-.007-.134h4.019l-.022.182v3.064a.71.71 0 01-.71.71zm-.46-3.706v2.996c0 .253.206.46.46.46h2.56a.46.46 0 00.46-.46V28.39zm22.735-1.17H4.133v-9.052h25.02zm-24.771-.25h24.52v-8.552H4.384zm24.77-9.722H4.134V4.88h25.02zm-24.77-.25h24.52V5.13H4.384zm25.69-5.127V6.423l.139.015a1.29 1.29 0 011.163 1.282v2.853c0 .662-.5 1.214-1.163 1.283zm.25-.286a1.04 1.04 0 00.802-1.01V7.72a1.04 1.04 0 00-.801-1.01zm-27.11.285l-.134-.009a1.295 1.295 0 01-1.211-1.287V7.72c0-.677.532-1.242 1.21-1.287l.135-.009zM2.963 6.7a1.044 1.044 0 00-.845 1.02v2.853c0 .501.362.926.845 1.021zm26.19-2.74H4.134V2.75c0-.714.58-1.295 1.295-1.295h22.43c.715 0 1.296.581 1.296 1.295zm-24.77-.25h24.52v-.96c0-.577-.468-1.045-1.044-1.045H5.428c-.576 0-1.045.468-1.045 1.045z"
        />
        <path
          fill="currentColor"
          strokeMiterlimit="20"
          strokeWidth="1"
          d="M12.099 6.893a.585.585 0 00-.813.154L6.37 14.254a.586.586 0 00.967.66l4.915-7.208a.585.585 0 00-.154-.813z"
        />
        <path
          fill="currentColor"
          strokeMiterlimit="20"
          strokeWidth="1"
          d="M6.455 15.171a.711.711 0 01-.187-.986l4.915-7.209a.71.71 0 011.173.8L7.44 14.985a.707.707 0 01-.986.188zm4.934-8.053l-4.915 7.206a.462.462 0 00.121.64.47.47 0 00.638-.12l4.915-7.208a.46.46 0 00-.759-.518zM14.24 8.483a.586.586 0 00-.814.153L9.81 13.94a.586.586 0 00.967.66l3.615-5.303a.584.584 0 00-.153-.813z"
        />
        <path
          fill="currentColor"
          strokeMiterlimit="20"
          strokeWidth="1"
          d="M9.894 14.856a.712.712 0 01-.187-.987l3.617-5.303a.71.71 0 01.985-.186.711.711 0 01.188.986L10.88 14.67a.707.707 0 01-.986.187zm3.636-6.149L9.914 14.01a.462.462 0 00.12.641.473.473 0 00.64-.122l3.616-5.302a.461.461 0 00-.76-.519z"
        />
      </svg>
    </SvgIcon>
  )
}

export default IconBus
