export const getUsername = (snapshot: { firstName?: string; lastName?: string } | null) => {
  if (!snapshot) return ''
  if (snapshot.firstName && snapshot.lastName) return `${snapshot.firstName} ${snapshot.lastName}`
  else return ''
}

export const getRoleByUserType = (userType: string) => {
  let role = ''
  if (userType === 'admin') role = 'SCHOOL_ADMIN'
  else if (userType === 'parent') role = 'PARENT'
  return role
}

export const getTitleByUserType = (userType: string) => {
  switch (userType) {
    case 'admin':
      return 'Administrator'
    case 'parent':
      return 'Parent'
    case 'chaperone':
      return 'Driver/Monitor'
    default:
      return ''
  }
}

export const getAdminRoleName = (adminRole: 'SCHOOL_ADMINISTRATOR' | 'SCHOOL_SECRETARY' | null) => {
  if (adminRole === 'SCHOOL_ADMINISTRATOR') return 'School Administrator'
  else if (adminRole === 'SCHOOL_SECRETARY') return 'School Secratory'
  else return ''
}

export const getJobTypeName = (jobType: 'ON_ROTATION' | 'REGULAR' | null) => {
  if (jobType === 'ON_ROTATION') return 'On Rotation'
  else if (jobType === 'REGULAR') return 'Regular'
  else return ''
}
