import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { SchoolContactModel } from './school-contact'
import { LocationModel } from './location'

/**
 * Model description here for TypeScript hints.
 */
export const SchoolModel = types.model('School').props({
  code: types.string,
  name: types.string,
  defaultContacts: types.optional(types.array(SchoolContactModel), []),

  /**
   * default school contacts, shown as emergency contacts and chat message contacts
   * 1. Chaperone's Manager
   * 2. School Transport Manager
   * 3. School Secretary
   */
  contacts: types.optional(types.array(SchoolContactModel), []),
  timeZone: types.maybe(types.string),
  schoolImage: types.maybe(types.string),
  location: LocationModel,
})

type SchoolType = Instance<typeof SchoolModel>
export interface School extends SchoolType {}
type SchoolSnapshotType = SnapshotOut<typeof SchoolModel>
export interface SchoolSnapshot extends SchoolSnapshotType {}
