import { TableCell } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useFilters } from '../../../hooks/useFilters'
import { api } from '../../../models/environment'
import { useStores } from '../../../models/root-store'
import { UserSnapshot } from '../../../models/user'
import { ParentUserTableFilters } from '../../../services/api.types'
import { KeywordFilter } from '../../UI/ColumnFilter'
import DataTable from '../../UI/DataTable'
import UserForm from './UserForm'

function ParentUserTable() {
  const rootStore = useStores()
  const { filterValues, filters, updateFilters } = useFilters<ParentUserTableFilters>({
    lastName: '',
  })

  const { lastName } = filterValues
  return (
    <DataTable
      enableCreate={rootStore.isAdmin}
      idProperty="userId"
      filters={filters}
      request={api.getParents}
      download={api.downloadParents}
      renderHeadCells={() => (
        <>
          <TableCell>Name</TableCell>
          <KeywordFilter
            label="Search Surname"
            columnName="Surname"
            value={lastName}
            onChange={(value: string) => updateFilters({ lastName: value }, true)}
          />
          <TableCell>Email</TableCell>
          <TableCell>Contact</TableCell>
        </>
      )}
      renderRowCells={(record: UserSnapshot) => {
        const { firstName, lastName, email, phoneNo } = record
        return (
          <>
            <TableCell>{firstName}</TableCell>
            <TableCell>{lastName}</TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>{phoneNo}</TableCell>
          </>
        )
      }}
      formComponent={UserForm}
      formProps={{ userType: 'parent' }}
      onDeleteContext={{
        confirm: {
          title: 'Delete User',
          messages: ['Are you sure you want to delete this user?'],
        },
        request: api.deleteUser,
        successMessage: 'User has been deleted successfully',
      }}
    />
  )
}

export default observer(ParentUserTable)
