import { RouteSnapshot } from '../../../models/route'
import { RouteRequestModel } from '../../../services/api.types'
import { Converter, createFormReducer } from '../../../utils/reducer'

const convert: Converter<RouteSnapshot, RouteRequestModel> = (record: RouteSnapshot | null) => {
  if (!record) {
    return {
      routeName: '',
      routeType: 'Morning',
      displayName: '',
      campus: '',
      color: '',
      chaperoneId: 0,
      driverId: null,
    }
  } else {
    const { routeName, routeType, displayName, campus, color, chaperone, driver } = record
    return {
      routeName,
      routeType,
      displayName: displayName || '',
      campus,
      color,
      chaperoneId: chaperone?.userId || 0,
      driverId: driver?.userId || null,
    }
  }
}

const formReducer = createFormReducer<RouteSnapshot, RouteRequestModel>(convert)

export { formReducer }
