import { Instance, SnapshotOut, types } from 'mobx-state-tree'

export const BusModel = types.model('Bus').props({
  lat: types.number,
  lon: types.number,
  routeId: types.number,
  routeName: types.string,
})

export interface Bus extends Instance<typeof BusModel> {}
export interface BusSnapshot extends SnapshotOut<typeof BusModel> {}
