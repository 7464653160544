import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface SvgIconProps {
  size?: number
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error'
}

function IconFlag(props: SvgIconProps) {
  const { size, color } = props
  return (
    <SvgIcon style={{ fontSize: size || 36 }} color={color ? color : undefined}>
      <svg viewBox="0 0 41.46 42.22">
        <path
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          d="M41.46 25.72l-3.8-8.17 3.8-8.17h-7.72v12.76H32V5.8H15.82V2a2 2 0 00-4.09 0v36.13H2a2 2 0 000 4.09h31.7a2 2 0 100-4.09H15.82v-16h11.69v3.58z"
        />
      </svg>
    </SvgIcon>
  )
}

export default IconFlag
