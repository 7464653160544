import { Paper, TableCell } from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { format, parseISO } from 'date-fns'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useFilters } from '../hooks/useFilters'
import { useSharedStyles } from '../hooks/useSharedStyles'
import { api } from '../models/environment'
import { FlagSnapshot } from '../models/flag'
import { useStores } from '../models/root-store'
import { FlagTableFilters } from '../services/api.types'
import { DATE_FORMAT } from '../utils/constant'
import { formatPickerDate, getDefaultDateDuration } from '../utils/date'
import { DateRangeFilter } from './UI/ColumnFilter/DateRangeFilter'
import ContactTag from './UI/ContactTag'
import ContentHeader from './UI/ContentHeader'
import DataTable from './UI/DataTable'

function FlagTable() {
  const rootStore = useStores()

  const sharedClasses = useSharedStyles()

  const { filters, updateFilters } = useFilters<FlagTableFilters>({
    routeId: rootStore.selectedRouteId,
    ...getDefaultDateDuration(),
  })

  useEffect(() => {
    rootStore.getTodayFlags()
    rootStore.setShowFlaggedRouteDot(true)

    return () => {
      rootStore.setShowFlaggedRouteDot(false)
    }
  }, [rootStore])

  const { dateFrom, dateTo } = filters

  return (
    <>
      <ContentHeader pageName="Flags" />
      <Paper className={sharedClasses.pagePaper}>
        <DataTable
          filters={filters}
          download={api.downloadFlags}
          request={api.getFlags}
          renderHeadCells={() => (
            <>
              <DateRangeFilter
                columnName="Date"
                dateFrom={dateFrom}
                dateFromLabel="Date From"
                onDateFromChange={(date: MaterialUiPickersDate) => updateFilters({ dateFrom: formatPickerDate(date) })}
                dateTo={dateTo}
                dateToLabel="Date To"
                onDateToChange={(date: MaterialUiPickersDate) => updateFilters({ dateTo: formatPickerDate(date) })}
              />
              <TableCell>Bus</TableCell>
              <TableCell>Description</TableCell>
            </>
          )}
          renderRowCells={(record: FlagSnapshot) => {
            const { dateOccurred, chaperone, flag } = record
            return (
              <>
                <TableCell>{format(parseISO(dateOccurred), DATE_FORMAT)}</TableCell>
                <TableCell>{<ContactTag contact={chaperone} />}</TableCell>
                <TableCell>{flag}</TableCell>
              </>
            )
          }}
        />
      </Paper>
    </>
  )
}

export default observer(FlagTable)
