import { Box, TextField as MuiTextField, Typography } from '@material-ui/core'
import { Field } from 'formik'
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useStores } from '../../../models/root-store'
import { UserSnapshot } from '../../../models/user'
import { getUsername } from '../../../utils/format'

interface UserSelectProps {
  name: string
  label: string
  error: boolean
  helperText: React.ReactNode
  userId: number | null
  setUserId: (userId: number | null) => void
  /**
   * A filter method to filter users
   */
  filterFn?: (user: UserSnapshot) => boolean
}

function UserSelect(props: UserSelectProps) {
  const { userId, name, setUserId, filterFn = () => true, ...textFieldProps } = props
  const [users, setUsers] = useState<UserSnapshot[]>([])
  const [loading, setLoading] = useState(false)

  const rootStore = useStores()

  useEffect(() => {
    setLoading(true)
    rootStore
      .getAllUsers()
      .then((users: UserSnapshot[]) => {
        setUsers(users)
      })
      .finally(() => setLoading(false))
  }, [rootStore])

  return (
    <Field
      name={name}
      component={Autocomplete}
      options={users.filter(filterFn)}
      getOptionLabel={getUsername}
      renderOption={(option: UserSnapshot) => (
        <Box>
          <Typography variant="body2">{getUsername(option)}</Typography>
        </Box>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <MuiTextField {...params} {...textFieldProps} name={name} variant="outlined" />
      )}
      getOptionSelected={(option: UserSnapshot, value: UserSnapshot | null) => {
        if (!value) return false
        return option.userId === value.userId
      }}
      onChange={(e: any, value: UserSnapshot | null) => {
        setUserId(value?.userId || 0)
      }}
      value={users.find(n => n.userId === userId) || null}
      loading={loading}
      fullWidth
    />
  )
}

export default observer(UserSelect)
