import { Paper, Tab, Tabs } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import ChildLogTable from './ChildLogTable'
import ChildScheduleTable from './ChildScheduleTable'
import StopLogTable from './StopLogTable'
import ContentHeader from '../UI/ContentHeader'
import { useSharedStyles } from '../../hooks/useSharedStyles'
import LoginLogTable from './LoginLogTable'

export default function Archive() {
  const [activeTab, setActiveTab] = useState('stop-log')

  const handleChange = useCallback((event: React.ChangeEvent<{}>, value: any) => setActiveTab(value), [])

  const sharedClasses = useSharedStyles()

  return (
    <>
      <ContentHeader pageName="Archive" />
      <Paper className={sharedClasses.pagePaper}>
        <Tabs value={activeTab} indicatorColor="primary" textColor="primary" onChange={handleChange}>
          <Tab value="stop-log" label="Stop Log" />
          <Tab value="child-log" label="Pupil Log" />
          <Tab value="login-log" label="Monitor" />
          <Tab value="child-schedule" label="Additional Notifications" />
        </Tabs>
        {activeTab === 'stop-log' && <StopLogTable />}
        {activeTab === 'child-log' && <ChildLogTable />}
        {activeTab === 'login-log' && <LoginLogTable />}
        {activeTab === 'child-schedule' && <ChildScheduleTable />}
      </Paper>
    </>
  )
}
