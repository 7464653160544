import { Field } from 'formik'
import { TextField as MuiTextField } from '@material-ui/core'
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { ChildSnapshot } from '../../../models/child'
import { useStores } from '../../../models/root-store'
import { getUsername } from '../../../utils/format'

interface ChildSelectProps {
  name: string
  label: string
  error: boolean
  helperText: React.ReactNode
  childId: number
  setChildId: (childId: number) => void
}

function ChildSelect(props: ChildSelectProps) {
  const rootStore = useStores()
  const { name, childId, setChildId, ...textFieldProps } = props

  const [children, setChildren] = useState<ChildSnapshot[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    rootStore
      .getAllChildren()
      .then((children: ChildSnapshot[]) => {
        setChildren(children)
      })
      .finally(() => setLoading(false))
  }, [rootStore])

  return (
    <Field
      name={name}
      component={Autocomplete}
      options={children}
      getOptionLabel={getUsername}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <MuiTextField {...params} {...textFieldProps} name={name} variant="outlined" />
      )}
      getOptionSelected={(option: ChildSnapshot, value: ChildSnapshot | null) => {
        if (!value) return false
        return option.childId === value.childId
      }}
      onChange={(e: any, value: ChildSnapshot | null) => {
        setChildId(value?.childId || 0)
      }}
      value={children.find(n => n.childId === childId) || null}
      loading={loading}
      fullWidth
    />
  )
}

export default observer(ChildSelect)
