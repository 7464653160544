import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface SvgIconProps {
  size?: number
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error'
}

function IconSupport(props: SvgIconProps) {
  const { size, color } = props
  return (
    <SvgIcon style={{ fontSize: size || 36 }} color={color ? color : undefined}>
      <svg viewBox="0 0 35 38">
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={2}
          d="M27.299 36.723c3.732-.26 6.552-3.515 6.552-7.256v-2.18a3.525 3.525 0 00-2.412-3.346l-6.056-2.02v0-5.644a2.116 2.116 0 10-4.233 0v11.996l-1.74-1.74a3.528 3.528 0 00-4.99 0l-.327.329v0l7.712 7.813v0-.001a7.04 7.04 0 005.494 2.049zM11.27 11.337v0h2.823v7.057M11.27 18.394h5.646M14.093 8.161a.353.353 0 11-.706 0 .353.353 0 01.706 0z"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={2}
          d="M26.71 12.743c-.704-6.348-6.082-11.285-12.617-11.285C7.078 1.458 1.39 7.144 1.39 14.16c0 7.015 5.687 12.702 12.702 12.702"
        />
      </svg>
    </SvgIcon>
  )
}

export default IconSupport
