import { RouteBaseModel } from './route-base'
import { types, Instance, SnapshotOut } from 'mobx-state-tree'
import { StopOverviewModel } from '../stop'

export const RouteOverviewModel = RouteBaseModel.named('RouteOverview').props({
  stops: types.optional(types.array(StopOverviewModel), []),
})

export interface RouteOverview extends Instance<typeof RouteOverviewModel> {}

export interface RouteOverviewSnapshot extends SnapshotOut<typeof RouteOverviewModel> {}
