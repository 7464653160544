import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { PersonModel } from './person'

/**
 * Model description here for TypeScript hints.
 */
export const SchoolContactModel = PersonModel.named('SchoolContact').props({
  phoneNo: types.string,
  title: types.string,
})

/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type SchoolContactType = Instance<typeof SchoolContactModel>
export interface SchoolContact extends SchoolContactType {}
type SchoolContactSnapshotType = SnapshotOut<typeof SchoolContactModel>
export interface SchoolContactSnapshot extends SchoolContactSnapshotType {}
