import { Button, Grid, makeStyles } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { getSnapshot } from 'mobx-state-tree'
import React from 'react'
import { useHistory } from 'react-router-dom'
import BackgroundImage from '../../assets/images/background.png'
import { useStores } from '../../models/root-store'
import { IconAvatar } from '../UI/Icons'
import SchoolCard from './SchoolCard'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  container: {
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  menu: {
    width: '100%',
    padding: theme.spacing(2),
    paddingTop: 0,
    '& .MuiButton-text': {
      color: 'white',
    },
  },
  schools: {
    overflow: 'auto',
  },
}))

function SchoolSelect() {
  const rootStore = useStores()
  const classes = useStyles()
  const history = useHistory()

  if (!rootStore.user?.schools) return null

  return (
    <div className={classes.root}>
      <Grid container direction="column" className={classes.container}>
        <Grid item className={classes.menu}>
          <Button
            variant="text"
            size="large"
            startIcon={<IconAvatar />}
            onClick={() => {
              rootStore.logout()
              history.push('/login')
            }}
          >
            LOGOUT
          </Button>
          {/* <Button variant="text" size="large" startIcon={<IconGear />}>
          MANAGE ACCOUNT
        </Button> */}
        </Grid>
        <Grid item container alignItems="center" justify="center" className={classes.schools} xs spacing={2}>
          {rootStore.user?.schools.map(school => (
            <Grid item key={school.code}>
              <SchoolCard {...getSnapshot(school)} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  )
}

export default observer(SchoolSelect)
