import { Grid } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { api } from '../../models/environment'
import { BIZZYBUS_HOME } from '../../utils/constant'
import AlertDialog from '../UI/AlertDialog'
import SubmitButton from '../UI/SubmitButton'
import Layout from './Layout'

export default function ForgotPassword() {
  const [successDialogOpen, setSuccessDialogOpen] = useState(false)
  const [errorDialogOpen, setErrorDialogOpen] = useState(false)

  const handleCloseSuccessDialog = () => {
    setSuccessDialogOpen(false)
    window.location.href = BIZZYBUS_HOME
  }

  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false)
  }

  return (
    <Layout subtitle="Forgot Password">
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().label('Email').email().required(),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true)
          const response = await api.requestPasswordReset(values.email)
          setSubmitting(false)
          if (response.success) setSuccessDialogOpen(true)
          else if (response.error.response?.status === 404) {
            setErrorDialogOpen(true)
          }
        }}
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <Form>
            <Grid container spacing={2} direction="column" alignItems="stretch">
              <Grid item>
                <Field component={TextField} type="email" name="email" label="Enter your email" fullWidth />
              </Grid>
              <Grid item>
                <SubmitButton onClick={submitForm} submitting={isSubmitting} text="Submit" fullWidth />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <AlertDialog
        open={successDialogOpen}
        handleClose={handleCloseSuccessDialog}
        title="Password Reset"
        content={[
          'An email has been sent to your email address.',
          'Please follow the instruction to set your new password.',
        ]}
      />

      <AlertDialog
        open={errorDialogOpen}
        handleClose={handleCloseErrorDialog}
        title="Invalid Email!"
        content="Please double check the email entered or contact your school if you need assistance."
      />
    </Layout>
  )
}
