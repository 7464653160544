import { Grid, Paper, Typography } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useSharedStyles } from '../../hooks/useSharedStyles'
import { GroupChatSnapshot } from '../../models/group-chat'
import { useStores } from '../../models/root-store'
import ContentHeader from '../UI/ContentHeader'
import GroupChatList from './GroupChatList'
import MessageList from './MessageList'

function MessageLog() {
  const rootStore = useStores()
  const { selectedRouteName: routeName } = rootStore
  const sharedClasses = useSharedStyles()
  const [childId, setChildId] = useState<number | undefined>()

  return (
    <>
      <ContentHeader pageName="Messages" />
      <Paper className={sharedClasses.pagePaper}>
        {routeName ? (
          <Grid container spacing={0} alignItems="stretch" style={{ flex: 1 }}>
            <Grid item>
              <GroupChatList
                key={`${routeName}`}
                childId={childId}
                onSelect={(groupChat: GroupChatSnapshot | null) => {
                  if (!groupChat) {
                    setChildId(undefined)
                  } else if (groupChat.child.childId !== childId) {
                    setChildId(groupChat.child.childId)
                  }
                }}
              />
            </Grid>
            <Grid item container xs>
              {childId && <MessageList key={`${routeName}-${childId}`} routeName={routeName} childId={childId} />}
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column" justify="center" style={{ height: '100%' }}>
            <Grid item>
              <Typography align="center">Please select a route</Typography>
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  )
}

export default observer(MessageLog)
