import { Instance, SnapshotOut } from 'mobx-state-tree'
import { RouteBaseModel } from './route-base'

/**
 * This is route record for management
 */
export const RouteModel = RouteBaseModel.named('Route')

export interface Route extends Instance<typeof RouteModel> {}
export interface RouteSnapshot extends SnapshotOut<typeof RouteModel> {}
