import { TextField } from '@material-ui/core'
import React from 'react'
import ColumnFilter from '.'

interface KeywordFilterProps {
  value: string
  onChange(value: string): void
  label: string
  columnName: string
}

export function KeywordFilter(props: KeywordFilterProps) {
  const { columnName, label, value, onChange } = props

  return (
    <ColumnFilter labelTx={columnName} filtering={!!value}>
      <TextField
        variant="outlined"
        size="small"
        type="search"
        fullWidth
        label={label}
        value={value}
        onChange={event => onChange(event.target.value)}
      ></TextField>
    </ColumnFilter>
  )
}
