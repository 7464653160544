export const CANCEL_REQUEST_MESSAGE = 'cancel request'

export const STOP_CIRCLE_MARKER_SIZE = 15

// TODO: i18n
export const getRoleName = (role: string) => {
  switch (role) {
    case 'SCHOOL_ADMIN':
      return 'School Admin'
    case 'SYS_ADMIN':
      return 'System Admin'
    case 'TRANSPORT_MANAGER':
      return 'Transport Manager'
  }
  return ''
}

export const DEFAULT_PAGE_OPTIONS = [25, 50, 100]

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/

export const BIZZYBUS_HOME = 'https://www.bizzybus.co.uk/'

export const DATE_FORMAT = 'dd/MM/yyyy'
