import { debounce, get } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useStores } from '../models/root-store'

type WithRouteId<T> = T & {
  routeId: number | undefined
}

/**
 *
 * @param values Initial filter values
 * @param delay wait time
 */
export function useFilters<T>(values: T extends { routeId: number | undefined } ? WithRouteId<T> : T, delay = 200) {
  type F = WithRouteId<T> | T
  const rootStore = useStores()

  // value for display purpose
  const [filterValues, setFilterValues] = useState<F>(values)

  // value for filter purpose
  const [filters, setFilters] = useState<F>(values)

  const delayedPatchFilters = useCallback(
    debounce((patch: Partial<F>) => setFilters({ ...filters, ...patch }), delay),
    []
  )

  const updateFilters = useCallback(
    (patch: Partial<F>, delayApply: boolean = false) => {
      setFilterValues({ ...filterValues, ...patch })
      if (!delayApply) {
        setFilters({ ...filters, ...patch })
      } else {
        delayedPatchFilters(patch)
      }
    },
    [delayedPatchFilters, filterValues, filters]
  )

  useEffect(() => {
    if ('routeId' in filters && get(filters, 'routeId') !== rootStore.selectedRouteId) {
      updateFilters({ routeId: rootStore.selectedRouteId } as Partial<WithRouteId<T>>)
    }
  }, [filters, rootStore.selectedRouteId, updateFilters])

  return { filterValues, filters, updateFilters }
}
