import { Paper, TableCell } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useFilters } from '../../../hooks/useFilters'
import { useSharedStyles } from '../../../hooks/useSharedStyles'
import { api } from '../../../models/environment'
import { useStores } from '../../../models/root-store'
import { StopSnapshot } from '../../../models/stop'
import { StopTableFilters } from '../../../services/api.types'
import { formatISOTimeString } from '../../../utils/date'
import ContentHeader from '../../UI/ContentHeader'
import DataTable from '../../UI/DataTable'
import StopForm from './StopForm'

function StopList() {
  const rootStore = useStores()
  const sharedClasses = useSharedStyles()

  const { filters } = useFilters<StopTableFilters>({
    routeId: rootStore.selectedRouteId,
  })

  return (
    <>
      <ContentHeader pageName="Manage Stops" />
      <Paper className={sharedClasses.pagePaper}>
        <DataTable
          filters={filters}
          enableCreate={rootStore.isAdmin}
          download={api.downloadStops}
          request={api.getStops}
          idProperty="stopId"
          renderHeadCells={() => (
            <>
              <TableCell>Stop Name</TableCell>
              <TableCell>Stop Address</TableCell>
              <TableCell>Set Time of Arrival</TableCell>
            </>
          )}
          renderRowCells={(stop: StopSnapshot) => (
            <>
              <TableCell>{stop.stopName}</TableCell>
              <TableCell>{stop.address}</TableCell>
              <TableCell>{formatISOTimeString(stop.stopTime)}</TableCell>
            </>
          )}
          onDeleteContext={{
            confirm: {
              title: 'Delete Stop',
              messages: ['Are you sure you want to delete this stop?'],
            },
            request: api.deleteStop,
            successMessage: 'Stop has been deleted successfully',
            successCallback: rootStore.getAllStops,
          }}
          formComponent={StopForm}
          formClosed={(reload?: boolean) => {
            reload && rootStore.getAllStops()
          }}
        />
      </Paper>
    </>
  )
}

export default observer(StopList)
