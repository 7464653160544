import { createContext } from 'react'

interface SchoolContextValue {
  timeZone: string
}

const SchoolContext = createContext<SchoolContextValue>({
  timeZone: 'Europe/London',
})

export default SchoolContext
