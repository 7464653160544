import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface SvgIconProps {
  size?: number
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error'
}

function IconCall(props: SvgIconProps) {
  const { size, color } = props
  return (
    <SvgIcon style={{ fontSize: size || 36 }} color={color ? color : undefined}>
      <svg viewBox="0 0 35 34">
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={2}
          d="M18.317 1.04c8.453 0 15.304 6.851 15.304 15.304M18.317 5.213c6.147 0 11.13 4.983 11.13 11.13M18.317 9.387a6.957 6.957 0 016.957 6.957"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={2}
          d="M9.598 25.064C2.37 17.837-.465 8.956 3.264 5.228c.79-.79 2.158-1.808 3.17-2.524a1.478 1.478 0 012.107.419l4.44 7.047a1.482 1.482 0 01-.49 2.06l-2.605 1.56a1.48 1.48 0 00-.458 2.119c1.2 1.698 2.716 3.392 4.324 5 1.608 1.608 3.302 3.124 5 4.325a1.48 1.48 0 002.118-.46l1.562-2.603a1.482 1.482 0 012.06-.492l7.046 4.442c.719.453.91 1.414.42 2.106-.717 1.013-1.734 2.38-2.525 3.17-3.728 3.73-12.608.894-19.835-6.333z"
        />
      </svg>
    </SvgIcon>
  )
}

export default IconCall
