import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import axios, { CancelToken, CancelTokenSource } from 'axios'
import { get } from 'lodash'
import React, { useCallback, useEffect, useReducer, useRef } from 'react'
import { usePagination } from '../../hooks/usePagination'
import { useSharedStyles } from '../../hooks/useSharedStyles'
import { api } from '../../models/environment'
import { useStores } from '../../models/root-store'
import { MessageVariant } from '../../models/snackbar-message'
import { ApiResponse, Pageable, PagedResult } from '../../services/api.types'
import { DEFAULT_PAGE_OPTIONS } from '../../utils/constant'
import { createListReducer } from '../../utils/reducer'
import LoadingOverlay from './LoadingOverlay'
import TableRowActionCell from './TableRowActionCell'
import NoDataOverlay from './NoDataOverlay'
import TableToolbar from './TableToolbar'

interface ConfirmContext {
  title: string
  messages: string[]
}

interface OnDeleteContext {
  confirm: ConfirmContext
  request(id: number): Promise<ApiResponse>
  successMessage: string
  successCallback?(id: number): void
}

export interface FormProps<T> {
  record: T | null
  closeForm(reload?: boolean): void
}

/**
 * F - Filter interface, T - Record interface
 */
interface TableProps<F, T> {
  renderHeadCells(): React.ReactNode
  renderRowCells(record: T): React.ReactNode
  keyExtractor?(record: T): string
  idProperty?: string
  request(query: F & Pageable, token: CancelToken | undefined): Promise<ApiResponse<PagedResult<T>>>
  sort?(record1: T, record2: T): number
  filters?: F
  download(filters?: F): Promise<void>
  onDeleteContext?: OnDeleteContext
  // formComponent?(props: FormProps): React.ReactNode
  // reason for not type result as React.ReactNode: https://github.com/typescript-cheatsheets/react/issues/129
  formComponent?(props: FormProps<T>): JSX.Element
  formClosed?(reload?: boolean): void
  formProps?: any
  enableCreate?: boolean
}

function DataTable<F, T>(props: TableProps<F, T>) {
  const {
    renderHeadCells,
    renderRowCells,
    idProperty,
    keyExtractor,
    download,
    filters,
    request,
    onDeleteContext,
    formComponent: FormComponent,
    formClosed,
    formProps = {},
    enableCreate,
  } = props

  const editable = !!onDeleteContext

  const rootStore = useStores()
  const { isAdmin } = rootStore
  const sharedClasses = useSharedStyles()

  const { page, size, total, tableRef, handleChangePage, handleChangePageSize, setTotal, setPage } = usePagination()

  const { reducer, initialState } = createListReducer<T>()

  const [state, dispatch] = useReducer(reducer, initialState)

  const source = useRef<CancelTokenSource | null>(null)
  const fetchData = useRef<(() => Promise<void>) | null>(null)

  const onDelete = useCallback(() => {
    if (!state.activeRecord || !onDeleteContext || !idProperty) return
    else {
      dispatch({ type: 'SET_DELETING', payload: true })
      const recordId = get(state.activeRecord, idProperty) as number
      onDeleteContext.request
        .call(api, recordId)
        .then(response => {
          if (response.success) {
            rootStore.enqueueSnackbar(onDeleteContext.successMessage, MessageVariant.success)
            fetchData.current && fetchData.current()
            if (onDeleteContext.successCallback) onDeleteContext.successCallback(recordId)
          }
        })
        .finally(() => {
          dispatch({ type: 'SET_DELETING', payload: false })
          dispatch({ type: 'SET_DELETE_CONFIRM_OPEN', payload: false })
        })
    }
  }, [idProperty, onDeleteContext, rootStore, state.activeRecord])

  useEffect(() => {
    setPage(0)
  }, [filters, setPage])

  useEffect(() => {
    fetchData.current = async () => {
      source.current?.cancel()
      source.current = axios.CancelToken.source()
      const query = { ...filters, size, page } as F & Pageable
      dispatch({ type: 'SET_LOADING', payload: true })
      const response = await request.call(api, query, source.current?.token)
      if (response.success) {
        const { content, totalElements } = response.data as PagedResult<T>
        dispatch({ type: 'SET_DATA', payload: content })
        setTotal(totalElements)
      }
      dispatch({ type: 'SET_LOADING', payload: false })
    }

    fetchData.current()

    return () => {
      if (source.current) source.current.cancel()
    }
  }, [filters, page, request, setTotal, size])

  const { data, loading } = state

  return (
    <>
      <TableToolbar
        downloadData={() => download.call(api, filters)}
        downloadDisabled={!data.length}
        onCreateButtonClicked={
          enableCreate
            ? () => {
                dispatch({ type: 'SET_ACTIVE_RECORD', payload: null })
                dispatch({ type: 'SET_FORM_OPEN', payload: true })
              }
            : undefined
        }
      />
      <TableContainer className={sharedClasses.tableContainer} ref={tableRef}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <>
                {renderHeadCells()}
                {isAdmin && editable && <TableCell>Action</TableCell>}
              </>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((record: T, index: number) => {
              let key: string
              let recordId: number
              if (keyExtractor) key = keyExtractor(record)
              else if (idProperty) {
                recordId = get(record, idProperty) as number
                key = `${recordId}`
              } else key = `${index}`
              return (
                <TableRow key={key}>
                  {renderRowCells(record)}
                  {isAdmin && editable && (
                    <TableRowActionCell
                      onDelete={() => {
                        dispatch({ type: 'SET_ACTIVE_RECORD', payload: record })
                        dispatch({ type: 'SET_DELETE_CONFIRM_OPEN', payload: true })
                      }}
                      onEdit={() => {
                        dispatch({ type: 'SET_ACTIVE_RECORD', payload: record })
                        dispatch({ type: 'SET_FORM_OPEN', payload: true })
                      }}
                    />
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        {!data.length && !loading && <NoDataOverlay />}
        <LoadingOverlay visible={loading} />
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={DEFAULT_PAGE_OPTIONS}
        component="div"
        count={total}
        rowsPerPage={size}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangePageSize}
      />

      {onDeleteContext && (
        <Dialog
          open={state.deleteConfirmOpen}
          maxWidth="xs"
          fullWidth={true}
          onClose={() => dispatch({ type: 'SET_DELETE_CONFIRM_OPEN', payload: true })}
        >
          <DialogTitle>{onDeleteContext.confirm.title}</DialogTitle>
          <DialogContent>
            {onDeleteContext.confirm.messages.map((message, index) => (
              <DialogContentText key={index}>{message}</DialogContentText>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="default"
              onClick={() => dispatch({ type: 'SET_DELETE_CONFIRM_OPEN', payload: false })}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={state.deleting}
              startIcon={state.deleting ? <CircularProgress size={14} /> : null}
              onClick={onDelete}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {state.formOpen && FormComponent && (
        <FormComponent
          record={state.activeRecord}
          closeForm={(reload?: boolean) => {
            dispatch({ type: 'SET_FORM_OPEN', payload: false })
            if (reload) {
              fetchData.current && fetchData.current()
            }
            // in case dialog view changing before hidden
            setTimeout(() => {
              dispatch({ type: 'SET_ACTIVE_RECORD', payload: null })
            }, 200)
            formClosed && formClosed(reload)
          }}
          {...formProps}
        />
      )}
    </>
  )
}

export default DataTable
