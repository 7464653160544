import { unstable_createMuiStrictModeTheme } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import { colors } from '@material-ui/core'

export default unstable_createMuiStrictModeTheme({
  palette: {
    // primary color is not the same as the doc, see: https://github.com/mui-org/material-ui/issues/9857
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
  },
  typography: {
    button: {
      // disable button textTransform
      fontWeight: 'bold',
      textTransform: 'none',
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        '& + &': {
          borderLeft: `1px solid ${colors.blue[500]}`,
        },
      },
    },
    MuiLink: {
      root: {
        fontWeight: 'bold',
        textDecoration: 'none',
      },
    },
  },
})
