import { Box, Typography } from '@material-ui/core'
import React from 'react'
import EmptyImage from '../../assets/images/empty.png'

interface NoDataOverlayProps {}

export default function NoDataOverlay(props: NoDataOverlayProps) {
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ backgroundColor: 'rgba(255, 255, 255, 0.38)' }}
    >
      <img src={EmptyImage} alt="no data" />
      <Typography variant="body2">No Data</Typography>
    </Box>
  )
}
