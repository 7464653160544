import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  TextField as MuiTextField,
  Typography,
} from '@material-ui/core'
import { createFilterOptions, FilterOptionsState } from '@material-ui/lab'
import { Field, FieldArray, FieldArrayRenderProps, Form, Formik, getIn } from 'formik'
import { TextField } from 'formik-material-ui'
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useReducer, useState } from 'react'
import * as Yup from 'yup'
import { ChildSnapshot } from '../../../models/child'
import { api } from '../../../models/environment'
import { useStores } from '../../../models/root-store'
import { UserSnapshot } from '../../../models/user'
import { getUsername } from '../../../utils/format'
import AlertDialog from '../../UI/AlertDialog'
import { FormProps } from '../../UI/DataTable'
import { AddCircleIcon, DeleteForeverIcon } from '../../UI/Icons'
import RouteNameSelect from '../../UI/Select/RouteNameSelect'
import StopSelect from '../../UI/Select/StopSelect'
import SubmitButton from '../../UI/SubmitButton'
import { formReducer } from './reducer'

const useStyles = makeStyles({
  cardActions: {
    padding: 16,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0px 0 10px 0',
    width: 180,
  },
  childTypeDialogContainer: {
    marginBottom: 20,
  },
})

interface ParentOption {
  userId?: number
  firstName?: string
  lastName?: string
  email: string
}

const filter = createFilterOptions<ParentOption>()

const getParentOptionLabel = (option?: ParentOption) => {
  if (option?.userId) return `${getUsername(option)} (${option.email})`
  else return option?.email || ''
}

function ChildForm(props: FormProps<ChildSnapshot>) {
  const rootStore = useStores()
  const classes = useStyles()
  const { record: child, closeForm } = props
  const [successDialogOpen, setSuccessDialogOpen] = React.useState<boolean>(false)
  const [state, dispatch] = useReducer(formReducer.reducer, formReducer.state)
  const [parentUsers, setParentUsers] = useState<UserSnapshot[]>([])
  const [loadingParentUsers, setLoadingParentUsers] = useState(false)
  /**
   * Used to determine form layout while creating new child or editing a child before child data loaded
   */
  const [registered, setRegistered] = useState<boolean | undefined>(child?.registered)

  useEffect(() => {
    // get stop options, consider refactor and move to stop select component
    rootStore.getAllStops()

    setLoadingParentUsers(true)
    rootStore
      .getAllUsers()
      .then((users: UserSnapshot[]) => {
        setParentUsers(users.filter(n => n.role === 'PARENT'))
      })
      .finally(() => setLoadingParentUsers(false))
  }, [rootStore])

  useEffect(() => {
    if (child) {
      dispatch({ type: 'SET_LOADING', payload: true })
      api
        .getChild(child.childId)
        .then(response => {
          if (response.success) {
            dispatch({ type: 'SET_RECORD', payload: response.data as ChildSnapshot })
          } else {
            // TODO: enqueue error message, and close form
          }
        })
        .finally(() => dispatch({ type: 'SET_LOADING', payload: false }))
    } else {
      dispatch({ type: 'SET_RECORD', payload: null })
    }
  }, [child])

  const closeSuccessDialog = () => {
    setSuccessDialogOpen(false)
    closeForm(true)
  }

  if (!child && registered === undefined) {
    return (
      <Dialog open={true} onClose={() => closeForm()}>
        <DialogTitle>Add Pupil</DialogTitle>
        <DialogContent className={classes.childTypeDialogContainer}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Button variant="contained" color="primary" fullWidth onClick={() => setRegistered(true)}>
                <Typography variant="button">Permanent Pupil</Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <>
      <Dialog fullWidth maxWidth={registered ? 'md' : 'xs'} open={true} onClose={() => closeForm()}>
        <DialogTitle>{`${child ? 'Edit' : 'Add'} ${registered ? 'Permanent Pupil' : 'One-Time Pupil'}`}</DialogTitle>
        <Formik
          enableReinitialize
          initialValues={state.values}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().label('Name').required(),
            lastName: Yup.string().label('Surname').required(),
            stopIds: Yup.array().of(Yup.number().nullable()).ensure().min(1, 'Moring or afternnon stop required'),
            daysOfWeek: Yup.array<string>().required('Days of week is required'),
            parentAccounts: Yup.array<string>(
              Yup.string().label('Email address').email('Invalid email address').required()
            ),
          })}
          onSubmit={async values => {
            const childId = props.record?.childId
            const response = childId ? await api.updateChild(childId, values) : await api.addChild(values)
            if (response.success) setSuccessDialogOpen(true)
          }}
        >
          {({ values, submitForm, isSubmitting, errors, touched, setFieldValue, handleChange }) => (
            <>
              <DialogContent>
                <Form autoComplete="off">
                  <Grid container spacing={2}>
                    <Grid item xs={registered ? 5 : 12}>
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <Field component={TextField} variant="outlined" label="Name" name="firstName" fullWidth />
                        </Grid>
                        <Grid item>
                          <Field component={TextField} variant="outlined" label="Surname" name="lastName" fullWidth />
                        </Grid>
                        <Grid item>
                          <Field component={TextField} variant="outlined" label="Medical" name="medical" fullWidth />
                        </Grid>
                        <Grid item>
                          <Field
                            name="routeName"
                            label="Route"
                            variant="outlined"
                            fullWidth
                            component={RouteNameSelect}
                          />
                        </Grid>

                        <FieldArray
                          name="stopIds"
                          render={(arrayHelpers: FieldArrayRenderProps) => (
                            <>
                              <Grid item>
                                <Field
                                  name={'stopIds.0'}
                                  arrayHelpers={arrayHelpers}
                                  fullWidth
                                  component={StopSelect}
                                  routeType="Morning"
                                  label="Morning Stop"
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item>
                                <Field
                                  name={'stopIds.1'}
                                  arrayHelpers={arrayHelpers}
                                  fullWidth
                                  component={StopSelect}
                                  routeType="Afternoon"
                                  label="Afternoon Stop"
                                  variant="outlined"
                                />
                              </Grid>
                            </>
                          )}
                        />
                        <Grid item>
                          <Field
                            multiple
                            name="daysOfWeek"
                            component={Autocomplete}
                            options={['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']}
                            renderInput={(params: AutocompleteRenderInputParams) => (
                              <MuiTextField
                                {...params}
                                error={touched['daysOfWeek'] && !!errors['daysOfWeek']}
                                helperText={errors['daysOfWeek']}
                                label="Days of Week"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {registered && (
                      <Grid item xs={7}>
                        <FieldArray
                          name="parentAccounts"
                          // TODO: we can creat component: https://formik.org/docs/api/fieldarray#component-reactreactnode
                          render={arrayHelpers => (
                            <Card variant="outlined">
                              <CardHeader title="Parents" />
                              <CardContent>
                                <Grid container direction="column" spacing={2}>
                                  {values.parentAccounts.map((email: string, index: number) => {
                                    const fieldName = `parentAccounts.${index}`
                                    return (
                                      <Grid item container direction="row" spacing={0} key={`parent-email-${index}`}>
                                        <Grid item xs>
                                          <Field
                                            name={fieldName}
                                            component={Autocomplete}
                                            options={parentUsers}
                                            getOptionLabel={getParentOptionLabel}
                                            renderOption={(option: ParentOption) => {
                                              const label = getParentOptionLabel(option)
                                              const newUser = !option.userId
                                              return (
                                                <Typography
                                                  variant="body2"
                                                  style={{ fontWeight: newUser ? 'bold' : 'normal' }}
                                                >
                                                  {newUser ? `Invite: ${label}` : label}
                                                </Typography>
                                              )
                                            }}
                                            renderInput={(params: AutocompleteRenderInputParams) => (
                                              <MuiTextField
                                                {...params}
                                                name={fieldName}
                                                label={`Parent ${index + 1}`}
                                                variant="outlined"
                                                error={!!getIn(touched, fieldName) && !!getIn(errors, fieldName)}
                                                helperText={!!getIn(touched, fieldName) && getIn(errors, fieldName)}
                                              />
                                            )}
                                            getOptionSelected={(option: ParentOption, value: ParentOption) =>
                                              option.email === value.email
                                            }
                                            onChange={(e: any, value: ParentOption | string | null) => {
                                              let email
                                              // input value when the user press enter
                                              if (typeof value === 'string') email = value
                                              else email = value?.email || ''
                                              arrayHelpers.replace(index, email)
                                            }}
                                            value={
                                              !!email ? parentUsers.find(n => n.email === email) || { email } : null
                                            }
                                            fullWidth
                                            filterOptions={(
                                              options: ParentOption[],
                                              params: FilterOptionsState<ParentOption>
                                            ) => {
                                              const filtered = filter(options, params)
                                              // add option to invite new parent user
                                              if (params.inputValue) {
                                                filtered.push({ email: params.inputValue })
                                              }
                                              return filtered
                                            }}
                                            freeSolo
                                            clearOnBlur
                                            loading={loadingParentUsers}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <IconButton
                                            color={values.parentAccounts.length <= 1 ? 'default' : 'secondary'}
                                            disabled={values.parentAccounts.length <= 1}
                                            onClick={() => arrayHelpers.remove(index)}
                                          >
                                            <DeleteForeverIcon />
                                          </IconButton>
                                        </Grid>
                                      </Grid>
                                    )
                                  })}
                                </Grid>
                              </CardContent>
                              <CardActions className={classes.cardActions}>
                                <Grid container justify="flex-end">
                                  <Grid item>
                                    <IconButton
                                      color="primary"
                                      disabled={values.parentAccounts.length >= 3}
                                      onClick={() => arrayHelpers.push('')}
                                    >
                                      <AddCircleIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </CardActions>
                            </Card>
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Form>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="default" onClick={() => closeForm()}>
                  <Typography variant="button">Cancel</Typography>
                </Button>
                <SubmitButton onClick={submitForm} submitting={isSubmitting} text="Save" />
              </DialogActions>
            </>
          )}
        </Formik>
      </Dialog>

      <AlertDialog
        open={successDialogOpen}
        handleClose={closeSuccessDialog}
        title="Success"
        content={[
          !child ? 'Add child successfully' : 'Update child successfully',
          // child.registered && !child.parents.every(e => rootStore.users.some(u => u.email === e.inputValue)) && `An email has been sent to pupil's associated parent users so that they can set up an account`
        ]}
      />
    </>
  )
}

export default observer(ChildForm)
