import { MenuItem, TextField } from '@material-ui/core'
import { FieldProps } from 'formik'
import { fieldToTextField } from 'formik-material-ui'
import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import { useStores } from '../../../models/root-store'

interface RouteNameSelectProps extends FieldProps {}

function RouteNameSelect(props: RouteNameSelectProps) {
  const {
    form: { setFieldValue, handleChange },
  } = props
  const rootStore = useStores()

  const renderOptions = useCallback(() => {
    return rootStore.routeNames.map((routeName: string) => (
      <MenuItem key={routeName} value={routeName}>
        {routeName}
      </MenuItem>
    ))
  }, [rootStore.routeNames])

  return (
    <TextField
      {...fieldToTextField(props)}
      select
      onChange={(e: any) => {
        handleChange(e)
        setFieldValue('stopIds', [])
      }}
    >
      {renderOptions()}
    </TextField>
  )
}

export default observer(RouteNameSelect)
