import { Box, Dialog, DialogContent, IconButton, makeStyles, Typography, Grid, colors } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useStores } from '../../models/root-store'
import { DescriptionOutlinedIcon, IconAvatar } from './Icons'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25,
    height: 35,
  },
  routeColor: {
    alignSelf: 'center',
    width: 25,
    height: 35,
    borderRadius: 25,
    marginRight: 20,
  },
  routeName: {},
  routeDetail: {
    // width: 400,
    height: 210,
  },
  childrenCount: {},
  childrenCountCircleWrapper: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  childrenCountCircle: {
    width: 75,
    height: 75,
    borderRadius: '50%',
    backgroundColor: colors.blue[500],
    fontWeight: 'bold',
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
    color: '#fff',
  },
  iconAvatar: {
    backgroundColor: colors.blue[500],
    borderRadius: '50%',
    color: '#fff',
    width: 30,
    height: 30,
    padding: 5,
    marginRight: 10,
  },
  routeContact: {
    marginTop: 15,
    marginBottom: 20,
  },
})

function RouteContact(props: { title: string; name?: string; phoneNo?: string | null }) {
  const classes = useStyles()
  return (
    <Grid item container alignItems="center" className={classes.routeContact}>
      <Grid item className={classes.iconAvatar}>
        <IconAvatar size={20} />
      </Grid>
      <Grid item container direction="column" xs>
        <Grid item>
          <Typography variant="body2" display="inline">
            {`${props.title}: `}
          </Typography>
          <Typography variant="body2" color="primary" display="inline">
            {props.name || ''}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" display="inline">
            Number:{' '}
          </Typography>
          <Typography variant="body2" color="primary" display="inline">
            {props.phoneNo || ''}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

function SelectedRouteView(props: { selectedRouteOnly?: boolean }) {
  const classes = useStyles()
  const rootStore = useStores()
  const [detailOpen, setDetailOpen] = useState(false)

  let routeName: string = 'All routes'
  let style: Partial<React.CSSProperties> = {}
  if (rootStore.selectedRoute) {
    style.backgroundColor = rootStore.selectedRoute.color
    // TODO: rename selectOptionLabel
    routeName = rootStore.selectedRoute.selectOptionLabel
  }

  const route = rootStore.selectedRoute

  return props.selectedRouteOnly && !rootStore.selectedRoute ? null : (
    <Box className={classes.root}>
      {rootStore.selectedRoute && <Box className={classes.routeColor} style={style} />}
      <Box className={classes.routeName}>
        <Typography>{routeName}</Typography>
      </Box>
      {rootStore.selectedRoute && (
        <IconButton color="primary" size="small" title="Route Detail" onClick={() => setDetailOpen(true)}>
          <DescriptionOutlinedIcon />
        </IconButton>
      )}
      <Dialog open={detailOpen} onClose={() => setDetailOpen(false)} maxWidth="xs" fullWidth>
        <DialogContent className={classes.routeDetail}>
          {route && (
            <Grid container direction="column">
              <Grid item>
                <Typography variant="body2">{`Campus: ${rootStore.selectedRoute?.campus || ''}`}</Typography>
              </Grid>
              <Grid item container>
                <Grid item container direction="column" xs={5}>
                  <Grid item className={classes.childrenCountCircleWrapper}>
                    <div className={classes.childrenCountCircle}>
                      <Typography variant="h5">{route.childrenCount}</Typography>
                    </div>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">No. of children</Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="column" xs={7}>
                  <RouteContact title="Monitor" name={route.chaperone?.name} phoneNo={route.chaperone?.phoneNo} />
                  <RouteContact title="Driver" name={route.driver?.name} phoneNo={route.driver?.phoneNo} />
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default observer(SelectedRouteView)
