import { Typography } from '@material-ui/core'
import { InfoWindow, Marker } from '@react-google-maps/api'
import React from 'react'
import { useStores } from '../../models/root-store'
import { StopOverviewSnapshot } from '../../models/stop'
import { STOP_CIRCLE_MARKER_SIZE } from '../../utils/constant'
import { getStopMarkerIcon } from '../../utils/map'

export enum StopMarkerStyle {
  ALL_ROUTES_VIEW,
  SINGLE_ROUTE_VIEW,
}

interface StopMarkerProps {
  stop: StopOverviewSnapshot
  color: string
  markerStyle?: StopMarkerStyle
  clickedStopId: number
  setClickedStopId: Function
}

export default function StopMarker(props: StopMarkerProps) {
  const rootStore = useStores()
  // color is fill color for ALL_ROUTE_VIEW and stroke color for SINGLE_ROUTE_VIEW
  const {
    stop: { lat, lon: lng, stopId, childCount, stopName },
    color,
    clickedStopId,
    setClickedStopId,
  } = props
  // depends on marker style
  const radius = STOP_CIRCLE_MARKER_SIZE / 2

  return (
    <>
      {/* TODO: change marker style when zoomed in to selected route */}
      <Marker
        key={`stop-marker-${stopId}`}
        zIndex={10}
        draggable={false}
        // MouseEvent
        onDragEnd={(e: any) => {
          rootStore.updateStopLocation(stopId, e.latLng.lat(), e.latLng.lng())
        }}
        position={{ lat, lng }}
        icon={{
          url: getStopMarkerIcon(color),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(radius, radius),
        }}
        onClick={() => setClickedStopId(stopId)}
      />
      {stopId === clickedStopId && (
        <InfoWindow
          key={`stop-info-${stopId}`}
          position={{ lat, lng }}
          options={{ pixelOffset: new google.maps.Size(0, -radius) }}
          onCloseClick={() => setClickedStopId(0)}
        >
          <>
            <Typography key={`stop-info-stop-name-${stopId}`} align="center">{`${stopName}:`}</Typography>
            <Typography
              key={`stop-info-stop-children-count-${stopId}`}
              align="center"
            >{`${childCount} children`}</Typography>
          </>
        </InfoWindow>
      )}
    </>
  )
}
