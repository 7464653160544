import { TextField } from '@material-ui/core'
import AutoComplete from '@material-ui/lab/Autocomplete'
import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useStores } from '../../../models/root-store'
import { StopOverview } from '../../../models/stop'

interface FilterStops {
  (): StopOverview[]
}

export interface StopSelectProps {
  filterOptions?: FilterStops
  optionChanged?(): void
  onChange(stopName: string | null): void
  value: string | null
}

function StopSelect(props: StopSelectProps) {
  const { optionChanged, value } = props
  const rootStore = useStores()
  // const filterState = rootStore.stopLogFilters.get('stop') as ColumnFilterState

  const [stopOptions, setStopOptions] = useState<string[]>([])

  useEffect(() => {
    optionChanged?.call(null)
    const options = new Set(
      rootStore.stops
        .filter(n => !rootStore.selectedRouteId || n.routeId === rootStore.selectedRouteId)
        .map(e => e.stopName)
    )

    setStopOptions(Array.from(options).sort())
  }, [optionChanged, rootStore.selectedRouteId, rootStore.stops])

  const onChange = (event: ChangeEvent<{}>, value: string | null) => {
    props.onChange(value)
    // filterState.setValue(value.map(stop => `${stop.stopId}`))
  }

  // const currentValue = filterState.value?.map(id =>
  //   rootStore.stops.find(n => n.stopId.toString() === id)
  // ) as StopOverview

  return (
    <AutoComplete
      size="small"
      openOnFocus
      options={stopOptions}
      // groupBy={stop => `Route ${stop.routeName} - ${stop.routeType}`}
      // getOptionLabel={stop => stop.stopName}
      renderInput={params => <TextField {...params} label="Search Stop" variant="outlined" />}
      value={value}
      onChange={onChange}
    />
  )
}

export default observer(StopSelect)
