import {
  Box,
  ClickAwayListener,
  Fade,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Popper,
  TableCell,
  TableCellProps,
} from '@material-ui/core'
import FilterListIcon from '@material-ui/icons/FilterList'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'

export interface ColumnFilterProps extends TableCellProps {
  /**
   * The column name
   */
  labelTx: string

  /**
   * Whether the filter is being used, filter icon color will be blue if true
   */
  filtering?: boolean
}

const useStyles = makeStyles({
  label: {
    flex: 1,
  },
  filterView: {
    backgroundColor: 'white',
    minWidth: 280,
    padding: 15,
  },
  filterButton: {},
  popper: {
    zIndex: 20,
  },
})

function ColumnFilter(props: ColumnFilterProps) {
  const { labelTx, align, filtering = false, ...restProps } = props
  const classes = useStyles()
  const headerRef = useRef()

  const [showFilterView, setShowFilterView] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined)

  const openFilterView = () => {
    setAnchorEl(headerRef.current)
    setShowFilterView(true)
  }
  const closeFilterView = () => setShowFilterView(false)

  return (
    <TableCell {...restProps} ref={headerRef}>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item className={classes.label}>
          {props.labelTx}
        </Grid>
        <Grid item>
          <ClickAwayListener onClickAway={closeFilterView}>
            <Box className={classes.filterButton}>
              <IconButton onClick={openFilterView}>
                <FilterListIcon color={filtering ? 'primary' : 'inherit'} />
              </IconButton>
              <Popper
                open={showFilterView}
                anchorEl={anchorEl}
                placement="bottom-end"
                transition
                className={classes.popper}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper className={classes.filterView}>{props.children}</Paper>
                  </Fade>
                )}
              </Popper>
            </Box>
          </ClickAwayListener>
        </Grid>
      </Grid>
    </TableCell>
  )
}

export default observer(ColumnFilter)
