const questions = [
  {
    title: 'How do I track a bus?',
    description: `To track a bus simply go to the Dashboard (from the menu bar on the left-hand side). The map on the dashboard shows where each of your buses is during throughout the school bus route.
You can filter to see only one bus by selecting one of the routes (morning or afternoon) on the left-hand side.`,
  },

  {
    title: 'How do I add a new school admin account?',
    description: `To add a new school admin account go to ‘Manage’ then ‘Users’ from the menu bar. Select the tab named ‘Administrator’ and then click on the plus button in the top right-hand corner. A pop-up box will then appear prompting you for the information you will need to fill in. Once you have filled this in simply press ‘submit’. An email will then be sent to the school administrator prompting them to set up a password.`,
  },

  {
    title: 'How do I add and manage parent accounts?',
    description: `To add a new parent account go to ‘Manage’ then ‘Users’ from the menu bar. Select the tab named ‘Parents’ and then click on the plus button in the top right-hand corner. A pop-up box will then appear prompting you for the information you will need to fill in. Once you have filled this in simply press ‘submit’. An email will then be sent to the parent prompting them to set up a password.
If you need to edit a parent account e.g. to update their phone number, simply press on the pencil icon next to that parent’s account in the ‘Manage’, ‘Users’, ‘Parents’ list. Similarly, if you want to delete a parent account select the delete icon next to the parent account.`,
  },

  {
    title: 'How do I add new children to a route?',
    description: `To add a new child, go to ‘Manage’ then ‘Pupils’ from the menu bar. From here select the plus button in the top right-hand corner. A pop-up box will then appear prompting you to fill in the information required for that pupil. Once you have filled this in press submit.`,
  },

  {
    title: 'What happens if a child isn’t coming on the route?',
    description: `If a child is not coming on a route either the morning or afternoon a parent can notify this through the app. They can do this at any time of the day and notify in advance or on the day.
Once they have submitted this through the app a notification will appear on the school portal in ‘Archive’ on the menu bar, you must then select a route, and then the ‘Additional Notifications’ tab.
The driver/chaperone will also be notified of this change on the day and so will know to not pick up/ drop off that child.`,
  },

  {
    title: 'How can I add or edit a bus stop?',
    description: `If you need to add or edit a stop mid-term you can do this by selecting ‘Manage’ and ‘Stops’ from the menu bar. Select a route from the left-hand side (morning or afternoon) and select the plus button in the top right-hand corner. Fill in the details required in the pop-up box and press ‘save’.
To edit a stop, select the edit button next to the stop and to delete it select the delete icon next to it.`,
  },

  {
    title: 'What happens if a one-off child is coming on a route?',
    description: `If a child that does not normally go on a bus route needs to go only for one day, they can be added to the route by the driver/ chaperone. If it is in the morning, when the bus gets to that child’s stop the driver/chaperone has the ability to add a child at that stop.
If it is in the afternoon, the driver/ chaperone can add the one-off child as the children are boarding the bus. Any new children that are added can be seen by the school by selecting ‘Archive’ and then the ‘Pupil log’ tab.`,
  },

  {
    title: 'How can I see if there have been issues on a route?',
    description: `To see if there have been any problems on a route, select ‘Flags’ from the menu bar, then select a route from the ones next to the menu bar.`,
  },

  {
    title: 'Where can I find information about what happened on the route?',
    description: `If you want more information about what is happening on each route on a day to day basis simply select ‘Archive’ from the menu bar then select a route and manage through the taps to see:
What pupils got on each route at what time,
At what time each bus arrived at each stop,
At what time a driver/ monitor logged into the app
Whether children are not coming on a route on a particular day.`,
  },
]

export interface FaqData {
  title: string
  description: string
}

export default questions
