import { Grid, Link } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import React from 'react'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useStores } from '../../models/root-store'
import SubmitButton from '../UI/SubmitButton'
import Layout from './Layout'

function Login() {
  const rootStore = useStores()
  const history = useHistory()

  return (
    <Layout>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().label('Email').email().required(),
          password: Yup.string().label('Password').required(),
        })}
        onSubmit={async values => {
          const { email, password } = values
          const success = await rootStore.login(email, password)
          if (success) history.push('/')
        }}
      >
        {({ submitForm, isSubmitting, isValid }) => (
          <Form>
            <Grid container spacing={2} direction="column" alignItems="stretch">
              <Grid item>
                <Field component={TextField} name="email" type="email" label="Email" fullWidth />
              </Grid>
              <Grid item>
                <Field component={TextField} type="password" label="Password" name="password" fullWidth />
              </Grid>
              <Grid item>
                <SubmitButton onClick={submitForm} submitting={isSubmitting} text="Login" fullWidth />
              </Grid>
              <Grid container justify="center">
                <Link underline="none" href="/forgot-password">
                  Forgot your password?
                </Link>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default Login
