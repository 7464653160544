import { Chip, Grid, Paper, TableCell } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useFilters } from '../../../hooks/useFilters'
import { useSharedStyles } from '../../../hooks/useSharedStyles'
import { ChildSnapshot } from '../../../models/child'
import { api } from '../../../models/environment'
import { ParentSnapshot } from '../../../models/parent'
import { useStores } from '../../../models/root-store'
import { ChildTableFilters } from '../../../services/api.types'
import { getUsername } from '../../../utils/format'
import { weekSort } from '../../../utils/sort'
import { KeywordFilter } from '../../UI/ColumnFilter'
import ContactTag from '../../UI/ContactTag'
import ContentHeader from '../../UI/ContentHeader'
import DataTable from '../../UI/DataTable'
import ChildForm from './ChildForm'

function ChildList() {
  const rootStore = useStores()
  const sharedClasses = useSharedStyles()

  const { filters, filterValues, updateFilters } = useFilters<ChildTableFilters>({
    lastName: '',
    routeId: rootStore.selectedRouteId,
  })

  const { lastName } = filterValues
  return (
    <>
      <ContentHeader pageName="Manage Pupils" />
      <Paper className={sharedClasses.pagePaper}>
        <DataTable
          enableCreate={rootStore.isAdmin}
          filters={filters}
          download={api.downloadChildren}
          request={api.getChildren}
          idProperty="childId"
          renderHeadCells={() => (
            <>
              <TableCell width={180}>Name</TableCell>
              <KeywordFilter
                label="Search Surname"
                columnName="Surname"
                value={lastName}
                onChange={(value: string) => updateFilters({ lastName: value }, true)}
              />
              <TableCell width={180}>{rootStore.selectedRouteId ? 'Stop' : 'Stops'}</TableCell>
              <TableCell width={150}>Contacts</TableCell>
              <TableCell>Medical</TableCell>
              <TableCell width={200}>Schedules of the week</TableCell>
            </>
          )}
          renderRowCells={(child: ChildSnapshot) => {
            const { firstName, lastName, medical, daysOfWeek, stops } = child
            return (
              <>
                <TableCell width={150}>{firstName}</TableCell>
                <TableCell>{lastName}</TableCell>
                <TableCell>
                  <Grid container spacing={1} direction="column">
                    {stops
                      .filter(n => !rootStore.selectedRouteId || n.routeId === rootStore.selectedRouteId)
                      .map(stop => (
                        <Grid item key={stop.stopId}>
                          <Chip label={stop.address} />
                        </Grid>
                      ))}
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container direction="column" spacing={1}>
                    {child.parents.filter(getUsername).map((parent: ParentSnapshot) => (
                      <Grid key={parent.userId} item>
                        <ContactTag contact={parent} child={child} />
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
                <TableCell>{medical}</TableCell>
                <TableCell>{daysOfWeek !== null && daysOfWeek.sort((e1, e2) => weekSort(e1, e2)).join(', ')}</TableCell>
              </>
            )
          }}
          onDeleteContext={{
            confirm: {
              title: 'Delete Pupil',
              messages: ['Are you sure you want to delete this pupil?'],
            },
            request: api.deleteChild,
            successMessage: 'Pupil has been deleted successfully',
          }}
          formComponent={ChildForm}
        />
      </Paper>
    </>
  )
}

export default observer(ChildList)
