import React from 'react'
import { Box, Typography, makeStyles, Divider } from '@material-ui/core'
import { useStores } from '../models/root-store'
import { observer } from 'mobx-react-lite'
import { getRoleName } from '../utils/constant'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
  },
  schoolName: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  user: {
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    margin: '5px 0',
  },
  divider: {
    height: '80%',
    backgroundColor: 'white',
    margin: '0 5px',
  },
  role: {
    fontWeight: 300,
  },
  schoolImage: {
    borderRadius: '50%',
    width: 80,
    border: '5px solid white',
    marginTop: 25,
    marginBottom: 15,
  },
})

function Profile() {
  const rootStore = useStores()
  const classes = useStyles()

  if (!rootStore.school || !rootStore.user) return null

  return (
    <Box className={classes.root}>
      <img className={classes.schoolImage} src={rootStore.school.schoolImage} alt="school" />
      <Typography className={classes.schoolName}>{rootStore.school.name}</Typography>
      <Box className={classes.user}>
        <Typography variant="body2">{rootStore.user.name}</Typography>
        <Divider orientation="vertical" className={classes.divider} />
        <Typography variant="body2" className={classes.role}>
          {getRoleName(rootStore.user.role)}
        </Typography>
      </Box>
    </Box>
  )
}

export default observer(Profile)
