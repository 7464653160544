import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface SvgIconProps {
  size?: number
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error'
}

function IconArchive(props: SvgIconProps) {
  const { size, color } = props
  return (
    <SvgIcon style={{ fontSize: size || 36 }} color={color ? color : undefined}>
      <svg viewBox="0 0 35 29">
        <path
          fill="none"
          stroke="currentColor"
          strokeMiterlimit={20}
          strokeWidth={2}
          d="M1.2 12.046A2.736 2.736 0 013.937 9.31h27.368a2.736 2.736 0 012.737 2.736"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeMiterlimit={20}
          strokeWidth={2}
          d="M14.484 4.172v0l-1.936-1.936a1.368 1.368 0 00-.967-.4H2.569a1.37 1.37 0 00-1.37 1.368v23.263a1.37 1.37 0 001.37 1.369h30.105a1.37 1.37 0 001.369-1.37V5.942a1.37 1.37 0 00-1.37-1.37H15.452c-.363 0-.711-.143-.967-.4z"
        />
      </svg>
    </SvgIcon>
  )
}

export default IconArchive
