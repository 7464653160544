import { SvgIcon } from '@material-ui/core'
import * as React from 'react'

interface SvgIconProps {
  size?: number
  color?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error'
}

function IconRegisters(props: SvgIconProps) {
  const { size, color } = props
  return (
    <SvgIcon style={{ fontSize: size || 36 }} color={color ? color : undefined}>
      <svg viewBox="0 0 29 36">
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={1.7}
          d="M25.086 4.912v0h-5.593c1.037.694 1.669 1.61 1.669 2.616H8.08c0-1.005.632-1.922 1.669-2.616H4.156A2.616 2.616 0 001.54 7.528v24.854a2.616 2.616 0 002.616 2.617h20.93a2.616 2.616 0 002.616-2.617V7.528a2.616 2.616 0 00-2.616-2.616z"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={1.7}
          d="M17.205 3.923c.013-.105.032-.21.032-.32a2.617 2.617 0 10-5.232 0c0 .11.02.215.032.32C9.71 4.525 8.08 5.912 8.08 7.528h13.082c0-1.616-1.63-3.003-3.957-3.605zM10.88 18.941v0H6.8c-.567 0-1.028-.46-1.028-1.027v-4.08c0-.567.461-1.028 1.027-1.028h1.173v.013H6.8c-.559 0-1.014.455-1.014 1.014v4.081c0 .56.455 1.014 1.014 1.014h4.081c.56 0 1.014-.455 1.014-1.014v-1.173 0h.013v1.173c0 .567-.46 1.027-1.027 1.027z"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={1.7}
          d="M8.737 16.37v0l-.005-.006v0l-.817-.818v0l.009-.009v0l.813.812v0l3.159-3.158v0l.009.01v0l-3.168 3.168v0zM23.464 14.18v0h-7.482v0-.014 0h7.482v0zM23.464 17.582v0h-7.482v0-.014 0h7.482v0zM10.88 29.825v0H6.8c-.567 0-1.028-.46-1.028-1.027v-4.081c0-.566.461-1.027 1.027-1.027h1.173v.013H6.8c-.559 0-1.014.455-1.014 1.014v4.08c0 .56.455 1.015 1.014 1.015h4.081c.56 0 1.014-.455 1.014-1.014v-1.173 0h.013v1.173c0 .567-.46 1.027-1.027 1.027z"
        />
        <path
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={20}
          strokeWidth={1.7}
          d="M8.737 27.252v0l-.005-.005v0l-.817-.818v0l.009-.009v0l.813.813v0l3.159-3.159v0l.009.01v0l-3.168 3.168v0zM23.464 25.063v0h-7.482v0-.013 0h7.482v0zM23.464 28.465v0h-7.482v0-.013 0h7.482v0z"
        />
      </svg>
    </SvgIcon>
  )
}

export default IconRegisters
