import { Instance, SnapshotOut, types } from 'mobx-state-tree'
import { PersonModel } from './person'

const PartialUserModel = types.model().props({
  userId: types.number,
  email: types.string,
  phoneNo: types.maybeNull(types.string),
  parentTitle: types.maybeNull(types.string),
  role: types.string,
  company: types.maybeNull(types.string),
  campus: types.maybeNull(types.string),
  adminRole: types.maybeNull(types.enumeration(['SCHOOL_ADMINISTRATOR', 'SCHOOL_SECRETARY'])),
  jobType: types.maybeNull(types.enumeration(['ON_ROTATION', 'REGULAR'])),
  childIds: types.optional(types.array(types.number), [], [undefined, null]),
})

/**
 * Model description here for TypeScript hints.
 */
export const UserModel = types.compose(PersonModel, PartialUserModel).named('User')

type UserType = Instance<typeof UserModel>
export interface User extends UserType {}
type UserSnapshotType = SnapshotOut<typeof UserModel>
export interface UserSnapshot extends UserSnapshotType {}
